// Lib
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../node_module_clones/recompose';

// Singletons
import { syncedMeasurements } from '../../components/measurementsStore/elementMeasurements/syncedMeasurementsSingleton';
import currentlyVisibleElementsSingleton from '../elementVisibility/currentlyVisibleElementsSingleton';

// Utils
import createElementDragPreviewShadows from './utils/createElementDragPreviewShadows';
import { shouldShowPlaceholder } from '../elementPlaceholder/elementPlaceholderUtils';
import { getDomElementId } from '../utils/elementUtil';
import { getLocationSection, getMediaType } from '../../../common/elements/utils/elementPropertyUtils';
import { isCommentThread, isLine, isLink, isTable } from '../../../common/elements/utils/elementTypeUtils';
import { updateDragDimension } from './dragAndDropStateSingleton';

// Selectors
import makeElementSelector from '../selectors/elementSelector';
import { getAttachment } from '../attachments/attachmentsSelector';
import { getSelectedElementsCount } from '../selection/selectedElementsSelector';

// Components
import Element from '../Element';
import ElementPlaceholder from '../elementPlaceholder/ElementPlaceholder';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';
import { MEDIA_TYPES } from '../../../common/links/richMediaConstants';

// Styles
import './ElementDragPreview.scss';

const USE_PLACEHOLDERS_ON_CANVAS_COUNT = 50;

const mapStateToProps = createStructuredSelector({
    attachment: getAttachment,
    selectedElementCount: getSelectedElementsCount,
});

const enhance = compose(connect(mapStateToProps), connect(makeElementSelector));

/**
 * Check if the element type should be cloned from the DOM.
 * Exclusions:
 * - Tables - they have a more complex dom clone as the standard presentational table element.
 * - Comments - change to a pin icon when dragging over other elements.
 * - Lines - arrows don't seem to render in the dom clone.
 * - Rich media types - have a greyed out preview to avoid weird behaviour from the iframes.
 *
 * Note - These elements are excluded for simplicity, if we want to further improve performance we could work on
 * creating better dom clones for these elements and avoid rendering the presentational elements
 */
const shouldCloneElementType = (element) =>
    !isTable(element) &&
    !isCommentThread(element) &&
    !isLine(element) &&
    !(isLink(element) && getMediaType(element) === MEDIA_TYPES.RICH);

const appendDomClone = (domElement, dragPreviewRef) => {
    if (!domElement || !dragPreviewRef.current) return;

    const clone = domElement.cloneNode(true);
    dragPreviewRef.current.appendChild(clone);
};

const ElementDragPreview = (props) => {
    const { element, elementId, isSelected, selectedElementCount } = props;

    let previewElement = null;
    const dragPreviewRef = useRef(null);
    const elementOnCanvas = getLocationSection(element) === BoardSections.CANVAS;

    // --- 1. Check if a placeholder can be used ---
    const measurements = syncedMeasurements[elementId];
    const isVisible = currentlyVisibleElementsSingleton.getIsVisible(elementId);

    // We only use placeholders on the canvas when dragging a large number of elements. This is to avoid users seeing single
    // placeholders which might look strange, but if a bunch of elements are being dragged then seeing placeholders is more acceptable and useful.
    const shouldRenderPlaceholder =
        !(elementOnCanvas && selectedElementCount < USE_PLACEHOLDERS_ON_CANVAS_COUNT) &&
        shouldShowPlaceholder(measurements, elementId, element, isVisible, null, true);

    if (shouldRenderPlaceholder) {
        previewElement = (
            <ElementPlaceholder
                element={element}
                placeholderMeasurements={measurements}
                elementId={elementId}
                isSelected={isSelected}
            />
        );
    }

    // --- 2. Check if a clone can be used ---
    // When dragging out of a list/container, the element does not have the correct size as it fits to the size of the container.
    // For simplicity, I'm using the presentational component if the element is not on the canvas instead of manually applying the widths.
    const shouldUseDomElement = !shouldRenderPlaceholder && elementOnCanvas && shouldCloneElementType(element);
    let domElement = shouldUseDomElement ? document.querySelector(`#${getDomElementId(elementId)}`) : null;

    // --- 3. If neither of the more performant options are available, render the presentational element ---
    if (!shouldRenderPlaceholder && !domElement) {
        previewElement = <Element {...props} isDragging={false} isDragPreview />;
    }

    useEffect(() => {
        const dragPreviewElement = dragPreviewRef.current;
        if (!!domElement && dragPreviewElement) {
            appendDomClone(domElement, dragPreviewRef);
        }

        const measurementElement = isCommentThread(element)
            ? dragPreviewElement.querySelector('.CommentThread')
            : dragPreviewElement;
        const rect = measurementElement.getBoundingClientRect();
        updateDragDimension(rect);

        const dragHandle = dragPreviewElement.querySelector('.drag-handle');
        createElementDragPreviewShadows(dragHandle);
    }, []);

    return (
        <div ref={dragPreviewRef} className="ElementDragPreview">
            {previewElement}
        </div>
    );
};

ElementDragPreview.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    isSelected: PropTypes.bool,
    selectedElementCount: PropTypes.number,
};

export default enhance(ElementDragPreview);
