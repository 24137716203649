import { HyperFormula } from 'hyperformula/es';
import { isGlobalDebugEnabled } from '../../../debug/debugUtil';
import { useUSDateFormat } from '../../../../common/utils/timeUtil';

/**
 * For a breakdown of all the formulas and how we expect them to work in Milanote,
 * see https://app.milanote.com/1R9YPj1qgzysb1/formula-list
 */

const BLACKLISTED_FUNCTIONS = [
    // Removed as we don't support array results yet
    // TODO: Remove once we support array results
    'ARRAYFORMULA',
    'ARRAY_CONSTRAIN',
    'MMULT',
    'MEDIANPOOL',
    'MAXPOOL',
    'TRANSPOSE',

    // Removed as these are non-standard functions
    'INTERVAL',
    'HF.ADD',
    'HF.CONCAT',
    'HF.DIVIDE',
    'HF.EQ',
    'HF.LTE',
    'HF.LT',
    'HF.GTE',
    'HF.GT',
    'HF.MINUS',
    'HF.MULTIPLY',
    'HF.NE',
    'HF.POW',
    'HF.UMINUS',
    'HF.UNARY_PERCENT',
    'HF.UPLUS',
];

const hyperFormulaInstance = HyperFormula.buildEmpty({
    licenseKey: 'internal-use-in-handsontable',
});

hyperFormulaInstance.addNamedExpression('TRUE', '=True()');
hyperFormulaInstance.addNamedExpression('FALSE', '=False()');

export const getHyperformulaDateFormat = (locale: string): Array<string> =>
    useUSDateFormat(locale) ? ['MM/DD/YYYY', 'MM/DD/YY', 'YYYY/MM/DD'] : ['DD/MM/YYYY', 'DD/MM/YY'];

BLACKLISTED_FUNCTIONS.forEach((fnId: string) => {
    // @ts-ignore - private API
    const functionRegistry = hyperFormulaInstance._functionRegistry;
    if (!functionRegistry) return;

    functionRegistry.functions?.delete(fnId);
    functionRegistry.functionsMetadata?.delete(fnId);
    functionRegistry.instancePlugins?.delete(fnId);
    functionRegistry.arrayFunctions?.delete(fnId);
    functionRegistry.arraySizeFunctions?.delete(fnId);
});

export const hyperFormulaList = hyperFormulaInstance
    .getRegisteredFunctionNames()
    .sort()
    .sort((a: string, b: string) => a.length - b.length);

if (isGlobalDebugEnabled()) {
    window.hyperFormulaInstance = hyperFormulaInstance;
}

export default hyperFormulaInstance;
