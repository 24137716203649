import { RawDraftContentState } from 'draft-js';

// Utils
import { getTimestamp } from '../utils/timeUtil';
import { getGlobalClientId } from '../uid/ClientIdInstance';

// Constants
import { COMMENT_ID_PREFIX, CommentActionType } from './commentConstants';
import { MNComment } from './commentTypes';
import { CommentsAddAction, CommentLoadAction, CommentsUpdateAction, CommentsDeleteAction } from './commentActionsType';

export const getNewCommentId = (id: string = getGlobalClientId().getElementId()) => `${COMMENT_ID_PREFIX}${id}`;

export const commentsLoad = ({ comments }: { comments: Record<string, MNComment> }): CommentLoadAction => ({
    type: CommentActionType.COMMENTS_LOAD,
    comments,
});

export const commentsAdd = ({
    id,
    text,
    threadId,
    userId,
    sync = true,
    transactionId,
}: {
    id?: string;
    text: RawDraftContentState;
    threadId: string;
    userId: string;
    sync?: boolean;
    transactionId?: number;
}): CommentsAddAction => ({
    _id: getNewCommentId(id),
    type: CommentActionType.COMMENTS_ADD,
    createdAt: getTimestamp(),
    userId,
    threadId,
    text,
    transactionId,
    sync,
});

export const commentsUpdate = ({
    _id,
    text,
    contentUpdate,
    userId,
    sync = true,
    transactionId,
}: {
    _id: string;
    text: RawDraftContentState;
    userId: string;
    sync: boolean;
    contentUpdate?: {
        changes: Record<string, unknown>;
    };
    transactionId?: number;
}): CommentsUpdateAction => ({
    _id,
    type: CommentActionType.COMMENTS_UPDATE,
    text,
    contentUpdate,
    userId,
    transactionId,
    sync,
});

export const commentsDelete = ({
    _id,
    userId,
    threadId,
    sync = true,
    transactionId,
}: {
    _id: string;
    userId: string;
    threadId: string;
    sync: boolean;
    transactionId?: number;
}): CommentsDeleteAction => ({
    _id,
    type: CommentActionType.COMMENTS_DELETE,
    threadId,
    userId,
    transactionId,
    sync,
});
