// Lib
import { isObject, get } from 'lodash/fp';

// Utils
import logger from '../../logger/logger';
import { isDebugEnabled } from '../../debug/debugUtil';
import { manuallyReportError } from '../../analytics/rollbarService';

// Constants
import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_DIFF_UPDATE,
    ELEMENT_FORCE_LOAD,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_UPDATE,
} from '../../../common/elements/elementConstants';
import {
    USER_ACTIVITY_HEARTBEAT,
    USER_CHECKED_NOTIFICATIONS,
    USER_CHECKED_QUICK_NOTES,
    USER_NAVIGATE,
    USER_NAVIGATE_TEMPLATE,
} from '../../../common/users/userConstants';
import { LOGOUT } from '../../auth/authConstants';
import { CommentActionType } from '../../../common/comments/commentConstants';
import {
    ELEMENTS_DESELECT_ALL,
    ELEMENTS_DESELECTED,
    ELEMENTS_FORCE_REMOTE_DESELECT,
    ELEMENTS_SELECTED,
} from '../../../common/elements/selectionConstants';
import { SOCKET_EVENT_NAMES } from '../../../common/utils/socket/socketConstants';
import { DEFAULT_LOG_STYLE } from '../../../node_module_clones/redux-logger/src';

export const getResponseStatus = (response) => {
    const responseCodeStr = isObject(response) ? get('status', response) : response;
    const responseCode = parseInt(responseCodeStr, 10);
    return isNaN(responseCode) ? responseCodeStr : responseCode;
};

export const debugSocketAction = (eventName, action) => {
    if (!isDebugEnabled()) return;

    if (!action.bufferFlush && !action.replay) return;

    const prefix = action.bufferFlush ? 'flush ' : 'replay';
    const color = action.bufferFlush ? '#16558d' : '#ff4e6d';
    const actionType = action.type || eventName;

    console.groupCollapsed(`%c ${prefix} %c${actionType}`, DEFAULT_LOG_STYLE, `color: ${color}`); // eslint-disable-line no-console
    logger.info('%caction', 'color: #03a9f4; font-weight: bold', action);
    console.groupEnd(); // eslint-disable-line no-console
};

export const debugSocketMaxRetryError = (eventName, action, response) => {
    const responseCode = getResponseStatus(response);

    logger.error(
        `Socket Emit: Maximum number of attempts exceeded for '${get('type', action)}'`,
        responseCode,
        response,
        action,
    );

    manuallyReportError({
        errorMessage: 'A socket emission failed',
        error: { code: responseCode },
        custom: { action },
    });
};

const FILTER_OUT_ACTION_TYPE_ON_LOAD = {
    [ELEMENT_FORCE_LOAD]: true,
    [USER_ACTIVITY_HEARTBEAT]: true,
    [USER_NAVIGATE_TEMPLATE]: true,
    [LOGOUT]: true,
    [CommentActionType.COMMENTS_TYPING_PING]: true,
    [CommentActionType.COMMENTS_TYPING_STOP]: true,
    [ELEMENTS_SELECTED]: true,
    [ELEMENTS_DESELECTED]: true,
    [ELEMENTS_DESELECT_ALL]: true,
    [ELEMENTS_FORCE_REMOTE_DESELECT]: true,
};

/**
 * This function filters out actions that should not be replayed when rehydrating the send buffer.
 * E.g. After a page refresh.
 */
export const cleanPersistedBuffer = (sendBuffer) => {
    const buffer = sendBuffer.getBuffer();

    for (const id in buffer) {
        if (!buffer.hasOwnProperty(id)) continue;

        const actionType = get([id, 'action', 'type'], buffer);

        if (FILTER_OUT_ACTION_TYPE_ON_LOAD[actionType]) {
            sendBuffer.removeActionFromBuffer(id);
        }
    }

    return sendBuffer;
};

export const REFRESH_BOARD_ON_RECONNECT_ACTION_TYPES = {
    [ELEMENT_UPDATE]: true,
    [ELEMENT_DELETE]: true,
    [ELEMENT_MOVE_AND_UPDATE]: true,
    [ELEMENT_CREATE]: true,
    [ELEMENT_DIFF_UPDATE]: true,
};

const FILTER_OUT_ACTION_TYPE_ON_FLUSH = {
    [USER_ACTIVITY_HEARTBEAT]: true,
    [LOGOUT]: true,
    [CommentActionType.COMMENTS_TYPING_PING]: true,
    [CommentActionType.COMMENTS_TYPING_STOP]: true,
};

const FILTER_OUT_OLDER_ACTION_TYPES_ON_FLUSH = {
    [ELEMENTS_SELECTED]: true,
    [ELEMENTS_DESELECTED]: true,
    [ELEMENTS_DESELECT_ALL]: true,
    [ELEMENTS_FORCE_REMOTE_DESELECT]: true,
    [USER_CHECKED_NOTIFICATIONS]: true,
    [USER_CHECKED_QUICK_NOTES]: true,
    [SOCKET_EVENT_NAMES.UPDATE_CHANNELS]: true,
    [USER_NAVIGATE]: true,
};

/**
 * This function is similar to cleanPersistedBuffer, however this time we don't want to clear some actions
 * and we want to keep only the newest of others.
 */
export const cleanSendBufferBeforeFlush = (sendBuffer) => {
    const buffer = sendBuffer.getBuffer();

    // Descending order so we encounter the newest actions first
    const orderedKeys = Object.keys(buffer).reverse();

    const alreadyEncounteredActionType = {};

    for (const id of orderedKeys) {
        const eventName = get([id, 'eventName'], buffer);
        const isUpdateChannels = eventName === SOCKET_EVENT_NAMES.UPDATE_CHANNELS;
        const actionType = isUpdateChannels ? SOCKET_EVENT_NAMES.UPDATE_CHANNELS : get([id, 'action', 'type'], buffer);

        // Never send these actions on socket reconnect
        if (FILTER_OUT_ACTION_TYPE_ON_FLUSH[actionType]) {
            sendBuffer.removeActionFromBuffer(id);
        }

        // Only send the most recent actions of this type on socket reconnect
        if (FILTER_OUT_OLDER_ACTION_TYPES_ON_FLUSH[actionType]) {
            // If we've already encountered an action of this type, remove this action
            if (alreadyEncounteredActionType[actionType]) {
                sendBuffer.removeActionFromBuffer(id);
            }

            alreadyEncounteredActionType[actionType] = true;
        }
    }

    return sendBuffer;
};
