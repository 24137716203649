// Utils
import { hasCommandModifier, hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';
import getFocusStartSelection from '../../customRichUtils/selection/getFocusStartSelection';
import getFocusEndSelection from '../../customRichUtils/selection/getFocusEndSelection';

// Handlers
import onLeftOrUp from './onLeftOrUp';
import onRightOrDown from './onRightOrDown';
import onTab from './onTab';

// Constants
import { KEY_CODES } from '../../../../utils/keyboard/keyConstants';

export const TOGGLE_TASK_COMMAND = 'toggle-task';
export const JOIN_PREVIOUS_TASK_COMMAND = 'join-prev-task';
export const JOIN_NEXT_TASK_COMMAND = 'join-next-task';

export const OPEN_ASSIGNMENTS_POPUP = 'open-assignments-popup';
export const OPEN_DUE_DATE_POPUP = 'open-due-date-popup';

const handleRemoval =
    (forward) =>
    (event, { getEditorState }) => {
        const editorState = getEditorState();
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();

        const targetSelection = forward ? getFocusEndSelection(contentState) : getFocusStartSelection(contentState);

        // We only care about backspaces at the start of the task
        if (selection.equals(targetSelection)) {
            // Prevent the mousetrap handlers in keyboardShortcuts.js from firing
            event.preventDefault();
            event.stopPropagation();

            return forward ? JOIN_NEXT_TASK_COMMAND : JOIN_PREVIOUS_TASK_COMMAND;
        }
    };

const handleBackspace = handleRemoval(false);
const handleDelete = handleRemoval(true);

export default (event, args) => {
    // Shift and 2 is @
    if (event.keyCode === KEY_CODES[2] && hasShiftKey(event)) {
        if (hasCommandModifier(event)) return OPEN_DUE_DATE_POPUP;
        return;
    }

    if (event.keyCode === KEY_CODES.PERIOD && hasCommandModifier(event)) return TOGGLE_TASK_COMMAND;

    if (event.keyCode === KEY_CODES.BACKSPACE) return handleBackspace(event, args);
    if (event.keyCode === KEY_CODES.DELETE) return handleDelete(event, args);

    if (event.keyCode === KEY_CODES.LEFT_ARROW) return onLeftOrUp(event, args);
    if (event.keyCode === KEY_CODES.UP_ARROW) return onLeftOrUp(event, args);
    if (event.keyCode === KEY_CODES.RIGHT_ARROW) return onRightOrDown(event, args);
    if (event.keyCode === KEY_CODES.DOWN_ARROW) return onRightOrDown(event, args);

    if (event.keyCode === KEY_CODES.TAB) return onTab(event, args);
};
