// Utils
import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';
import { applyInitialCellValues, createEmptyTableEntries } from './utils/tableInitialisationUtils';

// Constants
import { TABLE_INITIAL_COL_COUNT, TABLE_INITIAL_ROW_COUNT, TABLE_DISPLAY_NAME } from './tableConstants';
import { ELEMENT_DEFAULT_WIDTH } from '../elements/elementConstants';
import { ElementType } from '../elements/elementTypes';

const getInitialTableData = () =>
    applyInitialCellValues(createEmptyTableEntries(TABLE_INITIAL_ROW_COUNT, TABLE_INITIAL_COL_COUNT));

const getInitialTableColWidthsGU = () =>
    Array.from({ length: TABLE_INITIAL_COL_COUNT }, (_, i) => {
        const defaultColWidth = Math.round((ELEMENT_DEFAULT_WIDTH / TABLE_INITIAL_COL_COUNT) * 10) / 10;
        const isLastCol = i === TABLE_INITIAL_COL_COUNT - 1;
        if (!isLastCol) return defaultColWidth;

        // If last column, get the remainder of the width
        const totalPrevWidths = (TABLE_INITIAL_COL_COUNT - 1) * defaultColWidth;
        return ELEMENT_DEFAULT_WIDTH - totalPrevWidths;
    });

const objectConstructorFn = (params) => {
    const { content } = params;
    const data = getInitialTableData();
    const colWidthsGU = getInitialTableColWidthsGU();
    return {
        ...elementConstructor(params),
        elementType: ElementType.TABLE_TYPE,
        content: {
            tableContent: { data, colWidthsGU },
            width: colWidthsGU.reduce((sum, val) => sum + val, 0),
            ...content,
        },
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.TABLE_TYPE,
        objectConstructorFn,
        displayName: TABLE_DISPLAY_NAME,
        ...params,
    });
