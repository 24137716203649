// Utils
import { draftContentHasMatch } from './matchUtils';
import { prop } from '../../../../common/utils/immutableHelper';
import { getThreadId } from '../../../../common/elements/utils/elementPropertyUtils';
import { getThreadId as getCommentThreadId } from '../../../../common/comments/commentModelUtil';

const getCommentText = prop('text');

const commentContainsSearchQuery = (queryRegEx) => (comment) =>
    draftContentHasMatch(queryRegEx)(getCommentText(comment));

export default ({ queryRegEx, comments }) =>
    (element) => {
        const threadId = getThreadId(element);
        if (!threadId || !comments) return false;

        return Object.values(comments)
            .filter((comment) => getCommentThreadId(comment) === threadId)
            .some(commentContainsSearchQuery(queryRegEx));
    };
