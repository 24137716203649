import { useMemo } from 'react';
import hyperFormulaInstance from '../manager/hyperFormulaInstance';
import { CellType, CellValueDetailedType } from 'hyperformula/es';

/**
 * Get cell content information
 *  - rawContent: DraftJS raw content of the cell
 *  - plainText: Plain text of the cell
 *  - shouldRenderPlainText: Cell content does not contain any links or text styling, and can be rendered as plain text
 */
const useMilanoteCellRendererFormulaInfo = (props) => {
    const { row, col, hotCellValue, cellData = {}, hotTableInstance } = props;
    const { locale, elementId } = hotTableInstance.milanoteProps;

    return useMemo(() => {
        const sheet = hyperFormulaInstance.getSheetId(elementId);
        if (sheet === undefined) return [null, null, null];

        const hfType = hyperFormulaInstance.getCellType({ sheet, row, col });
        const hfDetailedType = hyperFormulaInstance.getCellValueDetailedType({ sheet, row, col });
        const hfCellValue = hyperFormulaInstance.getCellValue({ sheet, row, col });

        const isFormula = hfType === CellType.FORMULA || hfType === CellType.ARRAYFORMULA;
        const isFormulaError =
            isFormula &&
            hfDetailedType === CellValueDetailedType.ERROR &&
            typeof hfCellValue === 'object' &&
            'message' in hfCellValue;

        return { hfType, hfDetailedType, hfCellValue, isFormula, isFormulaError };
    }, [hotCellValue, cellData, locale]);
};

export default useMilanoteCellRendererFormulaInfo;
