// Lib
import { createStructuredSelector } from 'reselect';
import { pick } from 'lodash/fp';

// Utils
import { getEmojiData } from '../emojiDataUtil';
import { getReactions } from '../../../../common/elements/utils/elementPropertyUtils';
import { getReactionUserId, getReactionEmojiCode } from '../../../element/reactions/reactionUtil';
import {
    getUserReactionsRecentlyUsed,
    getUserReactionsSkinTone,
} from '../../../../common/users/utils/userPropertyUtils';

// Selectors
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { getCurrentUser, getCurrentUserId } from '../../../user/currentUserSelector';
import { getRenderedElements } from '../../../element/selection/selectedElementsSelector';
import { getAllComments } from '../../../element/comment/store/commentsSelector';

// Constants
import { RECENTLY_USED_DEFAULTS } from './reactionPopupConstants';

const selectedReactionIdsSelector = createShallowSelector(
    (_, { elementIds }) => elementIds,
    (state) => state.get('elements'),
    (state) => getAllComments(state),
    getCurrentUserId,
    (selectedElementIds, elements, comments, currentUserId) => {
        const selectedElements = getRenderedElements(selectedElementIds, elements);
        const selectedComments = Object.values(pick(selectedElementIds, comments));

        const selectedItems = [...selectedElements, ...selectedComments];

        return selectedItems
            .flatMap(getReactions)
            .filter((reaction) => getReactionUserId(reaction) === currentUserId)
            .map(getReactionEmojiCode);
    },
);

const reactionPopupSelector = createStructuredSelector({
    emojiData: getEmojiData,
    recentlyUsed: (state) => {
        const recent = getUserReactionsRecentlyUsed(getCurrentUser(state));

        return recent ? recent.toJS() : RECENTLY_USED_DEFAULTS;
    },
    skinTone: (state) => getUserReactionsSkinTone(getCurrentUser(state)),
    selectedReactionIds: selectedReactionIdsSelector,
});

export default reactionPopupSelector;
