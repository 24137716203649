import { CellTypeNames } from '../CellTypeConstants';
import { CellData } from '../TableTypes';
import { prop } from '../../utils/immutableHelper';

export const getCellValue = prop('value');
export const getCellResultValue = prop('resultValue');

export const getRowCount = <T>(data: Array<Array<T>>): number => (data || []).length;
export const getColumnCount = <T>(data: Array<Array<T>>): number => (data?.[0] || []).length;

// Cell Formulas

export const isFormulaCell = (cellData: CellData): boolean => {
    const cellValue = getCellValue(cellData);
    return !!cellValue && cellValue.toString().startsWith('=');
};

// Cell Types

export const isCellOfType =
    (cellTypeName: CellTypeNames | undefined) =>
    (cellData: CellData): boolean =>
        cellData?.type?.name === cellTypeName;
export const isCellTypeAuto = (cellData: CellData): boolean =>
    isCellOfType(CellTypeNames.AUTO)(cellData) || isCellOfType(undefined)(cellData);
export const isCellTypeNumber = isCellOfType(CellTypeNames.NUMBER);
export const isCellTypeCurrency = isCellOfType(CellTypeNames.CURRENCY);
export const isCellTypePercentage = isCellOfType(CellTypeNames.PERCENTAGE);
export const isCellTypeText = isCellOfType(CellTypeNames.TEXT);
export const isCellTypeDateTime = isCellOfType(CellTypeNames.DATE_TIME);
export const isCellTypeCheckbox = isCellOfType(CellTypeNames.CHECKBOX);

export const isCellTypeNumeric = (cellTypeName: CellTypeNames): boolean =>
    [CellTypeNames.CURRENCY, CellTypeNames.NUMBER, CellTypeNames.PERCENTAGE].includes(cellTypeName);

export const isCellTypeLocaleSpecific = (cellData: CellData): boolean =>
    isCellTypeNumber(cellData) ||
    isCellTypeCurrency(cellData) ||
    isCellTypePercentage(cellData) ||
    isCellTypeDateTime(cellData);
