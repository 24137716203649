import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getElementId, getReactions } from '../../../common/elements/utils/elementPropertyUtils';
import { length } from '../../../common/utils/immutableHelper';
import { getCurrentUserId } from '../../user/currentUserSelector';
import { getGridSize } from '../../utils/grid/gridSizeSelector';

import { getUniqueReactions } from './reactionUtil';

import { canWrite, canGiveFeedback } from '../../../common/permissions/permissionUtil';

import ElementReactionAddButton from './ElementReactionAddButton';
import ElementReactionPillContainer from './ElementReactionPillContainer';

import './ElementReactions.scss';

const mapStateToProps = createStructuredSelector({
    gridSize: getGridSize,
    currentUserId: getCurrentUserId,
});

const ElementReactionList = ({
    element,
    elementId: _elementId,
    reactions: _reactions,
    currentUserId,
    gridSize,
    permissions,
}) => {
    const elementId = _elementId || getElementId(element);
    const reactions = _reactions || getReactions(element) || [];
    const reactionCount = length(reactions);

    const uniqueReactionsList = React.useMemo(() => Object.values(getUniqueReactions(reactions)), [reactions]);

    if (!reactionCount) return null;

    const userCanWriteReactions = canWrite(permissions) || canGiveFeedback(permissions);

    return (
        <div className="ElementReactions">
            {uniqueReactionsList.map(({ emojiCode, userIds }) => (
                <ElementReactionPillContainer
                    elementId={elementId}
                    key={emojiCode}
                    disabled={!userCanWriteReactions}
                    emojiCode={emojiCode}
                    userIds={userIds}
                    currentUserId={currentUserId}
                    gridSize={gridSize}
                    permissions={permissions}
                />
            ))}

            {userCanWriteReactions && <ElementReactionAddButton elementId={elementId} />}
        </div>
    );
};

ElementReactionList.propTypes = {
    currentUserId: PropTypes.string,
    elementId: PropTypes.string,
    permissions: PropTypes.number,
    gridSize: PropTypes.number,
    reactions: PropTypes.oneOfType(PropTypes.object, PropTypes.array),
    element: PropTypes.object,
    toggleReaction: PropTypes.func,
};

export default connect(mapStateToProps)(ElementReactionList);
