// Lib
import * as Immutable from 'immutable';

// Constants
import { CommentActionType } from '../../../../common/comments/commentConstants';
import { USER_NAVIGATE } from '../../../../common/users/userConstants';

const initialState = Immutable.fromJS({
    currentlyEditingId: null,
    map: {},
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CommentActionType.COMMENTS_STOP_EDITING:
            return state.set('currentlyEditingId', null).setIn(['map', action._id, 'isEditing'], false);
        case CommentActionType.COMMENTS_START_EDITING:
            return state.set('currentlyEditingId', action._id).setIn(['map', action._id, 'isEditing'], true);
        case CommentActionType.COMMENTS_PRE_COMMIT:
            return state
                .set('currentlyEditingId', action._id)
                .setIn(['map', action._id, 'text'], Immutable.fromJS(action.text));
        case CommentActionType.COMMENTS_CLEAR_PRE_COMMIT:
            return state.setIn(['map', action._id, 'text'], null);
        case CommentActionType.COMMENTS_CANCEL_EDITING:
        case CommentActionType.COMMENTS_UPDATE:
        case CommentActionType.COMMENTS_DELETE:
            return state.deleteIn(['map', action._id]).set('currentlyEditingId', null);
        case CommentActionType.COMMENTS_ADD:
            return state.deleteIn(['map', action.threadId]).set('currentlyEditingId', null);
        case USER_NAVIGATE:
            return initialState;
        default:
            return state;
    }
};
