// Lib
import * as Immutable from 'immutable';
import { flow, map, get, uniq } from 'lodash/fp';

// Utils
import logger from '../logger/logger';
import { getUserIdFromAction } from '../../common/actionUtils';

// Reducers
import user from './userReducer';

// Constants
import {
    USER_UPDATE,
    USER_REFRESH,
    USER_NAVIGATE,
    USER_CONNECT,
    USER_DISCONNECT,
    USERS_LOAD,
    USER_ACTIVITY_LOAD,
    TRACK_USERS,
} from './userConstants';

import { CURRENT_USER_SET } from './currentUserConstants';
import { ELEMENTS_SELECTED, ELEMENTS_DESELECTED } from '../../common/elements/selectionConstants';
import { ELEMENT_UPDATE, ELEMENT_MOVE_MULTI } from '../../common/elements/elementConstants';
import { CommentActionType } from '../../common/comments/commentConstants';

const initialState = Immutable.Map();

const addTrackedUsers = (state, action) =>
    state.withMutations((mutableState) =>
        action.userIds.forEach((userId) => mutableState.setIn([userId, '_id'], userId)),
    );

const loadUsers = (state, action) => state.mergeDeep(action.users);

const loadActiveUsers = (state, action) => state.mergeDeep(action.users);

/**
 * Delegates the handling of the action to the user.
 * If the user does not exist then add its entry to the state.
 */
const delegateToUser = (state, action) => {
    const userId = getUserIdFromAction(action);
    if (!userId) {
        logger.warn('User action does not contain a user ID', action);
        return state;
    }

    // If we don't have the user then we cannot update it, we must set it
    return state.get(userId)
        ? state.update(userId, (userEntry) => user(userEntry, action))
        : state.set(userId, user(undefined, action));
};

const userActivityLoad = (state, action) =>
    state.withMutations((mutableState) => {
        loadActiveUsers(mutableState, action);
        action.disconnectedUserIds.forEach((disconnectedUserId) =>
            mutableState.update(disconnectedUserId, (userState) => user(userState, action)),
        );
    });

const delegateRemoteActionToUser = (state, action) => (action.remote ? delegateToUser(state, action) : state);

const trackCommentUsers = (state, action) => {
    const userIds = flow(get('comments'), map(get('userId')), uniq)(action);

    return addTrackedUsers(state, { userIds });
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_ACTIVITY_LOAD:
            return userActivityLoad(state, action);
        case USERS_LOAD:
            return loadUsers(state, action);
        case USER_REFRESH:
        case USER_UPDATE:
        case CURRENT_USER_SET:
        case USER_NAVIGATE:
            return delegateToUser(state, action);
        case ELEMENT_MOVE_MULTI:
        case ELEMENT_UPDATE:
        case ELEMENTS_SELECTED:
        case ELEMENTS_DESELECTED:
        case USER_CONNECT:
        case USER_DISCONNECT:
            return delegateRemoteActionToUser(state, action);
        case TRACK_USERS:
            return addTrackedUsers(state, action);
        case CommentActionType.COMMENTS_LOAD:
            return trackCommentUsers(state, action);
        default:
            return state;
    }
};
