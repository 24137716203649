// Lib
import { uniq, head, compact, flow, flatMap, get } from 'lodash/fp';

// Utils
import { getUserIdFromAction } from '../../../common/actionUtils';
import { getPermission } from '../../../common/permissions/elementPermissionsUtil';
import {
    getClosestAncestorBoardId,
    getClosestUpNavigableElementId,
} from '../../../common/elements/utils/elementTraversalUtils';
import { validateElementTypeSavePermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { getPersonalUserChannel } from '../../../common/utils/socket/socketChannelUtil';
import { getLocationParentId, getElementType } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getCurrentUserId } from '../../user/currentUserSelector';

// Constants
import * as ELEMENT_ACTION_TYPE from '../../../common/elements/elementConstants';
import * as ELEMENT_SELECTION_ACTION_TYPES from '../../../common/elements/selectionConstants';
import { LIVE_ACTIVITY_SUFFIX } from '../../../common/utils/socket/socketConstants';

import { CommentActionType } from '../../../common/comments/commentConstants';
import {
    BOARD_NOTIFICATION_PREFERENCES_SET,
    NOTIFICATIONS_MARK_OBSERVED,
    NOTIFICATIONS_MARK_ALL_VIEWED,
    NOTIFICATIONS_MARK_VIEWED,
    NOTIFICATIONS_REMOVE,
    NOTIFICATIONS_MARK_ELEMENTS_VIEWED,
} from '../../../common/notifications/notificationConstants';
import {
    USER_CHECKED_NOTIFICATIONS,
    USER_CHECKED_QUICK_NOTES,
    USER_NAVIGATE,
} from '../../../common/users/userConstants';
import { LOGOUT } from '../../auth/authConstants';
import { getAclIdsSelector } from '../permissions/permissionsSelector';
import {
    ELEMENT_CLIPBOARD_PASTE,
    ELEMENT_CLIPBOARD_SAVE,
} from '../../workspace/shortcuts/clipboard/clipboardConstants';
import { BATCH_ACTION_TYPE } from '../../store/reduxBulkingMiddleware';

export default (store) => {
    /*
    ---------------------
    --- CHANNEL UTILS ---
    ---------------------
    */

    const getElements = () => store.getState().get('elements');

    const getCurrentUserPersonalChannel = () => {
        const state = store.getState();
        const currentUserId = getCurrentUserId(state);

        return getPersonalUserChannel(currentUserId);
    };

    const getBoardLiveChannelName = (boardId) => `${boardId}${LIVE_ACTIVITY_SUFFIX}`;

    const validateSavePermissionsForChannel = (channelId, elementId, updateType) => {
        const state = store.getState();
        const aclIds = getAclIdsSelector(state);
        const element = state.getIn(['elements', elementId]);

        const permissionsForChannel = getPermission(getElements(), channelId, aclIds);

        return !!element && validateElementTypeSavePermission(permissionsForChannel, updateType || element);
    };

    // Will only return the board ID as a channel if the user has save permissions for the element being modified.
    const useBoardChannelIfValid = (channelId, elementId, updateType) =>
        validateSavePermissionsForChannel(channelId, elementId, updateType) ? channelId : undefined;

    // Gets the closest ancestor board of an element (excluding itself, if it is a board)
    const getAncestorBoardChannel = (elementId) => getClosestAncestorBoardId(getElements(), elementId);

    // Gets the closest board of an element (including itself, if it is a board)
    const getBoardChannel = (elementId) => getClosestUpNavigableElementId(getElements(), elementId);

    /*
    ------------------------------------
    --- ELEMENT ACTION TYPE CHANNELS ---
    ------------------------------------
    */

    // get and validate permissions for an element delete action
    const getChannelForDeleteElementAction = (syncAction) => {
        const boardId = getLocationParentId(syncAction);

        const aclIds = getAclIdsSelector(store.getState());

        const hasPermission = validateElementTypeSavePermission(
            getPermission(getElements(), boardId, aclIds),
            getElementType(syncAction),
        );

        return hasPermission ? [getBoardChannel(boardId)] : [];
    };

    // get and validate permissions for an element create actions
    const getChannelsFromElementCreateAction = (syncAction) =>
        compact([useBoardChannelIfValid(getBoardChannel(getLocationParentId(syncAction)), syncAction.id)]);

    const getChannelsFromMultipleElementUpdateAction = flow(
        get('updates'),
        flatMap((update) => [
            useBoardChannelIfValid(getAncestorBoardChannel(update.cloneId || update.id), update.id, update.updateType),
            useBoardChannelIfValid(getBoardChannel(update.cloneId || update.id), update.id, update.updateType),
        ]),
        compact,
        uniq,
    );

    const getChannelsFromMultiMoveAction = flow(
        get('moves'),
        flatMap((move) => [
            useBoardChannelIfValid(getBoardChannel(move.location.parentId), move.id),
            useBoardChannelIfValid(getBoardChannel(move.from.parentId), move.id),
        ]),
        compact,
        uniq,
    );

    const getChannelsFromSelectionAction = (syncAction) => {
        const firstSelectedId = head(syncAction.ids);

        const selectionBoardChannel = compact([
            useBoardChannelIfValid(getAncestorBoardChannel(firstSelectedId), firstSelectedId),
        ]);

        return selectionBoardChannel.map(getBoardLiveChannelName);
    };

    const getValidAncestorBoardChannelFromElementAction = (syncAction) =>
        compact([useBoardChannelIfValid(getAncestorBoardChannel(syncAction.id), syncAction.id)]);

    /*
    ------------------------------------
    --- COMMENT ACTION TYPE CHANNELS ---
    ------------------------------------
    */

    const getChannelsFromCommentAction = (action) => {
        const state = store.getState();

        // First get thread ID
        const commentsMap = state.get('comments');
        const threadId = commentsMap[action._id]?.threadId;

        // Then get thread component's AncestorBoardChannel
        return compact([useBoardChannelIfValid(getAncestorBoardChannel(threadId), threadId)]);
    };

    const getChannelsFromCommentTypingAction = (action) =>
        getChannelsFromCommentAction(action).map(getBoardLiveChannelName);

    /*
    ---------------------------------
    --- USER ACTION TYPE CHANNELS ---
    ---------------------------------
    */

    const getLogoutChannel = (action) => [getPersonalUserChannel(action.userId)];

    const getPersonalChannelsFromUserAction = (action) => [getCurrentUserPersonalChannel(action)];

    const getChannelsFromUserAction = (action) => {
        const userId = getUserIdFromAction(action);
        return action.personal ? [getCurrentUserPersonalChannel(action)] : [userId];
    };

    const getChannelsFromUserNavigateAction = (action) => [getBoardLiveChannelName(action.newBoardId), action.userId];

    /**
     * This handles all non-batched action types.
     */
    const getActionChannelsForStandardActionTypes = (syncAction) => {
        switch (syncAction.type) {
            case ELEMENT_ACTION_TYPE.ELEMENT_CREATE:
                return getChannelsFromElementCreateAction(syncAction);
            case ELEMENT_ACTION_TYPE.ELEMENT_DELETE:
                return getChannelForDeleteElementAction(syncAction);
            case ELEMENT_ACTION_TYPE.ELEMENT_UPDATE:
                return getChannelsFromMultipleElementUpdateAction(syncAction);
            case ELEMENT_ACTION_TYPE.ELEMENT_SET_TYPE:
            case ELEMENT_ACTION_TYPE.ELEMENT_FORCE_LOAD:
                return getValidAncestorBoardChannelFromElementAction(syncAction);
            case ELEMENT_ACTION_TYPE.ELEMENT_MOVE_MULTI:
                return getChannelsFromMultiMoveAction(syncAction);
            case ELEMENT_SELECTION_ACTION_TYPES.ELEMENTS_SELECTED:
            case ELEMENT_SELECTION_ACTION_TYPES.ELEMENTS_DESELECTED:
            case ELEMENT_SELECTION_ACTION_TYPES.ELEMENTS_DESELECT_ALL:
                return getChannelsFromSelectionAction(syncAction);
            case CommentActionType.COMMENTS_TYPING_PING:
            case CommentActionType.COMMENTS_TYPING_STOP:
                return getChannelsFromCommentTypingAction(syncAction);
            case CommentActionType.COMMENTS_ADD:
            case CommentActionType.COMMENTS_UPDATE:
            case CommentActionType.COMMENTS_DELETE:
                return getChannelsFromCommentAction(syncAction);
            case LOGOUT:
                return getLogoutChannel(syncAction);
            case ELEMENT_CLIPBOARD_PASTE:
            case ELEMENT_CLIPBOARD_SAVE:
            case USER_CHECKED_QUICK_NOTES:
            case USER_CHECKED_NOTIFICATIONS:
            case BOARD_NOTIFICATION_PREFERENCES_SET:
            case NOTIFICATIONS_MARK_ELEMENTS_VIEWED:
            case NOTIFICATIONS_MARK_VIEWED:
            case NOTIFICATIONS_MARK_ALL_VIEWED:
            case NOTIFICATIONS_REMOVE:
            case NOTIFICATIONS_MARK_OBSERVED:
                return getPersonalChannelsFromUserAction(syncAction);
            case USER_NAVIGATE:
                return getChannelsFromUserNavigateAction(syncAction);
            default:
                return getChannelsFromUserAction(syncAction);
        }
    };

    const getMultiActionChannels = flow(flatMap(getActionChannelsForStandardActionTypes), uniq, compact);

    /**
     * Finds all the channels for each action in the batch actions and sends it to the
     */
    const getBatchActionChannels = (batchAction) => {
        const actions = batchAction.payload;
        return getMultiActionChannels(actions);
    };

    /**
     * Gets the socket channels relevant for the given action.
     */
    return (syncAction) =>
        syncAction.type === BATCH_ACTION_TYPE
            ? getBatchActionChannels(syncAction)
            : getActionChannelsForStandardActionTypes(syncAction);
};
