export const COMMENT_THREAD_DISPLAY_NAME = 'Comment';

export enum CommentActionType {
    // Synced and persisted actions
    COMMENTS_LOAD = 'COMMENTS_LOAD',
    COMMENTS_ADD = 'COMMENTS_ADD',
    COMMENTS_UPDATE = 'COMMENTS_UPDATE',
    COMMENTS_DELETE = 'COMMENTS_DELETE',

    // Local actions that shouldn't be synced
    COMMENTS_STOP_EDITING = 'COMMENTS_STOP_EDITING',
    COMMENTS_START_EDITING = 'COMMENTS_START_EDITING',
    COMMENTS_CANCEL_EDITING = 'COMMENTS_CANCEL_EDITING',
    COMMENTS_PRE_COMMIT = 'COMMENTS_PRE_COMMIT',
    COMMENTS_CLEAR_PRE_COMMIT = 'COMMENTS_CLEAR_PRE_COMMIT',

    // Remote actions for tracking typing activity
    COMMENTS_TYPING_PING = 'COMMENTS_TYPING_PING',
    COMMENTS_TYPING_STOP = 'COMMENTS_TYPING_STOP',
}

export const COMMENT_ID_PREFIX = 'cm_';
