// Lib
import React from 'react';
import classNames from 'classnames';

// Styles
import './AccountPlanSection.scss';

type AccountPlanSectionProps = {
    className?: string;
    title: React.ReactNode;
    details?: React.ReactNode;
    action: React.ReactNode;
};

const AccountPlanSection = ({ className, title, details, action }: AccountPlanSectionProps) => {
    return (
        <div className={classNames('AccountPlanSection settings-section', className)}>
            <div className="title">{title}</div>
            <div className="details">{details}</div>
            <div className="section-action">{action}</div>
        </div>
    );
};

export default AccountPlanSection;
