// Property accessors
import { getClonedElementType, getElementType } from './elementPropertyUtils';
import { ElementType } from '../elementTypes';

const NON_ENUMERABLE_ELEMENT_TYPES = [ElementType.SKELETON_ELEMENT_TYPE, ElementType.UNKNOWN_ELEMENT_TYPE];

export const ALL_ELEMENT_TYPES: ElementType[] = Object.values(ElementType).filter(
    (elementType) => !NON_ENUMERABLE_ELEMENT_TYPES.includes(elementType),
);

// Element Type functions
export const isElementType = (type: ElementType) => (elementType: unknown) => getElementType(elementType) === type;

export const isSkeleton = isElementType(ElementType.SKELETON_ELEMENT_TYPE);

export const isCard = isElementType(ElementType.CARD_TYPE);
export const isDocument = isElementType(ElementType.DOCUMENT_TYPE);
export const isLink = isElementType(ElementType.LINK_TYPE);
export const isImage = isElementType(ElementType.IMAGE_TYPE);
export const isFile = isElementType(ElementType.FILE_TYPE);
export const isTaskList = isElementType(ElementType.TASK_LIST_TYPE);
export const isTask = isElementType(ElementType.TASK_TYPE);
export const isSketch = isElementType(ElementType.SKETCH_TYPE);
export const isColorSwatch = isElementType(ElementType.COLOR_SWATCH_TYPE);
export const isTable = isElementType(ElementType.TABLE_TYPE);
export const isWorkspace = isElementType(ElementType.WORKSPACE_TYPE);

export const isColumn = isElementType(ElementType.COLUMN_TYPE);
export const isAlias = isElementType(ElementType.ALIAS_TYPE);
export const isClone = isElementType(ElementType.CLONE_TYPE);
export const isBoard = isElementType(ElementType.BOARD_TYPE);

export const isLine = isElementType(ElementType.LINE_TYPE);
export const isAnnotation = isElementType(ElementType.ANNOTATION_TYPE);

export const isCommentThread = isElementType(ElementType.COMMENT_THREAD_TYPE);

export const isBoardLike = (elementType: unknown) => isBoard(elementType) || isAlias(elementType);
export const isTaskLike = (elementType: unknown) => isTaskList(elementType) || isTask(elementType);
export const isElementLinkable = (elementType: unknown) => isClone(elementType) || isAlias(elementType);

export const isNavigableElement = (elementType: unknown) => isBoard(elementType) || isWorkspace(elementType);

export const isDocumentLike = (elementType: unknown) =>
    isDocument(elementType) ||
    (isClone(elementType) && getClonedElementType(elementType) === ElementType.DOCUMENT_TYPE);

export const isDrawing = (elementType: unknown) => isSketch(elementType) || isAnnotation(elementType);

/**
 * Determines if the specified element type can be cloned.
 */
export const canBeCloned = (elementType: unknown) =>
    isCard(elementType) || isDocument(elementType) || isClone(elementType);

/**
 * The only elements that can't have descendants, are comments and lines, now that comments can be attached
 * to other elements.
 */
export const hasDescendants = (elementType: unknown) => !isCommentThread(elementType) && !isLine(elementType);

/**
 * Determines if the element type can have child elements that would be visible if displayed on a board,
 * excluding elements that can only have "Attached" elements (like comments).
 * For example, the only child elements that a card can have is a comment thread.
 * Columns on the other hand can have many elements as children that are visible to users when viewing a board.
 * Boards or aliases can have many elements as children, but none of them are visible to users when viewing a board.
 */
export const hasVisibleDescendantsExcludingAttachments = (elementType: unknown) =>
    isColumn(elementType) || isTaskList(elementType) || isTask(elementType);

/**
 * The same as hasVisibleDescendantsExcludingAttachments except this includes any elements that can have
 * attachments as well, such as cards which can have comment threads attached to them.
 */
export const hasVisibleDescendants = (elementType: unknown) =>
    hasVisibleDescendantsExcludingAttachments(elementType) ||
    isCard(elementType) ||
    isDocument(elementType) ||
    isLink(elementType) ||
    isImage(elementType) ||
    isFile(elementType) ||
    isSketch(elementType) ||
    isColorSwatch(elementType) ||
    isTable(elementType);

export const isContainer = (elementType: unknown) =>
    isBoard(elementType) ||
    isAlias(elementType) ||
    isColumn(elementType) ||
    isTaskList(elementType) ||
    isTask(elementType);

export const isContent = (elementType: unknown) => {
    // elementType here might an element, so need to get the actual element type if so
    const elType = getElementType(elementType);

    switch (elType) {
        case ElementType.COLOR_SWATCH_TYPE:
        case ElementType.CARD_TYPE:
        case ElementType.DOCUMENT_TYPE:
        case ElementType.LINK_TYPE:
        case ElementType.IMAGE_TYPE:
        case ElementType.FILE_TYPE:
        case ElementType.TASK_LIST_TYPE:
        case ElementType.SKETCH_TYPE:
        case ElementType.TABLE_TYPE:
            return true;
        default:
            return false;
    }
};
