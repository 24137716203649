// Lib
import React, { useMemo } from 'react';

// Components
import Comment from './Comment';
import { MNComment } from '../../../common/comments/commentTypes';

type Props = {
    commentList: MNComment[];
    currentUserId: string;
};

const Thread = (props: Props) => {
    const { commentList } = props;

    if (!commentList || !commentList.length) return null;

    const sortedCommentList = useMemo(() => {
        return commentList.sort((commentA, commentB) => commentA.createdAt - commentB.createdAt);
    }, [commentList]);

    return (
        <div className="Thread">
            {sortedCommentList.map((comment) => (
                <Comment
                    {...props}
                    key={comment._id}
                    _id={comment._id}
                    userId={comment.userId}
                    text={comment.text}
                    content={comment.content}
                    createdAt={comment.createdAt}
                />
            ))}
        </div>
    );
};

export default Thread;
