import { isNil, trim, mapValues } from 'lodash/fp';
import { QueryMode, prepareQuery } from './getQueryRegex';

export const choose = (predicate, left, right) => (predicate ? left : right);
export const chooseSingular = (count, left, right) => choose(count === 1, left, right);
export const SPACE = ' ';

export const NBSP_CHAR = '\u00A0';

export const pluralise = (string, count) => chooseSingular(count, string, `${string}s`);

export const listSeparator = (i, count, finalConjunction = 'and') => {
    if (i === count - 2) return ` ${finalConjunction} `;
    if (i >= count - 1) return '';
    return ', ';
};

export const listToEnglish = (list, finalConjunction) =>
    list.reduce((acc, word, index) => {
        const separator = listSeparator(index, list ? list.length : 0, finalConjunction);
        return `${acc}${word}${separator}`;
    }, '');

export const slugify = (str = '', replaceSpace = '-') =>
    str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, replaceSpace)
        .replace(/\-\-+/g, replaceSpace)
        .replace(/[^\w\-]+/g, '')
        .replace(/^-+/, '')
        .replace(/-+$/, '');

export const cleanEmail = (str = '') => (str ? str.toString().toLowerCase().replace(/\s+/g, '') : '');

export const cleanEmailList = (str = '') =>
    str
        ? str
              .split(/[, ]+/)
              .map(cleanEmail)
              .filter((email) => email && email !== '')
        : [];

export const stringValue = (value) => {
    if (typeof value === 'object' && Array.isArray(value)) return value.join(',');
    if (typeof value === 'object') return JSON.stringify(value);
    return !isNil(value) ? value.toString() : '';
};

export const chunkString = (string, chunkSize) => {
    const ret = [];
    const { length } = string;

    for (let i = 0; i < length; i += chunkSize) {
        ret.push(string.substr(i, chunkSize));
    }

    return ret;
};

/**
 * Maps the values of the object to appropriate values for the query string.
 * This can be used in cases where axios doesn't handle arrays correctly.
 */
export const convertToQueryStringValues = (queryObject) => {
    if (!queryObject) return queryObject;
    return mapValues(stringValue, queryObject);
};

// takes an object map and returns alphabetically sorted query string
// @input: { filter_ids=[abc,123], filter_after: 1245 }
// @output: 'filter_ids=abc,123&filter_after=1234'
export const createQueryString = (queryObject) => {
    if (!queryObject) return '';

    return Object.keys(queryObject)
        .filter((key) => !isNil(queryObject[key]))
        .map((key) => `${key}=${stringValue(queryObject[key])}`)
        .join('&');
};

export const tokenizeMatches = (queryText, text) => {
    if (!text) return [];

    const trimmedQueryText = trim(queryText);
    if (trimmedQueryText.length === 0) {
        return [{ textPart: text, isMatch: false }];
    }

    const queryRegEx = new RegExp(`(${prepareQuery(trimmedQueryText, QueryMode.PHRASE)})`, 'gi');

    return text
        .split(queryRegEx)
        .filter((part) => part?.length)
        .map((part) => {
            const isEmptyString = trim(part) === '';
            const isMatch = isEmptyString ? false : !!part.match(queryRegEx);
            return {
                textPart: part,
                isMatch,
            };
        });
};

const BRACKETS_REGEX = /(.*)(\(.+\))(.*)/s;

/**
 * Simply places a &nbsp; character at between the last two words if there's more than 3 words,
 * or places &nbsp; characters between all words inside brackets;
 */
export const preventOrphanedWords = (str) => {
    if (!str) return str;

    const bracketsMatch = str.match(BRACKETS_REGEX);

    if (bracketsMatch) {
        const { 1: startString, 2: bracketString, 3: endString } = bracketsMatch;

        const replacedString = bracketString.replace(/ /g, NBSP_CHAR);

        return `${startString}${replacedString}${endString}`;
    }

    const words = str.split(/\s/).filter((word) => !!word);

    if (words.length < 4) return str;

    const lastWord = words[words.length - 1];
    const lastWordLength = lastWord.length;

    const secondLastWord = words[words.length - 2];
    const secondLastWordLength = secondLastWord.length;
    const last2WordLength = secondLastWordLength + lastWord.length;

    // If the last two words are too long, then don't add the nbsp;
    if (last2WordLength > 20) return str;

    const strLength = str.length;

    // We should add the non-breaking-space if the separator before the last word is a single space
    const shouldAddNbsp =
        str[strLength - lastWordLength - 1] === ' ' &&
        str[strLength - lastWordLength - 2] === secondLastWord[secondLastWordLength - 1];

    if (!shouldAddNbsp) return str;

    const strStartLength = strLength - (secondLastWordLength + 1 + lastWordLength);

    return `${str.substr(0, strStartLength)}${secondLastWord}${NBSP_CHAR}${lastWord}`;
};

const NBSP_REGEX = new RegExp(NBSP_CHAR, 'g');

/**
 * Removes any &nbsp; characters from the text.
 */
export const stripOrphanedWordsSpecialCharacters = (str) => {
    if (!str) return str;

    return str.replace(NBSP_REGEX, ' ');
};

// For debugging purposes
export const debugSpecialCharacters = (str) =>
    str.replace(/[^\u0020-\u007f]/g, (x) => `\\u${`0000${x.charCodeAt(0).toString(16)}`.slice(-4)}`);

export const isNilOrEmptyString = (str) => {
    return isNil(str) || str === '';
};

// Characters that are not in the ASCII range (0-127)
// eslint-disable-next-line no-control-regex
const NOT_ASCII_REGEX = /[^\x00-\x7F]/g;

export const replaceNonAscii = (input, replacement = ' ') => input.replace(NOT_ASCII_REGEX, replacement);
