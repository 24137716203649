// Lib
import { createSelector } from 'reselect';
import getUserLocale from 'get-user-locale';

// Utils
import { propIn } from '../../common/utils/immutableHelper';
import { createShallowSelector } from '../utils/milanoteReselect/milanoteReselect';
import { getUsers } from './usersSelector';
import { isStaff, isUserSubscriptionAdmin } from './userUtil';
import {
    getUserCurrencyPreference,
    getUserEnableDoubleClickToCreateNotes,
    getUserEnableDrawingInkEffect,
    getUserEnableUndoRedoButtonsForIpad,
    getUserEnableUndoRedoButtonsForWeb,
    getUserLanguagePreference,
} from '../../common/users/utils/userPropertyUtils';
import { isPlatformIpad } from '../platform/utils/platformDetailsUtils';
import { getLegacyHybridUseCaseSelector, getPlatformDetailsSelector } from '../platform/platformSelector';
import { LegacyHybridUseCase } from '../platform/platformTypes';
import { getIsFeatureEnabledForCurrentUser } from '../element/feature/elementFeatureSelector';

// Constants
import { ExperimentId } from '../../common/experiments/experimentsConstants';

export const getCurrentUser = propIn(['app', 'currentUser']);
export const getCurrentUserId = (state) => state.getIn(['app', 'currentUser', '_id']);
export const getCurrentUserClientId = (state) => state.getIn(['app', 'currentUser', 'clientId']);
export const getCurrentUserEmail = (state) => state.getIn(['app', 'currentUser', 'email']);
export const getIsCurrentUserEmailVerified = (state) => !!state.getIn(['app', 'currentUser', 'emailVerifiedDate']);
export const getCurrentUserOriginalRootBoardId = (state) => state.getIn(['app', 'currentUser', 'rootBoardId']);
export const getCurrentUserRootWorkspaceId = (state) => state.getIn(['app', 'currentUser', 'rootWorkspaceId']);
export const isCurrentUserFetched = (state) => state.getIn(['app', 'currentUser', 'fetched']);
export const getCurrentUserTemplateRootBoardId = (state) => state.getIn(['app', 'currentUser', 'templateRootId']);
export const getCurrentUserQuickNotesRootBoardId = (state) => state.getIn(['app', 'currentUser', 'quickNotesRootId']);
export const isGuestSelector = (state) => state.getIn(['app', 'currentUser', 'isGuest']);
export const getLastCheckedQuickNotes = (state) => state.getIn(['app', 'currentUser', 'lastCheckedQuickNotes']);
export const getCurrentUserExperiments = (state) => state.getIn(['app', 'currentUser', 'experiments']);

export const getCurrentUserSegment = (state) => state.getIn(['app', 'currentUser', 'userSegment', 'segment']);

/**
 * @deprecated Since 16/09/22. Use `getCurrentUserSegment` instead (though this is required for existing user data)
 */
export const getCurrentUserSegments = (state) => state.getIn(['app', 'currentUser', 'segments']);

export const getCurrentUserLastViewedBoardId = (state) => state.getIn(['app', 'currentUser', 'lastViewedBoard']);
export const getCurrentUserEducation = (state) => state.getIn(['app', 'currentUser', 'education']);
export const getCurrentUserAccountCreationDate = (state) => state.getIn(['app', 'currentUser', 'accountCreationDate']);
export const getCurrentUserOTToken = (state) => state.getIn(['app', 'currentUser', 'otToken']);
export const getCurrentUserAccountRegistrationDate = propIn(['app', 'currentUser', 'accountRegistrationDate']);
export const getCurrentUserInitialisationLastViewedBoardId = (state) =>
    state.getIn(['app', 'currentUser', 'initialisationLastViewedBoardId']);
export const isCurrentUserSettingDrawingInkEffectEnabled = (state) =>
    getUserEnableDrawingInkEffect(state.getIn(['app', 'currentUser']));

export const getCurrentUserRole = propIn(['app', 'currentUser', 'role']);
export const isCurrentUserStaff = (state) => isStaff(getCurrentUserRole(state));

export const isUserSubscribed = (state) => state.getIn(['app', 'currentUser', 'subscription', 'active']);
export const getCurrentUserSubscriptionId = (state) => state.getIn(['app', 'currentUser', 'subscriptionId']);
export const getCurrentUserSubscription = (state) => state.getIn(['app', 'currentUser', 'subscription']);
export const getCurrentUserSubscriptionPlan = (state) => state.getIn(['app', 'currentUser', 'subscription', 'plan']);
export const getCurrentUserCustomer = (state) => state.getIn(['app', 'currentUser', 'customer']);
export const getCurrentUserCustomerId = (state) => state.getIn(['app', 'currentUser', 'customerId']);

export const getCurrentUserUsedPlatforms = (state) => state.getIn(['app', 'currentUser', 'usedPlatforms']);

export const getIsDoubleClickToCreateNotesSettingEnabled = createSelector(
    getCurrentUser,
    getLegacyHybridUseCaseSelector,
    (currentUser, legacyHybridUseCase) => {
        if (legacyHybridUseCase === LegacyHybridUseCase.IOS_CANVAS) return false;

        return getUserEnableDoubleClickToCreateNotes(currentUser);
    },
);

//  This is a special case for the iPad app, where we the default setting is true for the undo/redo buttons
export const getIsUndoRedoButtonsSettingEnabled = createSelector(
    getCurrentUser,
    getPlatformDetailsSelector,
    (currentUser, platformDetails) => {
        if (isPlatformIpad(platformDetails)) {
            return getUserEnableUndoRedoButtonsForIpad(currentUser);
        }
        return getUserEnableUndoRedoButtonsForWeb(currentUser);
    },
);

export const currentUserIsSubscriptionAdminSelector = createSelector(
    getCurrentUser,
    getCurrentUserSubscription,
    (currentUser, subscription) => isUserSubscriptionAdmin(currentUser, subscription),
);

export const subscriptionTeamSelector = createShallowSelector(
    getCurrentUserSubscription,
    getUsers,
    (subscription, users) => {
        const memberIds = subscription && subscription.get('teamMemberIds');
        if (!memberIds) return null;

        return memberIds.map((userId) => users.get(userId)).filter((user) => user);
    },
);

export const subscriptionAdminUserSelector = createSelector(
    getCurrentUserSubscription,
    getUsers,
    (subscription, users) => {
        if (!users || !subscription) return;

        const adminId = subscription.get('adminId');
        return users.get(adminId);
    },
);

export const isUserAvailableSelector = createSelector(
    getCurrentUser,
    isGuestSelector,
    (currentUser, isGuest) => isGuest || (currentUser.get('authenticated') && currentUser.get('fetched')) || false,
);

export const userLanguagePreferenceSelector = createSelector(
    getCurrentUser,
    (currentUser) => getUserLanguagePreference(currentUser) || getUserLocale() || 'en-US',
);

export const userCurrencyPreferenceSelector = createSelector(
    getCurrentUser,
    (currentUser) => getUserCurrencyPreference(currentUser) || 'USD',
);

export const getCurrentUserRootBoardId = createSelector(
    getIsFeatureEnabledForCurrentUser(ExperimentId.userWorkspaces),
    getCurrentUserRootWorkspaceId,
    getCurrentUserOriginalRootBoardId,
    (isUserWorkspaceFeatureEnabled, rootWorkspaceId, rootBoardId) =>
        isUserWorkspaceFeatureEnabled ? rootWorkspaceId : rootBoardId,
);
