import { pick } from 'lodash/fp';

import { getCurrentUserId } from '../../user/currentUserSelector';
import { getRenderedElements } from '../selection/selectedElementsSelector';
import { getElementId, getReactions } from '../../../common/elements/utils/elementPropertyUtils';
import { isCommentId } from '../../../common/comments/commentModelUtil';
import { getAllComments, getComment } from '../comment/store/commentsSelector';

import { getReactionUserId, getReactionEmojiCode } from './reactionUtil';

import { getTimestamp } from '../../../common/utils/timeUtil';
import { asObject, length } from '../../../common/utils/immutableHelper';

import { updateMultipleElements } from '../actions/elementActions';
import { commentsUpdate } from '../comment/store/commentActions';

import { ELEMENT_UPDATE_TYPE } from '../../../common/elements/elementConstants';

const reactionMatches = (userId, emojiCode) => (reaction) =>
    getReactionUserId(reaction) === userId && getReactionEmojiCode(reaction) === emojiCode;

const createReactionState = ({ existingReactionState = [], emojiCode, userId }) => {
    const matchingReaction = existingReactionState.find(reactionMatches(userId, emojiCode));

    if (matchingReaction) {
        return existingReactionState.filter((reaction) => !reactionMatches(userId, emojiCode)(reaction));
    }

    return [
        ...existingReactionState,
        {
            userId,
            emojiCode,
            timestamp: getTimestamp(),
        },
    ];
};

const updateCommentReaction =
    ({ commentIds, emojiCode }) =>
    (dispatch, getState) => {
        const state = getState();
        const userId = getCurrentUserId(state);

        const comments = Object.values(pick(commentIds, getAllComments(state)));
        if (!comments.length) return;

        const [firstComment] = comments;

        const existingReactionState = firstComment?.content?.reactions || [];

        const newReactionState = createReactionState({
            existingReactionState,
            emojiCode,
            userId,
        });

        // If the old reaction state has more reactions compared to the new state, that means that
        // the element already has the reaction, and the reaction needs to be removed from the element
        const shouldRemoveEmoji = existingReactionState.length > newReactionState.length;

        const updateType = shouldRemoveEmoji ? ELEMENT_UPDATE_TYPE.TOGGLE_REACTION : ELEMENT_UPDATE_TYPE.REACTION;

        commentIds.forEach((commentId) => {
            const comment = getComment(state, { _id: commentId });
            if (!comment) return;

            dispatch(
                commentsUpdate({
                    _id: commentId,
                    userId,
                    contentUpdate: {
                        id: comment.threadId,
                        changes: {
                            reactions: newReactionState,
                        },
                        reactionLatest: {
                            emojiCode,
                            userId,
                        },
                        updateType,
                    },
                }),
            );
        });
    };

export const updateElementReaction =
    ({ elementIds, emojiCode }) =>
    (dispatch, getState) => {
        const state = getState();
        const userId = getCurrentUserId(state);

        const elements = getRenderedElements(elementIds, state.get('elements'));
        if (length(elements) < 1) return;

        let reactionExistInElements = false;

        let updates = elements.map((element) => {
            const elementId = getElementId(element);

            const existingReactionState = asObject(getReactions(element)) || [];

            const newReactionState = createReactionState({
                existingReactionState,
                emojiCode,
                userId,
            });

            // If the existing reaction state has more reactions compared to the new state, that means that
            // the element already has the reaction, and the reaction needs to be removed from the element
            const shouldRemoveEmoji = existingReactionState.length > newReactionState.length;
            if (shouldRemoveEmoji) {
                reactionExistInElements = true;
            }

            const updateType = shouldRemoveEmoji ? ELEMENT_UPDATE_TYPE.TOGGLE_REACTION : ELEMENT_UPDATE_TYPE.REACTION;

            return {
                id: elementId,
                changes: { reactions: newReactionState },
                reactionLatest: { emojiCode, userId },
                updateType,
            };
        });

        // If at least 1 of the elements already have the reaction, only apply TOGGLE_REACTION updates to remove emoji
        if (reactionExistInElements) {
            updates = updates.filter(({ updateType }) => updateType === ELEMENT_UPDATE_TYPE.TOGGLE_REACTION);
        }

        dispatch(
            updateMultipleElements({
                silent: true,
                updates,
            }),
        );
    };

export const updateReaction =
    ({ updateIds, emojiCode }) =>
    (dispatch) => {
        const commentIds = updateIds.filter((id) => isCommentId(id));
        const elementIds = updateIds.filter((id) => !isCommentId(id));

        if (elementIds.length) {
            dispatch(
                updateElementReaction({
                    elementIds,
                    emojiCode,
                }),
            );
        }

        if (commentIds.length) {
            dispatch(
                updateCommentReaction({
                    commentIds,
                    emojiCode,
                }),
            );
        }
    };
