// Lib
import { createSelector } from 'reselect';
import { pick } from 'lodash';
import * as Immutable from 'immutable';

// Selectors
import { getCurrentBoardVisibleDescendants } from '../../selectors/currentBoardSelector';

// Utils
import { createDeepSelector, createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { isCommentThread } from '../../../../common/elements/utils/elementTypeUtils';

// Types
import { MNCommentMap } from '../../../../common/comments/commentTypes';
import { MNElement } from '../../../../common/elements/elementModelTypes';

// Store selector
export const getAllComments = (state: any): MNCommentMap => state.get('comments');
export const getComment = (state: any, ownProps: { _id: string }): MNCommentMap => state.get('comments')[ownProps._id];
const getPropsThreadId = (state: any, ownProps: { threadId: string }) => ownProps.threadId;

// ALL COMMENT THREADS

/**
 * Creates a map of threadIds to an array of each thread's comment ids.
 */
export const threadCommentIdsMapSelector = createDeepSelector(getAllComments, (commentsMap) =>
    Object.values(commentsMap).reduce<Record<string, string[]>>((map, comment) => {
        const commentId = comment._id;
        const threadId = comment.threadId;

        map[threadId] = map[threadId] || [];
        map[threadId].push(commentId);

        return map;
    }, {}),
);

export const getThreadCommentIdsSelector = () =>
    createShallowSelector(
        getPropsThreadId,
        threadCommentIdsMapSelector,
        (threadId, threadCommentIdsMap) => threadCommentIdsMap[threadId] || [],
    );

export const getThreadCommentsCountSelector = () =>
    createSelector(getThreadCommentIdsSelector(), (commentIds) => commentIds?.length || 0);

export const makeGetThreadCommentList = () => {
    return createShallowSelector(getThreadCommentIdsSelector(), getAllComments, (commentIdsForThread, allCommentsMap) =>
        Object.values(pick(allCommentsMap, commentIdsForThread)),
    );
};

const getCurrentBoardCommentIdsSelector = createShallowSelector(
    getCurrentBoardVisibleDescendants,
    threadCommentIdsMapSelector,
    (currentElements: Immutable.Map<string, MNElement>, threadIdToCommentsMap) =>
        currentElements.filter(isCommentThread).reduce<string[]>((commentIdArray, comment) => {
            if (!commentIdArray) return [];

            const threadId = comment?._id;
            if (!threadId) return commentIdArray;

            const commentIds = threadIdToCommentsMap[threadId] || [];

            commentIdArray.push(...commentIds);

            return commentIdArray;
        }, []),
);

export const getCurrentBoardCommentsSelector = createShallowSelector(
    getCurrentBoardCommentIdsSelector,
    getAllComments,
    (commentIds, allCommentsMap) => pick(allCommentsMap, commentIds),
);
