// Lib
import jwtDecode from 'jwt-decode';
import { get, isEmpty } from 'lodash/fp';
import logger from '../logger/logger';
import http from '../utils/services/http';

// Utils
import { getApplicationPlatform } from '../utils/platformUtils';
import { setCurrentUserToken } from './authService';
import { getUrlSuccessRedirect } from '../app/routingSelector';
import {
    arePushNotificationsAlreadyPermitted,
    refreshPushNotificationsSubscription,
    unsubscribeFromPushNotifications,
} from '../notifications/push/pushNotificationsManager';
import { isSkeleton } from '../../common/elements/utils/elementTypeUtils';
import { getElementId } from '../../common/elements/utils/elementPropertyUtils';

import recaptchaVerify from '../analytics/recaptchaVerify';
import { getElements } from '../element/selectors/elementSelector';
import { sendAmplitudeEvent } from '../analytics/amplitudeService';

// Actions
import { simpleLogout } from './authLogoutActions';
import { fetchElements } from '../element/elementService';
import { isSafeRedirectUrl, navigateToRootWorkspace, safeRedirectTo } from '../reducers/navigationActions';
import { currentBoardIdSet } from '../reducers/currentBoardId/currentBoardIdActions';

// Constants
import * as AUTH_ACTION_TYPES from './authConstants';
import { METHODS } from '../../common/utils/http/httpConstants';
import { REGISTRATION_EVENT_NAMES } from '../../common/users/userConstants';

export const LOGIN_REGISTER_TIMEOUT = 30000;

const setCurrentUserTokenFromResponse = (response) => {
    const { token } = response.data;
    if (!token) throw new Error('No token found');

    setCurrentUserToken(token);
    return response;
};

const handleSuccessfulAuthenticationRedirection =
    ({ replaceUrl = false, successCallback, dispatch, getState }) =>
    (response) => {
        // Get all skeleton elements and force fetch them, in case the new user has more permissions
        const state = getState();
        const elements = getElements(state);

        const staleBoardIds = elements.filter(isSkeleton).map(getElementId).toArray();
        if (!isEmpty(staleBoardIds)) {
            dispatch(fetchElements({ force: true, elementIds: staleBoardIds }));
        }

        // The browser could be subscribed to notifications for another user, so refresh them for this user
        if (arePushNotificationsAlreadyPermitted()) {
            refreshPushNotificationsSubscription();
        }

        if (successCallback) return successCallback();

        // This will redirect to a "success_redirect" location if it's been provided as a URL parameter
        const successRedirect = getUrlSuccessRedirect(state);
        if (successRedirect && isSafeRedirectUrl(successRedirect)) {
            return safeRedirectTo(successRedirect);
        }

        const token = get(['data', 'token'], response);

        // When logging in/registering, always return to the home board
        if (token && !successRedirect) {
            const decodedToken = jwtDecode(token);

            const { rootBoardId, rootWorkspaceId } = decodedToken;

            const boardId = rootWorkspaceId || rootBoardId;

            if (boardId) dispatch(currentBoardIdSet({ boardId }));
        }

        // Otherwise send them to their root workspace (or the 'from' location if that is provided)
        dispatch(navigateToRootWorkspace(replaceUrl));
    };

export const handleSuccessfulAuthentication =
    ({ replaceUrl, successCallback, dispatch, getState }) =>
    (response) => {
        setCurrentUserTokenFromResponse(response);
        return handleSuccessfulAuthenticationRedirection({ replaceUrl, successCallback, dispatch, getState })(response);
    };

export const loginSubmit = () => ({ type: AUTH_ACTION_TYPES.LOGIN_SUBMIT });
export const loginSuccess = () => ({ type: AUTH_ACTION_TYPES.LOGIN_SUCCESS });

export const sendLogin = (email, password, rest) => async (recaptchaToken) =>
    http({
        method: METHODS.POST,
        url: 'auth/login',
        timeout: LOGIN_REGISTER_TIMEOUT,
        data: {
            ...rest,
            email: email.toLowerCase(),
            password,
            recaptchaToken,
            platform: getApplicationPlatform(),
        },
    });

export const login = (email, password, rest) => (dispatch, getState) => {
    dispatch(loginSubmit());

    return recaptchaVerify('login')
        .then(sendLogin(email, password, rest))
        .then(setCurrentUserTokenFromResponse)
        .then((response) => {
            dispatch(loginSuccess());
            return response;
        })
        .then(handleSuccessfulAuthenticationRedirection({ dispatch, getState }));
};

export const registerSubmit = () => ({ type: AUTH_ACTION_TYPES.REGISTER_SUBMIT });
export const registerSuccess = () => ({ type: AUTH_ACTION_TYPES.REGISTER_SUCCESS });

export const register =
    ({ email, successCallback, registrationEntryPoint, givenName, familyName, ...rest }) =>
    (dispatch, getState) => {
        dispatch(registerSubmit());

        return recaptchaVerify('register')
            .then((recaptchaToken) =>
                http({
                    method: METHODS.POST,
                    url: 'auth/register',
                    timeout: LOGIN_REGISTER_TIMEOUT,
                    data: {
                        email: email.toLowerCase(),
                        recaptchaToken,
                        platform: getApplicationPlatform(),
                        registrationEntryPoint,
                        givenName: givenName.trim(),
                        familyName: familyName.trim(),
                        ...rest,
                    },
                }),
            )
            .then(setCurrentUserTokenFromResponse)
            .then((response) => {
                dispatch(registerSuccess());

                if (registrationEntryPoint) {
                    const eventType = REGISTRATION_EVENT_NAMES[registrationEntryPoint];
                    eventType && sendAmplitudeEvent({ eventType });
                }

                return response;
            })
            .then(handleSuccessfulAuthenticationRedirection({ successCallback, dispatch, getState }));
    };

export const logout = () => (dispatch, getState) => {
    dispatch(simpleLogout());

    unsubscribeFromPushNotifications().catch((err) => {
        logger.error('Failed to unsubscribe from push notifications', err);
    });
};

export const validatePassword = ({ email = '', password = '' }) =>
    http({
        method: METHODS.POST,
        url: 'auth/validate-password',
        timeout: LOGIN_REGISTER_TIMEOUT,
        data: {
            email: email.toLowerCase(),
            password,
        },
    });

export const fetchInvitationEmail = (inviteCode) =>
    http({
        method: METHODS.GET,
        url: `invite/${inviteCode}`,
    })
        .then((response) => response.data.email)
        .catch(() => null);
