// Lib
import { cloneDeep, isNil } from 'lodash';
import Core from 'handsontable/core';
import { sanitize } from 'dompurify';
import { parse } from '../../../../node_module_clones/sheetclip/sheetclip';

// Utils
import {
    createEmptyRowEntries,
    createEmptyTableEntries,
} from '../../../../common/table/utils/tableInitialisationUtils';
import { getMaxCellSelection } from './tableCellSelectionUtils';
import { continueCellFormatting } from './tableCellFormattingUtils';
import convertHtmlToDraft from '../../../../common/utils/editor/convertHtmlToDraft';
import { getNumericDateTimeStringFromCellValue } from '../../../../common/table/utils/tableInputDateTimeUtils';
import { stringifyCellContent } from '../../../../common/table/utils/tableCellContentStringUtils';
import { isFormula } from './tableFormulaUtils';
import { getDateFromString, getIsoStringWithoutTimezone } from '../../../../common/utils/timeUtil';
import { getColumnCount, getRowCount } from '../../../../common/table/utils/tableCellDataPropertyUtils';

// Types
import {
    CellData,
    CellSelection,
    CellSelections,
    TableContentData,
    TableElementChanges,
} from '../../../../common/table/TableTypes';

// Constants
import { CellTypeNames } from '../../../../common/table/CellTypeConstants';
import { DATA_TRANSFER_TYPES } from '../../../workspace/shortcuts/clipboard/clipboardConstants';

export const getFinalRowIndex = (data: Array<Array<CellData>>): number => getRowCount(data) - 1;
export const getFinalColumnIndex = (data: Array<Array<CellData>>): number => getColumnCount(data) - 1;

/**
 * Get the highest row and column indices that contain content
 */
export const lastColWithContent = (hotInstance: Core): number | undefined => {
    if (!hotInstance) return;

    for (let colIndex = hotInstance.countCols() - 1; colIndex >= 0; colIndex--) {
        // Readonly cells may have a null value, but visually contain content
        const cellsMeta = hotInstance.getCellsMeta();
        const currentColCells = cellsMeta.filter((cellMeta) => cellMeta?.col === colIndex);
        const colContainsReadonlyCells = currentColCells.some((cellMeta) => cellMeta.readOnly);

        if (!hotInstance.isEmptyCol(colIndex) || colContainsReadonlyCells) {
            return colIndex;
        }
    }
};

export const lastRowWithContent = (hotInstance: Core): number | undefined => {
    if (!hotInstance) return;

    for (let rowIndex = hotInstance.countRows() - 1; rowIndex >= 0; rowIndex--) {
        // Readonly cells may have a null value, but visually contain content
        const rowMeta = hotInstance.getCellMetaAtRow(rowIndex);
        const rowContainsReadonlyCells = rowMeta.some(({ cellData }): boolean => !!cellData?.readOnly);

        if (!hotInstance.isEmptyRow(rowIndex) || rowContainsReadonlyCells) {
            return rowIndex;
        }
    }
};

/**
 * Check if the selection is within the bounds of the data array
 * This is useful in situations like deleting rows, to make sure the selection is still valid
 */
export const selectionExistsInData = (dataArray: Array<Array<CellData>>, cellSelections: CellSelections): boolean => {
    let maxRow: number | null = null;
    let maxCol: number | null = null;

    cellSelections?.forEach((cellSelection: CellSelection) => {
        const { row, col } = getMaxCellSelection(cellSelection);
        if (isNil(maxRow) || row > maxRow) maxRow = row;
        if (isNil(maxCol) || col > maxCol) maxCol = col;
    });

    return !isNil(maxRow) && !isNil(maxCol) && maxRow < getRowCount(dataArray) && maxCol < getColumnCount(dataArray);
};

/**************************
 * TABLE DATA REORDERING
 **************************/

/**
 * Move sub-content of an array to a final index. This is an in place operation.
 */
export const shiftRowsOrColumns = <T>(movedDataIndices: Array<number>, array: Array<T>, finalIndex: number): void => {
    // remove moving part from data array
    const moving = array.splice(movedDataIndices[0], movedDataIndices.length);

    // put moving part into new spot
    array.splice(finalIndex, 0, ...moving);
};

/**
 * This function is adjust table size to be nRows x nCols
 */
export const adjustTableContentDataSize = (
    dataArray: Array<Array<CellData>>,
    nRows: number,
    nCols: number,
): Array<Array<CellData>> => {
    const nRowsPrev = getRowCount(dataArray);
    const nColsPrev = getColumnCount(dataArray);

    const newDataArray = cloneDeep(dataArray);

    if (newDataArray.length < nRows) {
        newDataArray.push(...createEmptyTableEntries(nRows - newDataArray.length, nCols));
    }

    if (newDataArray.length > nRows) {
        newDataArray.splice(nRows, newDataArray.length - nRows);
    }

    newDataArray.forEach((row) => {
        if (row.length < nCols) {
            row.push(...createEmptyRowEntries(nCols - row.length));
        }

        if (row.length > nCols) {
            row.splice(nCols, row.length - nCols);
        }
    });

    return continueCellFormatting({
        dataArray: newDataArray,
        colIndex: nColsPrev,
        nColsAdded: nCols - nColsPrev,
        rowIndex: nRowsPrev,
        nRowsAdded: nRows - nRowsPrev,
    });
};

/**************************
 * TABLE DATA PROVISION
 **************************/

/**
 * Will push to an array at the next empty index, or create a new index if there are no empty indices
 * @param array the array to push to
 * @param entry the entry to push
 * @returns the index where the entry is pushed to
 */
const pushToNextEmptyIndex = <T>(array: Array<T>, entry: T): number => {
    const index = array.findIndex((val) => val === undefined);
    if (index < 0) {
        return array.push(entry) - 1;
    } else {
        array[index] = entry;
        return index;
    }
};

export const parseTableHTML = (html: string): { values: (string | null)[][]; colWidthsPx: number[] } | null => {
    if (!/(<table)|(<TABLE)/g.test(html)) return null;

    const containerElement = document.createElement('div');
    containerElement.innerHTML = html;
    const tableElement = containerElement.querySelector('table');

    if (!tableElement) return null;

    const colWidthsPx: number[] = [];
    tableElement.querySelectorAll('colgroup > col').forEach((colElement: unknown) => {
        if (colElement instanceof HTMLTableColElement) colWidthsPx.push(parseInt(colElement.width));
    });

    const rowElements = tableElement.querySelectorAll('tr');

    const values: (string | null)[][] = [];

    rowElements.forEach((rowElement, row) => {
        values[row] = values[row] || [];

        const colMapping: { [index: number]: number } = {};
        rowElement.querySelectorAll<HTMLTableCellElement>('td, th').forEach((entryElement, col) => {
            colMapping[col] = pushToNextEmptyIndex(
                values[row],
                stringifyCellContent(convertHtmlToDraft(entryElement.innerHTML)),
            );

            const colSpan = entryElement.colSpan || 0;
            for (let i = 2; i <= colSpan; i++) {
                pushToNextEmptyIndex(values[row], null);
            }

            const rowSpan = entryElement.rowSpan || 0;
            for (let i = 2; i <= rowSpan; i++) {
                const newRow = row + i - 1;
                const newCol = colMapping[col];
                values[newRow] = values[newRow] || [];
                values[newRow][newCol] = null;
            }
        });
    });

    if (values.length === 0) return null;

    return { values, colWidthsPx };
};

export const parseTableHTMLFromClipboardData = (
    clipboardData: DataTransfer,
): { values: (string | null)[][]; colWidthsPx?: number[]; plainText?: string } | null => {
    if (!clipboardData) return null;

    // Try getting table data from HTML if it exists
    const htmlText = sanitize(clipboardData.getData(DATA_TRANSFER_TYPES.HTML));

    const tableData = parseTableHTML(htmlText);
    if (tableData) return tableData;

    // Or else, get table data from plain text if it exists
    const plainText = clipboardData.getData(DATA_TRANSFER_TYPES.PLAIN_TEXT);
    const values = parse(plainText);

    return { values };
};

/**************************
 * TABLE DATA CONVERSIONS
 **************************/

/**
 * Convert values for the CURRENT CELL from Redux format to HotTable format
 * Currently this is just converting date time values from a standard format to a locale specific format
 * but may include more in the future
 * For more info see https://app.milanote.com/1Qctge1JPkug2e/datetime
 * @param cellData
 * @param locale
 */
export const convertReduxValueToHotValue = (cellData: CellData, locale: string): CellData => {
    const { type, value } = cellData || {};
    if (!value || !type) return cellData;

    // If the cell is a date time and doesn't contain a formula, convert the value to a numeric date string
    if (type?.name === CellTypeNames.DATE_TIME && !isFormula(value)) {
        const numericDateTimeString = getNumericDateTimeStringFromCellValue(value, type, locale);
        if (numericDateTimeString) cellData.value = numericDateTimeString;
    }

    return cellData;
};

/**
 * Used purely for testing convertReduxValueToHotValue
 * @param data
 * @param locale
 */
export const convertDataToHotFormat = (data: TableContentData, locale: string): TableContentData => {
    return data?.map((row: Array<CellData>) =>
        row?.map((cellData: CellData) => convertReduxValueToHotValue(cellData, locale)),
    );
};

/**
 * Convert values for the CURRENT CELL from HotTable format to Redux format
 * Currently this is just converting date time values to a standard format (irrespective of local formatting and timezone)
 * but may include more in the future
 * For more info see https://app.milanote.com/1Qctge1JPkug2e/datetime
 * @param cellData
 * @param locale
 */
export const convertCellDataToReduxFormat = (cellData: CellData, locale: string): CellData => {
    const { type, value } = cellData;
    if (!value || !type) return cellData;

    // If the cell is a date time and doesn't contain a formula, convert the value to an ISO string
    if (type?.name === CellTypeNames.DATE_TIME && !isFormula(value)) {
        const date = getDateFromString(value, locale);
        const isoString = getIsoStringWithoutTimezone(date);
        if (isoString) cellData.value = isoString;
    }

    return cellData;
};

/**
 * Convert any values that we want to save differently in redux from hotTable
 * @param data
 * @param locale
 */
export const convertDataToReduxFormat = (data: TableContentData, locale: string): TableContentData => {
    return data?.map((row: Array<CellData>) =>
        row?.map((cellData: CellData) => convertCellDataToReduxFormat(cellData, locale)),
    );
};

/**
 * Take in an object of changes to the element object.
 * Convert any values that we want to save differently in redux
 * @param changes
 * @param locale
 */
export const convertTableElementChangesToReduxFormat = (
    changes: TableElementChanges,
    locale: string,
): TableElementChanges => {
    const newChanges = cloneDeep(changes);
    if (!newChanges.tableContent?.data) return changes;

    newChanges.tableContent.data = convertDataToReduxFormat(newChanges.tableContent?.data, locale);
    return newChanges;
};

export const getCellValueArray = (data: TableContentData): Array<Array<string | null>> =>
    data.map((rows) => rows.map((cell) => cell?.value || null)) || [];
