// Lib
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import MilanoteEditor from '../milanoteEditor/MilanoteEditor';

// Plugins
import { createLinkPlugin, createLinkifyPlugin } from '../plugins/link';
import createHighlightPlugin from '../plugins/textColor';
import createMilanoteShortcutsPlugin from '../plugins/milanoteShortcuts';
import createDeleteOnEmptyPlugin from '../plugins/deleteOnEmpty';
import createEditingHelperPlugin from '../plugins/editingHelper';
import createSearchHighlightPlugin from '../plugins/searchHighlight';
import { createColorConverterPlugin } from '../plugins/universalElement';
import createMarkdownPlugin from '../plugins/markdownSupport';
import createStyleShorcutsPlugin from '../plugins/styleShortcuts';

// Styles
import './TableCellEditor.scss';

const linkifyPlugin = createLinkifyPlugin({});
const linkPlugin = createLinkPlugin({});
const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
const milanoteShortcutsPlugin = createMilanoteShortcutsPlugin();
const markdownPlugin = createMarkdownPlugin({ enableBlockStyles: false });
const styleShortcutsPlugin = createStyleShorcutsPlugin({ enableBlockStyles: false });
const editingHelperPlugin = createEditingHelperPlugin({ preventNewline: true });
const searchHighlightPlugin = createSearchHighlightPlugin();
const highlightPlugin = createHighlightPlugin();
const colorConverterPlugin = createColorConverterPlugin();

const plugins = [
    searchHighlightPlugin,
    highlightPlugin,
    linkPlugin,
    linkifyPlugin,
    milanoteShortcutsPlugin,
    deleteOnEmptyPlugin,
    markdownPlugin,
    colorConverterPlugin,
    styleShortcutsPlugin,
    // Ensure this is last as it uses a handleReturn helper to remove inline styles by default
    editingHelperPlugin,
];

const TableCellEditor = (props) => {
    const editorRef = props.editorRef || useRef(null);

    useEffect(() => {
        if (!editorRef.current?.getEditor()?.editor?.editor) return;

        // This is to add data-hot-input to the DraftJS editor, in order to allow Handsontable to
        // correctly handle input mouse events.
        editorRef.current.getEditor().editor.editor.dataset.hotInput = true;
    }, []);

    return (
        <MilanoteEditor
            {...props}
            plugins={plugins}
            className={classNames('TableCellEditor', props.className)}
            editorRef={editorRef}
            spellCheck={true}
        />
    );
};

TableCellEditor.propTypes = {
    className: PropTypes.string,
    editorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default TableCellEditor;
