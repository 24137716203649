import registerCard from './card';
import registerDocument from './document';
import registerBoard from './board';
import registerImage from './image';
import registerColumn from './column';
import registerLink from './link';
import registerAlias from './alias';
import registerClone from './clone';
import registerLine from './line';
import registerFile from './file';
import registerComment from './comment';
import registerTaskList from './taskList';
import registerTask from './task';
import registerSketch from './drawing/sketch';
import registerAnnotation from './drawing/annotation';
import registerColorSwatch from './colorSwatch';
import registerTable from './table';
// FIXME-USER-WORKSPACES: using boards as placeholder for workspaces for now
import registerWorkspaces from './workspace';

export default () => {
    registerCard();
    registerDocument();
    registerImage();
    registerLink();
    registerColumn();
    registerBoard();
    registerAlias();
    registerClone();
    registerLine();
    registerFile();
    registerComment();
    registerTaskList();
    registerTask();
    registerSketch();
    registerAnnotation();
    registerColorSwatch();
    registerTable();
    registerWorkspaces();
};
