// Utils
import { getLinkifyMatchesFromText } from '../../../../../common/utils/getLinksFromText';

// Constants
import { STYLE_COMMANDS } from '../../richText/richTextConstants';

// Gets all the links in the text, and returns them via the callback
const linkStrategy = (contentBlock, callback, ...other) => {
    const blockType = contentBlock.getType();

    if (blockType === STYLE_COMMANDS.CODE_BLOCK) return;

    const links = getLinkifyMatchesFromText(contentBlock.get('text'));

    if (typeof links !== 'undefined' && links !== null) {
        for (let i = 0; i < links.length; i += 1) {
            const startingPoint = links[i].index;
            const firstChar = contentBlock.getCharacterList().get(startingPoint);

            if (!firstChar.hasStyle(STYLE_COMMANDS.CODE)) {
                callback(links[i].index, links[i].lastIndex);
            }
        }
    }
};

export default linkStrategy;
