import { createSelector } from 'reselect';
import { isFeatureEnabled } from '../../../common/users/utils/userPropertyUtils';
import { propIn } from '../../../common/utils/immutableHelper';

// Constants
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

/**
 * @param {string} feature Feature Flag ID
 * @param {boolean} defaultValue Default value if the isFeatureEnabled is undefined (e.g. guest)
 */
export const getIsFeatureEnabledForCurrentUser = (feature: ExperimentId, defaultValue = false) =>
    createSelector(propIn(['app', 'currentUser']), (currentUser) => {
        const isFeatureEnabledForCurrentUser = isFeatureEnabled(feature, currentUser);

        return isFeatureEnabledForCurrentUser ?? defaultValue;
    });

/**
 * Determines if the current user has the "clientPersistence" feature flag enabled.
 */
export const getIsClientPersistenceEnabledForCurrentUser = getIsFeatureEnabledForCurrentUser(
    ExperimentId.clientPersistence,
);
