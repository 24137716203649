// Lib
import { mapValues, isEmpty, some } from 'lodash/fp';

// Utils
import { createQueryString } from './stringUtil';

// Constants
import { ILLEGAL_URL_SCHEME_REGEX } from '../validation/validationConstants';
import { ALTERNATE_WEB_HOSTS, APP_HOSTNAME, MEDIA_CDN_HOSTNAME, MILANOTE_DOMAIN_SUFFIX } from './urlConstants';

const DOMAIN_REGEX = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?]+)/im;
const QUERY_REGEX = /[?#]/;

export const getDomain = (url) => {
    const domainMatchResult = url?.match(DOMAIN_REGEX);
    return domainMatchResult && domainMatchResult[1];
};

/**
 * Prevents XSS by removing schemes (i.e. javascript:) from the start of a URL so it can be
 * safely used as an anchor's "href" attribute.
 */
export const sanitiseUrl = (url) => {
    if (!url) return url;

    const illegalSchemeMatchResult = url.match(ILLEGAL_URL_SCHEME_REGEX);
    if (illegalSchemeMatchResult) {
        const matchLength = illegalSchemeMatchResult[0].length;
        return url.substring(matchLength);
    }

    return url;
};

export const normaliseUrl = (url) => encodeURIComponent(url.toString().toLowerCase().replace(/\s+/g, ''));

export const stripProtocol = (url) => url.replace(/^(https?):\/\//, '');

export const encodeQueryObject = mapValues(encodeURIComponent);

export const buildUrl = (baseUrl, queryParams, rootSeparator = '?') => {
    if (isEmpty(queryParams)) return baseUrl;

    const alreadyHasParams = baseUrl.indexOf(rootSeparator) !== -1;
    const separator = alreadyHasParams ? '&' : rootSeparator;
    const queryString = createQueryString(queryParams);

    if (!queryString.length) return baseUrl;

    return `${baseUrl}${separator}${queryString}`;
};

export const decodeParams = (query) => {
    if (!query) return {};

    const queryString = /^[?#]/.test(query) ? query.slice(1) : query;

    if (!queryString) return {};

    return queryString.split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
    }, {});
};

export const getQueryString = (url) => {
    if (!url) return null;

    const parts = url.split(QUERY_REGEX);
    return parts[1] || '';
};

export const isImageURL = (url) => url.match(/\.(webp|gif|png|jpe?g)/g);
export const isSvgImageURL = (url) => !!url.match(/\.svg(\?.*)?(#.*)?$/i);

// Group 2 is the position of the BOARD URL
const MILANOTE_URL_REGEX = /(http|https):\/\/.+?\/(.+?)(\/|\?|#|$)/;

export const getBoardIdFromFullUrl = (url) => {
    if (!url) return null;

    const matchResult = url.match(MILANOTE_URL_REGEX);

    if (!matchResult) return null;

    return matchResult[2];
};

export const doesHostBelongToSubDomain = (hostname) => (domainSuffix) =>
    !!(hostname === domainSuffix || hostname?.endsWith(`.${domainSuffix}`));

export const isSubDomainOfHost = (domainSuffix) => (hostname) =>
    !!(hostname === domainSuffix || hostname?.endsWith(`.${domainSuffix}`));

export const isMediaHost = isSubDomainOfHost(MEDIA_CDN_HOSTNAME);
export const isAppHost = isSubDomainOfHost(APP_HOSTNAME);
export const isMilanoteHost = isSubDomainOfHost(MILANOTE_DOMAIN_SUFFIX);

/**
 * Check if a milanote domain
 * @param {string} url
 * @returns {boolean}
 */
export const getIsMilanoteDomain = (url) => isMilanoteHost(getDomain(url)) || isAppHost(getDomain(url));

/**
 * Checks if the URL is an alternate host for the environment (e.g. in development could be localhost or a ngrok domain)
 * @param {string} url
 * @returns {boolean}
 */
export const isAlternateWebHost = (url) => some(doesHostBelongToSubDomain(getDomain(url)), ALTERNATE_WEB_HOSTS);

const SUB_PATH_REGEX = /^\/?(.+?)\//;
export const getFirstSubPathFromPathname = (path) => {
    if (!path) return null;
    const matches = path.match(SUB_PATH_REGEX);

    if (isEmpty(matches)) return null;

    return matches[1];
};

/**
 *
 * @param {string} url
 * @returns {string}
 * @description Sorts the query parameters of a URL in alphabetical order and the values in each parameter in alphabetical order.
 */
export const sortUrlQueryParams = (url) => {
    const urlArray = url.split(QUERY_REGEX);
    if (urlArray.length < 2) return url;

    const [baseUrl, queryParams] = urlArray;

    const sortedQueryParams = queryParams
        .split('&')
        .map((param) => {
            const [key, value] = param.split('=');
            const sortedValues = value?.split(',')?.sort(); // Handle optional values
            return sortedValues ? `${key}=${sortedValues.join(',')}` : param;
        })
        .sort();

    if (!sortedQueryParams.length) {
        return baseUrl; // No valid params after filtering, return base url
    }

    return `${baseUrl}?${sortedQueryParams.join('&')}`;
};

/**
 * Returns the top level cookie domain.
 * - Will return '.milanote.com' if hostname is a subdomain of '.milanote.com',
 * - Will return 'localhost' for local environment
 */
export const getTopLevelCookieDomain = (url) => {
    const domain = getDomain(url);
    if (!isMilanoteHost(domain)) return domain;

    return '.' + MILANOTE_DOMAIN_SUFFIX;
};
