// Lib
import { chunk, assign } from 'lodash';
import { get, isEmpty } from 'lodash/fp';

// Utils
import logger from '../../../logger/logger';
import { http } from '../../../utils/services/http';

// Selectors
import { getAllComments } from './commentsSelector';

// Actions
import { commentsLoad } from './commentActions';

export const fetchComments =
    ({ commentIds }) =>
    (dispatch, getState) => {
        const state = getState();
        const commentsState = getAllComments(state);

        // Don't fetch a comment we already have
        const commentIdsToFetch = commentIds.filter((commentId) => !commentsState[commentId]);

        if (isEmpty(commentIdsToFetch)) return;

        const commentIdBatches = chunk(commentIdsToFetch, 200);

        return Promise.all(commentIdBatches.map((ids) => http({ url: 'comments', params: { ids: ids.join(',') } })))
            .then((responses) => {
                let commentsMap = {};

                responses.forEach((response) => {
                    const comments = get(['data', 'comments'], response);
                    commentsMap = assign(commentsMap, comments);
                });

                if (isEmpty(commentsMap)) return {};

                dispatch(commentsLoad({ comments: commentsMap }));

                return commentsMap;
            })
            .catch((err) => {
                logger.error('Failed to retrieve comments', commentIds, err);
                return {};
            });
    };
