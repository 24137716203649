// Libraries
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Immutable from 'immutable';
import fontLoader from 'webfontloader';

// i18n
import './i18n/initI18n';

// Utilities
import './utils/polyfills';
import configureStore from './store/configureStore';
import platformSingleton from './platform/platformSingleton';
import configureEditorStore from './components/editor/store/configureEditorStore';
import * as analyticsTimingService from './analytics/timingService/analyticsTimingService';

import configureMeasurementsStore from './components/measurementsStore/store/configureMeasurementsStore';
import configureLiveCollaborationStore from './remoteActivity/liveCollaboration/store/configureLiveCollaborationStore';
import registerServiceWorker from './registerServiceWorker';
import { initialiseDebugSettingsFromLocalStorage, isGlobalDebugEnabled } from './debug/debugUtil';
import { getCachedReduxState } from './offline/cache/localCache';
import { createStoreHistory } from './store/history';
import { isPlatformElectronMac } from './platform/utils/platformDetailsUtils';

// Components
import Root from './app/Root';

// Styles
import './scss/main.scss';
import './scss/one-trust.scss';
import 'handsontable/dist/handsontable.full.min.css';

if (isPlatformElectronMac(platformSingleton)) {
    require('./scss/electron-inject.scss');

    window._toggleScreenshotMode = () => {
        window.resizeTo(1920, 1080);

        const appContainer = document.querySelector('#app');
        appContainer && appContainer.classList.toggle('app-electron-screenshot');
    };
}

// in development, load the fonts required. In prod, these are built to a seperate file.
if (process.env.NODE_ENV === 'development') {
    require('./scss/font-face.scss');
}

fontLoader.load({
    custom: {
        families: ['Inter:n4,i4,n6,i6', 'RobotoMono:n4', 'Tiempos-semibold:n7'],
    },
});

/**
 * Index.js
 *  -   Defines the root of the application and creates the store instance as well as
 *      its initial state.
 *  -   Also ensures that the Redux state is synchronised with the URL and vice versa.
 *  -   Installs Immutable.js development tools if we're in the development environment.
 */

/* eslint-disable no-console */
console.log(
    '%cWelcome to %cMilanote',
    'font-size: 18px; font-family: Tiempos',
    'color: #f4511c; font-size: 18px; font-family: "Tiempos", Times, serif; font-weight: 600;',
);
/* eslint-enable no-console */

initialiseDebugSettingsFromLocalStorage();

// Allow immutable JS objects to be printed nicely in the console
if (isGlobalDebugEnabled()) {
    const installDevTools = require('immutable-devtools');
    installDevTools.default(Immutable);
}

// Stores
const liveCollaborationStore = configureLiveCollaborationStore();
const editorStore = configureEditorStore();
const measurementsStore = configureMeasurementsStore();

const hydrationState = await getCachedReduxState();

const initialState = hydrationState || Immutable.Map();

const store = configureStore(initialState, { measurementsStore, editorStore });

analyticsTimingService.connectToStore(store);
registerServiceWorker(store);
const history = createStoreHistory(store);

const mountNode = document.getElementById('app');

ReactDOM.render(
    <Root
        store={store}
        history={history}
        liveCollaborationStore={liveCollaborationStore}
        measurementsStore={measurementsStore}
        editorStore={editorStore}
    />,
    mountNode,
);
