export const WORKSPACE_DISPLAY_NAME = 'Workspace';
export const WORKSPACE_DEFAULT_TITLE = 'New Workspace';

export const WORKSPACE_BOARD_TITLES = {
    WORK: 'Work',
    PERSONAL: 'Personal',
    ARCHIVE: 'Archive',
    SHARED: 'Shared with you',
    OLD_HOME_BOARD: 'Your old home board',
    ROOT: 'Root',
};

export enum WorkspaceTypes {
    CUSTOM = 'CUSTOM',
    ARCHIVE = 'ARCHIVE',
    SHARED = 'SHARED',
    ROOT = 'ROOT',
}
