// Lib
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { cloneDeep, get, isEmpty, isEqual, omit } from 'lodash';
import PropTypes from 'prop-types';
import { CellType } from 'hyperformula/es';

// Utils
import { hasCommandModifier, isControlKeyCommand } from '../../../utils/keyboard/keyboardUtility';
import { createTableCellEntry } from '../../../../common/table/utils/tableInitialisationUtils';
import {
    asCellSelection,
    getAllCellsBetween,
    getColRefHeaderCellSelection,
    getFirstSelection,
    getRowRefHeaderCellSelection,
    getStartCol,
    getStartRow,
    hasSelections,
    isSelectingSingleCell,
    isSelectingSingleSelection,
    repositionAutofillHandle,
} from '../utils/tableCellSelectionUtils';
import { adjustTableContentDataSize, convertCellDataToReduxFormat, shiftRowsOrColumns } from '../utils/tableDataUtils';
import { createCellDataChanges, createCellDataChangesFromSource } from '../utils/tableCellEditingUtils';
import { colorBorders, continueCellFormatting, getNormalisedHotCellValue } from '../utils/tableCellFormattingUtils';
import HotTableClipboardManager from '../manager/HotTableClipboardManager';
import { inferFormulaCellType } from '../utils/tableFormulaUtils';
import hyperFormulaInstance, { getHyperformulaDateFormat } from '../manager/hyperFormulaInstance';
import { getCurrentTransactionId, getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';
import { getTableElementFromHotInstance } from '../utils/tableDOMUtils';

// Types
import { TableAxis, TableOperation } from '../../../../common/table/TableTypes';

// Constants
import { MILANOTE_EDITING_PLUGIN_NAME } from '../modules/MilanoteEditingPlugin';
import { TABLE_ELEMENT_CELL_SELECTIONS_UPDATE } from '../../../../common/elements/selectionConstants';
import { getColumnCount, getRowCount } from '../../../../common/table/utils/tableCellDataPropertyUtils';

const TableCellEditingHandlers = forwardRef(function TableCellEditingHandlersComponent(props, ref) {
    const {
        elementId,
        locale,
        currencyPreference,

        hotTableInstanceRef,
        tableOperationsRef,
        hotTableContainerRef,

        dispatchUpdateTableElement,
        dispatchDeselectElement,

        dispatchUndoAction,
        dispatchRedoAction,
        dispatchFinishUndoingOrRedoing,

        gridSize,
        isSingleSelected,
        isUndoingOrRedoing,
        isDarkMode,
        isReadOnly,
        isResizing,
        showTitle,
        showCaption,
        setIsGridMoving,
        mounted,
        filterQuery,
    } = props;

    // **********************************
    // EFFECTS
    // **********************************

    useEffect(() => {
        if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

        // Set the hyperformula date formats based on current locale
        hyperFormulaInstance.updateConfig({
            dateFormats: getHyperformulaDateFormat(locale),
        });

        if (mounted) {
            hotTableInstanceRef.current.milanoteProps.locale = locale;
            hotTableInstanceRef.current.render();
        }
    }, [locale]);

    // Update filterQuery value in the hotTableInstance
    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        if (mounted) {
            hotTableInstanceRef.current.milanoteProps.filterQuery = filterQuery;
            hotTableInstanceRef.current.render();
        }
    }, [filterQuery]);

    // Update settings for readonly table
    useEffect(() => {
        if (!isReadOnly) return;

        // disable all shortcuts
        hotTableInstanceRef.current?.unlisten();
    }, [isReadOnly]);

    // Update showTitle value in the hotTableInstance
    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        hotTableInstanceRef.current.milanoteProps.showTitle = showTitle;
    }, [showTitle]);

    // Update showCaption value in the hotTableInstance
    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        hotTableInstanceRef.current.milanoteProps.showCaption = showCaption;
    }, [showCaption]);

    // Update isResizing value in the hotTableInstance
    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        hotTableInstanceRef.current.milanoteProps.isResizing = isResizing;
    }, [isResizing]);

    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        hotTableInstanceRef.current.milanoteProps.gridSize = gridSize;

        // Destroy the editor so that it can be recalculated in new grid size
        requestAnimationFrame(() => {
            if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

            hotTableInstanceRef.current.destroyEditor();
        });
    }, [gridSize]);

    // Update relevant values with new darkmode setting
    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        hotTableInstanceRef.current.milanoteProps.isDarkMode = isDarkMode;

        // recalculate the coloured cell borders with the new darkmode setting
        const dataArray = hotTableInstanceRef.current.getData();
        for (let row = 0; row < dataArray.length; row++) {
            for (let col = 0; col < dataArray[row].length; col++) {
                colorBorders(hotTableInstanceRef.current, row, col);
            }
        }
    }, [isDarkMode]);

    useEffect(() => {
        // Override Handsontable undo/redo functionality to use Milanote's instead

        if (!hotTableInstanceRef.current) return;

        const undoRedoPluginInstance = hotTableInstanceRef.current.getPlugin('undoRedo');
        undoRedoPluginInstance.undo = () => {
            if (hotTableInstanceRef.current.isListening()) {
                dispatchUndoAction();
            }
        };
        undoRedoPluginInstance.redo = () => {
            if (hotTableInstanceRef.current.isListening()) {
                dispatchRedoAction();
            }
        };
    }, []);

    // isUndoingOrRedoing state persists as true in the state, and causes issues where changes to cell is cleared
    // after an undo/redo operation. So, always toggle this state back to false after an undo/redo operation on table.
    useEffect(() => {
        if (isSingleSelected && isUndoingOrRedoing) dispatchFinishUndoingOrRedoing();
    }, [isSingleSelected, isUndoingOrRedoing]);

    // **********************************
    // HANDLERS
    // **********************************

    const beforeAutofill = useCallback((_, sourceRange) => {
        HotTableClipboardManager.setTableAutofillSource(hotTableInstanceRef, asCellSelection(sourceRange));
    });

    const beforeCopy = useCallback((data, coords) => {
        const { startRow, startCol, endRow, endCol } = getFirstSelection(coords);

        HotTableClipboardManager.setTableCopySource(hotTableInstanceRef, [startRow, startCol, endRow, endCol]);
    });

    const beforeCut = useCallback((data, coords) => {
        beforeCopy(data, coords);

        // Revert readOnly cells to be editable so that they can be cleared on cut
        const { startRow, startCol, endRow, endCol } = getFirstSelection(coords);
        getAllCellsBetween([[startRow, startCol, endRow, endCol]]).forEach(({ row, col }) => {
            hotTableInstanceRef.current.setCellMetaObject(row, col, { readOnly: false });
        });
    });

    const beforePaste = useCallback((data, coords) => {
        const { startRow, startCol } = getFirstSelection(coords);

        const nRows = getRowCount(data);
        const nCols = getColumnCount(data);

        // Revert readOnly cells to be editable so that they can be pasted on to
        const pastedCellSelection = [startRow, startCol, startRow + nRows, startCol + nCols];
        getAllCellsBetween([pastedCellSelection]).forEach(({ row, col }) => {
            hotTableInstanceRef.current.setCellMetaObject(row, col, { readOnly: false });
        });
    });

    const beforeChange = useCallback(
        (changes, source) => {
            // If source is coming from updating cell meta, save data as is
            if (source.startsWith('CellMeta')) return true;

            const data = tableOperationsRef.current.getHotTableData();

            // ********************************************
            // Convert cell changes [row, col, prevValue, newValue] to [row, col, prevCellData, newCellData]

            let cellDataChanges;
            if (source === 'Autofill.fill' || source === 'CopyPaste.paste') {
                const sourceCellDataArray =
                    source === 'Autofill.fill'
                        ? HotTableClipboardManager.getTableAutofillData()
                        : HotTableClipboardManager.getTableCopiedDataForChanges(changes);

                cellDataChanges = createCellDataChangesFromSource(changes, data, source, sourceCellDataArray, locale);
            } else {
                cellDataChanges = createCellDataChanges(changes, data, locale, currencyPreference);
            }

            // ********************************************
            // Adjust table size to fit all the changes

            const nRows = getRowCount(data);
            const nCols = getColumnCount(data);

            const maxChangeRow = Math.max(...cellDataChanges.map(([row]) => row)) + 1;
            const maxChangeCol = Math.max(...cellDataChanges.map(([_, col]) => col)) + 1;

            const maxRow = Math.max(maxChangeRow, nRows);
            const maxCol = Math.max(maxChangeCol, nCols);

            const newData = adjustTableContentDataSize(data, maxRow, maxCol);

            const nRowsNew = getRowCount(newData) - nRows;
            const nColsNew = getColumnCount(newData) - nCols;

            const transactionId = getNewTransactionId();
            const sourceWithTransactionId = `${source}:${transactionId}`;

            if (nRowsNew > 0) {
                hotTableInstanceRef.current.alter('insert_row_below', nRows - 1, nRowsNew, sourceWithTransactionId);
            }

            if (nCols > 0) {
                hotTableInstanceRef.current.alter('insert_col_end', nCols - 1, nColsNew, sourceWithTransactionId);
            }

            // ********************************************
            // Apply changes if any

            cellDataChanges.forEach((cellDataChange, index) => {
                const [row, col, , newCellData] = cellDataChange;

                changes[index][3] = newCellData.value;
                newData[row][col] = newCellData;

                tableOperationsRef.current.setCellMetaObject(row, col, { cellData: newCellData });
            });

            if (isEqual(newData, data)) return;

            const firstChange = changes[0];
            const [firstChangeRow, firstChangeCol] = firstChange;
            const firstChangeCellSelections = [[firstChangeRow, firstChangeCol, firstChangeRow, firstChangeCol]];
            const undoRedoCellSelectionAction = {
                type: TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
                id: elementId,
                cellSelections:
                    changes.length === 1 ? firstChangeCellSelections : hotTableInstanceRef.current.getSelected(),
            };

            const hotColWidthsGU = tableOperationsRef.current.getHotColWidthsGU();

            dispatchUpdateTableElement({
                id: elementId,
                changes: {
                    tableContent: {
                        data: newData,
                        colWidthsGU: hotColWidthsGU,
                    },
                },
                batchUndoActions: [undoRedoCellSelectionAction],
                batchRedoActions: [undoRedoCellSelectionAction],
                transactionId,
            });
        },
        [elementId, locale, currencyPreference],
    );

    /**
     * After a change is made, for formula cells:
     * 1. infer formula cell's cell type based on precedents cells (if it is not already set)
     * 2. save formula cell's result value to Redux
     */
    const afterChange = useCallback(
        (changes, source) => {
            if (
                !changes ||
                source === 'loadData' ||
                source === 'CellMeta.init' ||
                source === 'CellMeta.updateCellType.effect'
            )
                return;

            const sheet = hyperFormulaInstance.getSheetId(elementId);
            if (sheet === undefined) return;

            const data = cloneDeep(tableOperationsRef.current.getHotTableData());
            const visited = new WeakMap();

            // These sources should NOT trigger the formula type re-inference for the current cell, but may for dependent cells
            const userTypeUpdateSources = ['CellMeta.updateCellType', 'CellMeta.updateCellTypeFormat'];

            const applyCellChangesForFormulas = (elementId, newChanges, cellAddress, level = 0) => {
                if (visited.get(cellAddress)) return;
                visited.set(cellAddress, true);

                const isDirectUpdateCellTypeChange = userTypeUpdateSources.includes(source) && level === 0;
                const isValidCellAddress = 'row' in cellAddress && 'col' in cellAddress;
                if (!isDirectUpdateCellTypeChange && isValidCellAddress) {
                    const { row, col } = cellAddress;

                    const cellDataChanges = {};
                    const tempCellData = cloneDeep(data[row][col]);

                    // Infer type
                    const currentType = tempCellData.type;
                    const inferredType = inferFormulaCellType(cellAddress, hotTableInstanceRef.current);
                    if (inferredType && inferredType.name !== currentType?.name) {
                        cellDataChanges.type = inferredType;
                        tempCellData.type = inferredType;
                    }

                    // For formulas, save result value so that the cell result value can be indexed and searched
                    if (hyperFormulaInstance.getCellType(cellAddress) === CellType.FORMULA) {
                        const hotCellValue = hyperFormulaInstance.getCellValue(cellAddress);

                        tempCellData.value = getNormalisedHotCellValue(hotCellValue, locale, {
                            hfType: hyperFormulaInstance.getCellType(cellAddress),
                            hfDetailedType: hyperFormulaInstance.getCellValueDetailedType(cellAddress),
                        });

                        const currentResultValue = tempCellData.resultValue;
                        const resultValue = convertCellDataToReduxFormat(tempCellData, locale).value;

                        if (resultValue !== undefined && resultValue !== currentResultValue) {
                            cellDataChanges.resultValue = resultValue;
                        }
                    }

                    // Apply changes if cellDataChanges is not empty
                    if (!isEmpty(cellDataChanges)) {
                        newChanges.push([
                            row,
                            col,
                            (cellData) => ({ ...omit(cellData, ['resultValue']), ...cellDataChanges }),
                        ]);
                    }
                }

                const cellDependents = hyperFormulaInstance.getCellDependents(cellAddress);
                cellDependents.forEach((cellDependent) => {
                    applyCellChangesForFormulas(elementId, newChanges, cellDependent, level + 1);
                });
            };

            const cellDataChanges = [];

            changes.forEach(([row, col]) =>
                applyCellChangesForFormulas(elementId, cellDataChanges, { sheet, row, col }),
            );

            if (cellDataChanges.length === 0) return;

            tableOperationsRef.current.setCellData(
                cellDataChanges,
                'CellMeta.updateCellType.effect',
                getCurrentTransactionId(),
            );
        },
        [elementId],
    );

    /**
     * This function is called we want to sync table data from Handsontable over to table data in Redux.
     */
    const afterGridAltered = useCallback((axis, operation, index, amount, source) => {
        // New data needs to be recreated from handsontable data, as formulas might've been updated after grid altered
        const milanoteEditingPluginInstance = hotTableInstanceRef.current.getPlugin(MILANOTE_EDITING_PLUGIN_NAME);
        const cellValues = milanoteEditingPluginInstance.getOriginalSourceDataArray();

        // Make sure all cells have a value in Hyperformula, otherwise we will get
        // an error when trying to perform operations like setIndexesSequence
        // once https://github.com/handsontable/handsontable/issues/10569 has been resolved,
        // check if we can remove this
        if (operation === TableOperation.INSERT) {
            const sheetId = hyperFormulaInstance.getSheetId(elementId);
            const nCols = hotTableInstanceRef.current.countCols();
            const nRows = hotTableInstanceRef.current.countRows();

            // Set new cells to empty in Hyperformula
            if (axis === TableAxis.COL) {
                const newContentSingleRow = new Array(amount).fill('');
                const newContent = new Array(nRows).fill(newContentSingleRow);
                const firstNewCellAddress = { sheet: sheetId, row: 0, col: index };
                hyperFormulaInstance.setCellContents(firstNewCellAddress, newContent);
            } else if (axis === TableAxis.ROW) {
                const newContentSingleRow = new Array(nCols).fill('');
                const newContent = new Array(amount).fill(newContentSingleRow);
                const firstNewCellAddress = { sheet: sheetId, row: index, col: 0 };
                hyperFormulaInstance.setCellContents(firstNewCellAddress, newContent);
            }
        }

        const newData = [];
        cellValues.forEach((rowValues, row) => {
            if (!newData[row]) newData.push([]);

            rowValues.forEach((value, col) => {
                const { cellData = createTableCellEntry() } = hotTableInstanceRef.current.getCellMeta(row, col);

                newData[row].push({ ...cellData, value });
            });
        });

        // Get data with formatting applied
        const newDataWithFormatting = continueCellFormatting({
            dataArray: newData,
            ...(axis === TableAxis.COL &&
                operation === TableOperation.INSERT && {
                    colIndex: index,
                    nColsAdded: amount,
                }),
            ...(axis === TableAxis.ROW &&
                operation === TableOperation.INSERT && {
                    rowIndex: index,
                    nRowsAdded: amount,
                }),
            hotTableInstanceRef,
        });

        // Need to recalculate the autofill handle position after grid altered
        // e.g. bottom right corner may have previously been selected but isn't
        // anymore since rows have been added below
        repositionAutofillHandle(hotTableInstanceRef.current, hotTableContainerRef);

        // If the table is being resized by the handle, don't update the table data in Redux
        if (source === 'resizeHandle') return;

        const transactionIdStr = get(source?.split(':'), 1);
        const transactionId = transactionIdStr && parseInt(transactionIdStr);

        const hotColWidthsGU = tableOperationsRef.current.getHotColWidthsGU();

        dispatchUpdateTableElement({
            id: elementId,
            changes: {
                tableContent: {
                    data: newDataWithFormatting,
                    colWidthsGU: hotColWidthsGU,
                },
                width: hotColWidthsGU.reduce((sum, val) => sum + val, 0),
            },
            transactionId,
        });
    });

    // In Handsontable, moving rows/columns is treated as a temporary visual change, and only persist in the
    // session. The ordering is saved in `hotTableInstanceRef.current.rowIndexMapper.indexesSequence`.
    //
    // Since we want to persist the ordering to the DB, on every rows/columns move, we want to:
    // 1. Reset `hotTableInstanceRef.current.rowIndexMapper.indexesSequence` to its original form, so that the
    //    ordering in rowIndexMapper remains the same
    // 2. Apply the movement to the data in Redux instead, which will be applied to Handsontable
    const afterGridMovement = useCallback((axis, movedIndices, finalIndex, dropIndex, movePossible, orderChanged) => {
        if (!movePossible || !orderChanged) return;

        let newData = cloneDeep(tableOperationsRef.current.getHotTableData());
        let newColWidthsGU = tableOperationsRef.current.getHotColWidthsGU();
        let movedIndicesCopy = cloneDeep(movedIndices);

        // New data needs to be recreated from handsontable data, as formulas might've been updated after grid altered
        const milanoteEditingPluginInstance = hotTableInstanceRef.current.getPlugin(MILANOTE_EDITING_PLUGIN_NAME);
        const cellValues = milanoteEditingPluginInstance.getOriginalSourceDataArray();
        cellValues.forEach((rowValues, row) => {
            rowValues.forEach((value, col) => {
                newData[row][col].value = value;
            });
        });

        const nRows = getRowCount(newData);
        const nCols = getColumnCount(newData);

        if (axis === TableAxis.ROW) {
            const originalIndexesSequence = new Array(nRows).fill(1).map((_, i) => i);
            hotTableInstanceRef.current.rowIndexMapper.setIndexesSequence(originalIndexesSequence);

            shiftRowsOrColumns(movedIndicesCopy, newData, finalIndex);
        }

        if (axis === TableAxis.COL) {
            const originalIndexesSequence = new Array(nCols).fill(1).map((_, i) => i);
            hotTableInstanceRef.current.columnIndexMapper.setIndexesSequence(originalIndexesSequence);

            newData.forEach((row) => shiftRowsOrColumns(movedIndicesCopy, row, finalIndex));
        }

        // Get CellSelections on undo/redo
        const tableInfo = {
            nRows: hotTableInstanceRef.current.countRows(),
            nCols: hotTableInstanceRef.current.countCols(),
        };

        const getRefHeaderCellSelection =
            axis === TableAxis.ROW ? getRowRefHeaderCellSelection : getColRefHeaderCellSelection;

        const moveRangeStart = get(movedIndices, 0);
        const moveRangeEnd = get(movedIndices, 1) || get(movedIndices, 0);

        // Apply changes to Redux state

        setIsGridMoving(true);
        dispatchUpdateTableElement({
            id: elementId,
            changes: {
                tableContent: {
                    data: newData,
                    colWidthsGU: newColWidthsGU,
                },
                width: newColWidthsGU.reduce((sum, val) => sum + val, 0),
            },
            batchUndoActions: [
                {
                    type: TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
                    id: elementId,
                    cellSelections: [getRefHeaderCellSelection(moveRangeStart, moveRangeEnd, tableInfo)],
                },
            ],
            batchRedoActions: [
                {
                    type: TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
                    id: elementId,
                    cellSelections: [
                        getRefHeaderCellSelection(finalIndex, finalIndex + moveRangeEnd - moveRangeStart, tableInfo),
                    ],
                },
            ],
        });

        requestAnimationFrame(() => {
            if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

            hotTableInstanceRef.current.deselectCell();

            const startSelectIndex = finalIndex;
            const endSelectIndex = finalIndex + movedIndicesCopy.length - 1;

            if (axis === TableAxis.ROW) hotTableInstanceRef.current.selectRows(startSelectIndex, endSelectIndex);
            if (axis === TableAxis.COL) hotTableInstanceRef.current.selectColumns(startSelectIndex, endSelectIndex);
        });
    });

    const beforeOnCellMouseDown = useCallback(
        (event, coords) => {
            // @ts-ignore - Handsontable private property
            if (event.isImmediatePropagationEnabled === false) return;

            // If user clicks on a checkbox in a cell, don't select the cell
            if (event.target.parentElement.classList.contains('checkbox-animated')) {
                event.isImmediatePropagationEnabled = false;
            }

            if (event.target.parentElement.parentElement.classList.contains('DraftLinkAnchor')) {
                // If a link has been clicked, don't select the cell
                event.stopImmediatePropagation();
                event.preventDefault();
                return false;
            }

            if (!isSingleSelected) {
                // If the table element is not yet selected, select the table element instead of the cell
                // this would only happen when the cover is not present (e.g. when the table is in mobile view)
                const tableElement = getTableElementFromHotInstance(hotTableInstanceRef.current);
                if (!tableElement) return;

                // Both events are necessary to trigger the selection of the table element
                // In ElementContainer mouseDown alone will never select the element as there is an early
                // return for document mode, and click will only run if there has been a mouseDown event
                tableElement.dispatchEvent(new MouseEvent('mousedown', event));
                tableElement.click();
                return;
            }

            const isRightMouseClick = event.button === 2;
            if (isRightMouseClick) return;

            const hotCurrentCellSelections = tableOperationsRef.current.getHotCurrentCellSelections();

            // ************************************************
            // On Cmd+click, and no cells are selected, deselect element

            if ((hasCommandModifier(event) || isControlKeyCommand(event)) && !hasSelections(hotCurrentCellSelections)) {
                event.stopImmediatePropagation();
                event.preventDefault();

                dispatchDeselectElement(elementId);
                return;
            }

            // ************************************************
            // On clicking the selected cell one more time, immediately go to edit mode. This overrides the default
            // behaviour of requiring a double click to edit a cell, and is more in line with Milanote editing process.

            const isSelectingCurrentlySelectedCell =
                isSelectingSingleSelection(hotCurrentCellSelections) &&
                isSelectingSingleCell(getFirstSelection(hotCurrentCellSelections)) &&
                coords.row === getStartRow(getFirstSelection(hotCurrentCellSelections)) &&
                coords.col === getStartCol(getFirstSelection(hotCurrentCellSelections));

            if (
                !(hasCommandModifier(event) || isControlKeyCommand(event)) &&
                isSelectingCurrentlySelectedCell &&
                hotTableInstanceRef.current.getActiveEditor()
            ) {
                event.stopImmediatePropagation();
                event.preventDefault();

                hotTableInstanceRef.current.getActiveEditor().beginEditing();
            }
        },
        [elementId, isSingleSelected],
    );

    useImperativeHandle(
        ref,
        () => ({
            beforeAutofill,
            beforeCopy,
            beforeCut,
            beforePaste,
            beforeChange,
            afterChange,
            afterGridAltered,
            afterGridMovement,
            beforeOnCellMouseDown,
        }),
        [
            beforeAutofill,
            beforeCopy,
            beforeCut,
            beforePaste,
            beforeChange,
            afterChange,
            afterGridAltered,
            afterGridMovement,
            beforeOnCellMouseDown,
        ],
    );

    return null;
});

TableCellEditingHandlers.propTypes = {
    elementId: PropTypes.string,

    hotTableInstanceRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    tableOperationsRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    hotTableContainerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    updateHotCellSelections: PropTypes.func,
    jumpToTitle: PropTypes.func,
    dispatchUpdateTableElement: PropTypes.func,
    dispatchDeselectElement: PropTypes.func,

    dispatchUndoAction: PropTypes.func,
    dispatchRedoAction: PropTypes.func,
    dispatchFinishUndoingOrRedoing: PropTypes.func,

    gridSize: PropTypes.number,
    isSingleSelected: PropTypes.bool,
    isUndoingOrRedoing: PropTypes.bool,
    isDarkMode: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    showTitle: PropTypes.bool,
    showCaption: PropTypes.bool,
    isResizing: PropTypes.bool,
    locale: PropTypes.string,
    currencyPreference: PropTypes.string,
    mounted: PropTypes.bool,
    filterQuery: PropTypes.string,

    getContextZoomScale: PropTypes.func,
    setIsGridMoving: PropTypes.func,
};

export default TableCellEditingHandlers;
