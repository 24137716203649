import registerWorkspace from '../../../common/workspace/index';
import presentationalComponent from '../board/Board';
import containerComponent from '../board/BoardContainer';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerWorkspace({
        containerComponent,
        presentationalComponent,
        selectedTools: [TOOLBAR_TOOLS.COLOR, TOOLBAR_TOOLS.ICON, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
    });
