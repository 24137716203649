// Lib
import { useMemo } from 'react';
import { convertToRaw } from 'draft-js';

// Utils
import { parseCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';
import { getCellRenderContentState } from '../utils/tableCellFormattingUtils';
import getAllContentInlineStyle from '../../../components/editor/customRichUtils/contentState/getAllContentInlineStyle';
import getLinksFromText from '../../../../common/utils/getLinksFromText';
import rawGetInlineLinks from '../../../../common/utils/editor/rawUtils/rawGetInlineLinks';

/**
 * Get cell formula information from Hyperformula
 *  - hfType: Hyperformula cell type (e.g. FORMULA, VALUE, ARRAY, EMPTY, ARRAYFORMULA)
 *  - hfDetailedType: Hyperformula cell detailed type (e.g. NUMBER, STRING, NUMBER_DATE, NUMBER_TIME, NUMBER_DATETIME)
 *  - hfCellValue: Hyperformula resolved value of the cell
 *  - isFormula: Cell is a formula cell
 *  - isFormulaError: Cell is a formula cell with errors
 */
const useMilanoteCellRendererFormulaInfo = (props, deps) => {
    const { hotCellValue, cellData = {}, hotTableInstance, hfType, hfDetailedType } = props;
    const { textStyle } = cellData || {};
    const { locale } = hotTableInstance.milanoteProps;

    return useMemo(() => {
        const contentState = getCellRenderContentState(cellData, locale, hotCellValue, { hfType, hfDetailedType });
        if (contentState === null) return {};

        const rawContent = convertToRaw(contentState);
        const plainText = contentState.getPlainText();

        const inlineStyles = getAllContentInlineStyle(contentState);
        const hasTextStyling = (!!inlineStyles && inlineStyles.size > 0) || (!!textStyle && textStyle.length > 0);
        const hasLinks =
            // Check for links in plain text
            getLinksFromText(plainText).length > 0 ||
            // Check for inline links in RawDraftContentState
            // NOTE: Cannot use rawContent generated from contentState here
            //       as the contentState are stripped off inline link entities
            rawGetInlineLinks(parseCellContentString(hotCellValue)).length > 0;

        const shouldRenderPlainText = !hasTextStyling && !hasLinks;

        return { rawContent, plainText, shouldRenderPlainText };
    }, deps);
};

export default useMilanoteCellRendererFormulaInfo;
