import { CommentActionType } from '../../../../common/comments/commentConstants';

// Actions
import { keepSelectedElements } from '../../selection/selectionActions';

// Un-synced actions
export const stopEditingComment = ({ _id }) => ({ type: CommentActionType.COMMENTS_STOP_EDITING, _id });
export const startEditingComment =
    ({ _id, elementId }) =>
    (dispatch) => {
        dispatch(keepSelectedElements({ ids: [elementId] }));
        dispatch({ type: CommentActionType.COMMENTS_START_EDITING, _id });
    };
export const cancelEditingComment = ({ _id }) => ({ type: CommentActionType.COMMENTS_CANCEL_EDITING, _id });
export const preCommitComment = ({ _id, text }) => ({ type: CommentActionType.COMMENTS_PRE_COMMIT, _id, text });
export const clearPreCommitComment = ({ _id, text }) => ({ type: CommentActionType.COMMENTS_CLEAR_PRE_COMMIT, _id });
