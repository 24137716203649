import React from 'react';
import PropTypes from 'prop-types';
import { FormatOptions } from '../../../../common/table/CellTypeConstants';
import Checkbox from '../../../components/editor/plugins/checklist/components/Checkbox';
import NumberInput from '../../../components/form/fields/NumberInput';
import { getDefaultTypeObject } from '../../../../common/table/utils/tableCellTypeUtils';

const DecimalFormatOptions = (props) => {
    const { sharedFormattingValues, currentCellType, updateCellTypeFormat, shouldShowOption } = props;

    const defaultValues = getDefaultTypeObject(currentCellType);
    const {
        decimals = defaultValues[FormatOptions.DECIMALS],
        thousandsSeparator = defaultValues[FormatOptions.THOUSANDS_SEPARATOR],
        accounting = defaultValues[FormatOptions.ACCOUNTING],
    } = sharedFormattingValues;

    // max allowed value for minimumFractionDigits and maximumFractionDigits https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#minimumfractiondigits
    const maxDecimals = 20;

    const onDecimalsChange = (targetValue) => {
        // if the user is at auto and wants to decrease, or if the user is at maxDecimals and wants to increase, no change
        if ((decimals === 'auto' && targetValue < 0) || targetValue > maxDecimals) return;

        // if the user is at auto and wants to increase, we want to set decimals to 0
        if (decimals === 'auto' && targetValue === '1') {
            updateCellTypeFormat({ [FormatOptions.DECIMALS]: '0' });
            return;
        }

        // if the user is at 0 and wants to decrease, we want to set decimals to auto
        if (targetValue === '' || (decimals === '0' && targetValue === '-1')) {
            updateCellTypeFormat({ [FormatOptions.DECIMALS]: 'auto' });
            return;
        }

        // otherwise, just update the value
        updateCellTypeFormat({ [FormatOptions.DECIMALS]: targetValue });
    };

    const increaseDecimals = () => {
        const newValue = decimals === 'auto' ? 0 : Number(decimals) + 1;
        onDecimalsChange(String(newValue));
    };

    const decreaseDecimals = () => {
        const newValue = decimals === 'auto' ? 'auto' : Number(decimals) - 1;
        onDecimalsChange(String(newValue));
    };

    return (
        <div className="DecimalFormatOptions celltype-format-section">
            <div className="celltype-format-title">Decimals:</div>
            <div className="celltype-format-inputs">
                <div className="celltype-format-input">
                    <NumberInput
                        value={decimals >= 0 ? decimals : ''}
                        maxValue={maxDecimals}
                        minValue={-1} // need -1 here to allow user to click/arrow down from 0 to get auto
                        onChange={onDecimalsChange}
                        onIncrease={increaseDecimals}
                        onDecrease={decreaseDecimals}
                        placeholder={'Auto'}
                        showButtons
                    />
                </div>

                {shouldShowOption(FormatOptions.THOUSANDS_SEPARATOR) && (
                    <div className="checkbox-input celltype-format-input">
                        <Checkbox
                            checked={thousandsSeparator}
                            onClick={() =>
                                updateCellTypeFormat({ [FormatOptions.THOUSANDS_SEPARATOR]: !thousandsSeparator })
                            }
                        />
                        <span>Thousands separator</span>
                    </div>
                )}
                {shouldShowOption(FormatOptions.ACCOUNTING) && (
                    <div className="checkbox-input celltype-format-input">
                        <Checkbox
                            checked={accounting}
                            onClick={() => updateCellTypeFormat({ [FormatOptions.ACCOUNTING]: !accounting })}
                        />
                        <span>Accounting style</span>
                    </div>
                )}
            </div>
        </div>
    );
};

DecimalFormatOptions.propTypes = {
    sharedFormattingValues: PropTypes.object.isRequired,
    currentCellType: PropTypes.string.isRequired,
    updateCellTypeFormat: PropTypes.func.isRequired,
    shouldShowOption: PropTypes.func.isRequired,
};
export default DecimalFormatOptions;
