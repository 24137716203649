import { registerElementType } from '../elements/elementRegistry';
import elementConstructor from '../elements/elementConstructor';

import { ElementType } from '../elements/elementTypes';
import { WORKSPACE_DEFAULT_TITLE, WORKSPACE_DISPLAY_NAME } from './workspaceConstants';

const objectConstructorFn = (params) => {
    const { content, acl } = params;

    return {
        ...elementConstructor(params),
        elementType: ElementType.WORKSPACE_TYPE,
        content: {
            title: WORKSPACE_DEFAULT_TITLE,
            icon: null,
            color: null,
            ...content,
        },
        acl,
    };
};

export default (params = {}) =>
    registerElementType({
        elementType: ElementType.WORKSPACE_TYPE,
        objectConstructorFn,
        displayName: WORKSPACE_DISPLAY_NAME,
        ...params,
    });
