// Lib
import { cloneDeep } from 'lodash';

// Utils
import { getRawTextFromCellContentString } from './tableCellContentStringUtils';
import { stringShouldFormatAsType } from './tableInputGeneralUtils';
import { getDateFormattingOptions } from './tableInputDateTimeUtils';
import { isFormulaCell } from './tableCellDataPropertyUtils';

// Constants
import { CellTypeConstants, CellTypeNames, CellTypeObjectNames, FormatOptions } from '../CellTypeConstants';
import { CellData, CellTypeObject, DateTimeTypeObject } from '../TableTypes';

/**
 * Returns a boolean value indicating whether the cell type should be updated based on new input
 * Returns true if current type is auto, or new types are currency or percentage
 * or if the current type is DateTime and the new type is Number
 * And the new type is not the same as the current type
 */
export const shouldUpdateCellType = (
    existingTypeName: CellTypeNames | undefined,
    newTypeName: CellTypeNames,
): boolean =>
    existingTypeName !== newTypeName &&
    (!existingTypeName ||
        newTypeName === CellTypeNames.PERCENTAGE ||
        newTypeName === CellTypeNames.CURRENCY ||
        newTypeName === CellTypeNames.DATE_TIME ||
        (existingTypeName === CellTypeNames.DATE_TIME && newTypeName === CellTypeNames.NUMBER));

export const getDefaultTypeObject = <T extends CellTypeObject>(cellTypeName: CellTypeObjectNames): T => {
    const { formattingOptions = {} } = CellTypeConstants[cellTypeName];
    const defaultOptions: { [key: string]: number | boolean | string | null } = { name: cellTypeName };

    // Loop through the formatting options and set the default value for each
    (Object.keys(formattingOptions) as Array<FormatOptions>).forEach((option) => {
        const value = formattingOptions[option]?.defaultValue;
        if (value === undefined) return;
        defaultOptions[option] = value;
    });
    // Necessary to cast to CellTypeObject object here because it will only match the type after the foreach loop
    return defaultOptions as T;
};

export const cellCanBeDraftFormatted = (cellData: CellData): boolean => {
    return !isFormulaCell(cellData) && (!cellData.type || cellData.type.name === CellTypeNames.TEXT);
};

/**
 * If the user enters some content related to a certain cell type (such as
 * a currency symbol in front of a number), update the cell type to match and return the number value
 */
export const formatCellAsType = (
    cellData: CellData,
    oldCellValue: string | null = null,
    locale: string,
    currencyPreference?: string,
): CellData => {
    const { type: existingType, value = '' } = cellData;
    const { name: existingTypeName } = existingType || {};
    const { NUMBER, CURRENCY, PERCENTAGE, TEXT, CHECKBOX } = CellTypeNames;

    // Don't format these types
    if (existingTypeName === TEXT || existingTypeName === CHECKBOX) return cellData;

    const cellValue = value || '';

    const rawText = getRawTextFromCellContentString(cellValue);

    // Return if empty or formula result
    if (!rawText || typeof rawText === 'number') return cellData;

    const formatType = stringShouldFormatAsType(rawText.toString(), true, locale, currencyPreference);
    if (!formatType) return cellData;

    // **************************************************
    // Determine whether to update just the value, or both type and value

    const { newCellType, newCellValue, otherTypeOptions } = formatType;

    // Update cell type if current type is auto, or new types are currency or percentage
    // or if the current type is DateTime and the new type is Number
    const shouldUpdateType = shouldUpdateCellType(existingTypeName, newCellType);

    // Update cell value if we are updating the type, new type matches current, or
    // current type is currency/percentage and the new type is number
    const shouldUpdateValue =
        shouldUpdateType ||
        existingTypeName === newCellType ||
        ((existingTypeName === CURRENCY || existingTypeName === PERCENTAGE) && newCellType === NUMBER);

    // If neither value nor type&value update are allowed, don't update anything
    if (!shouldUpdateValue && !shouldUpdateType) return cellData;

    // **************************************************
    // Apply the new cell, type and formatting values

    const newCellData = cloneDeep(cellData);

    if (shouldUpdateType) newCellData.type = getDefaultTypeObject(newCellType);

    // add any newly calculated type options to the type object, even if the type wasn't updated
    // this is because it's most likely formatting options based on the new input
    newCellData.type = { ...newCellData.type, ...otherTypeOptions } as CellTypeObject;

    // Update the value, either in draftjs format or as a string
    newCellData.value = cellCanBeDraftFormatted(newCellData)
        ? cellValue.replace(rawText, newCellValue.toString())
        : newCellValue.toString();

    // Set some dateTime specific format options
    if (newCellData.type?.name === CellTypeNames.DATE_TIME) {
        newCellData.type = {
            ...newCellData.type,
            ...getDateFormattingOptions(newCellValue, newCellData.type as DateTimeTypeObject, {
                prevCellValue: oldCellValue,
                prevTypeObject: existingType,
                rawInput: rawText.toString(),
                locale,
            }),
        } as DateTimeTypeObject;
    }

    return newCellData;
};
