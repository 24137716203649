// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Selectors
import { getGivenName } from '../../../common/users/userHelper';
import { getUserId } from '../../../common/users/utils/userPropertyUtils';

// Components
import CommentLine from './CommentLine';
import EllipsisAnimation from '../../components/animations/EllipsisAnimation';

// Styles
import './CommentActivity.scss';

const CommentActivity = ({ activeUsers, commentList }) => {
    if (!activeUsers || !activeUsers.size) return null;

    const hasComments = commentList && commentList.length > 0;

    return (
        <div className={classNames('CommentActivity', { 'has-comments': hasComments })}>
            {activeUsers.map((user) => (
                <CommentLine user={user} key={getUserId(user)} active>
                    <span className="user-name">{getGivenName(user) || 'Someone'}</span>
                    <span>
                        {' '}
                        is typing
                        <EllipsisAnimation />
                    </span>
                </CommentLine>
            ))}
        </div>
    );
};

CommentActivity.propTypes = {
    activeUsers: PropTypes.object,
    commentList: PropTypes.array,
};

export default CommentActivity;
