import { BasePlugin } from 'handsontable/plugins';
import { SelectionController } from 'handsontable/selection';
import { CellCoords } from 'handsontable';
import { debounce } from 'lodash';
import { EditorState, SelectionState } from 'draft-js';

// Utils
import {
    getAllCellsBetween,
    getEndCol,
    getEndRow,
    getMaxCellSelection,
    getMinCellSelection,
    getStartCol,
    getStartRow,
    getStartSelectionCoords,
} from '../utils/tableCellSelectionUtils';
import { isFormula } from '../utils/tableFormulaUtils';
import insertTextAt from '../../../components/editor/customRichUtils/insertTextAt';
import applyInlineStyle from '../../../components/editor/customRichUtils/applyInlineStyle';
import removeAllInlineStyle from '../../../components/editor/customRichUtils/removeAllInlineStyle';
import { hasCommandModifier, hasShiftKey } from '../../../utils/keyboard/keyboardUtility';
import replaceSelectionWithText from '../../../components/editor/customRichUtils/replaceSelectionWithText';
import forceFocus from '../../../components/editor/customRichUtils/forceFocus';

// Types
import { CellCoordsObj, CellSelection } from '../../../../common/table/TableTypes';
import { mixColorsForOverlayEffect } from '../../../../common/colors/coreColorUtil';
import { getBackgroundColorHex } from '../utils/tableCellFormattingUtils';
import { getCellRangeName, parseCellName } from '../utils/tableExcelNameUtils';

export const MILANOTE_FORMULA_CELL_REFERENCE_PLUGIN_NAME = 'milanoteFormulaCellReference';

const HIGHLIGHTED_CELL_COLORS = [
    {
        // Blue
        borderColorHex: '16a0e5',
        backgroundColor: 'rgba(22, 160, 229, 0.1)',
    },
    {
        // Orange
        borderColorHex: 'ff8d22',
        backgroundColor: 'rgba(255, 141, 34, 0.1)',
    },
    {
        // Green
        borderColorHex: '4eb85a',
        backgroundColor: 'rgba(78, 184, 90, 0.1)',
    },
    {
        // Purple
        borderColorHex: 'af72ff',
        backgroundColor: 'rgba(175, 114, 255, 0.1)',
    },
    {
        // Pink
        borderColorHex: 'ff6dd4',
        backgroundColor: 'rgba(255, 109, 212, 0.1)',
    },
    {
        // Teal
        borderColorHex: '62dbc8',
        backgroundColor: 'rgba(98, 219, 200, 0.1)',
    },
];

type CellReference = {
    cellSelection: CellSelection;
    cellSelectionName: string;
};

type ReferenceCellsOptions = { asNewArg: boolean };

class MilanoteFormulaCellReferencePlugin extends BasePlugin {
    private prevEditorState: EditorState | null = null;
    private currEditorState: EditorState | null = null;

    private inCellReferenceMode = false;
    private customBordersAdded = false;
    private referenceCells: ((cellSelection: CellSelection, options?: ReferenceCellsOptions) => void) | null = null;

    private isCurrentlyReferencingFromCell: CellCoordsObj | null = null;

    private justReferencedCell = false;
    private cellReferences: CellReference[] = [];

    /**
     * This key will be used as a prop key when enabling this plugin in the HotTable component
     */
    static get PLUGIN_KEY(): string {
        return MILANOTE_FORMULA_CELL_REFERENCE_PLUGIN_NAME;
    }

    /**
     * This function is needed to determine whether or not the plugin is enabled. In the case of this plugin, always
     * enable it.
     */
    isEnabled(): boolean {
        return true;
    }

    /**
     * The `enablePlugin` method is triggered on the `beforeInit` hook.
     * It should contain the plugin's initial setup and hook connections.
     * This method is run only if the `isEnabled` method returns `true`.
     */
    enablePlugin(): void {
        this.addHook('beforeOnCellMouseDown', (...args) => this.onBeforeOnCellMouseDown(...args));
        this.addHook('beforeOnCellMouseOver', (...args) => this.onBeforeOnCellMouseOver(...args));
        this.addHook('beforeChange', () => this.onBeforeChange());

        // @ts-ignore - Custom Milanote hook
        this.addHook('onMilanoteCellEditorChange', (...args) => this.debouncedOnMilanoteCellEditorChange(...args));

        // The `super` method sets the `this.enabled` property to `true`.
        // It is a necessary step to update the plugin's settings properly.
        super.enablePlugin();
    }

    getConfig() {
        const settings = this.hot?.getSettings() as any;

        return settings?.[MILANOTE_FORMULA_CELL_REFERENCE_PLUGIN_NAME] ?? {};
    }

    /******************
     * PLUGIN HOOKS
     ******************/

    onMilanoteCellEditorChange(editorState: EditorState): void {
        if (!this.hot.getActiveEditor()?.isOpened()) return;

        this.currEditorState = editorState;

        // If we referenced a cell using this plugin (using referenceCell(), which updates the Milanote Editor's value),
        // `onMilanoteCellEditorChange` will be called and we should ignore it.
        if (this.justReferencedCell) {
            this.justReferencedCell = false;
            return;
        }

        if (!this.hot.isListening()) return;

        const currentText = editorState.getCurrentContent().getPlainText();
        const currentSelectionStart = editorState.getSelection().getStartOffset();

        // ***********************************************
        // 1. Ignore if input value is not a formula

        if (!isFormula(currentText)) {
            this.unsetCellReferenceMode();
            this.removeCellSelectionReferences();
            return;
        }

        // ***********************************************
        // 2. Re-highlight all cell references in the formula if text has changed

        const prevText = this.prevEditorState?.getCurrentContent().getPlainText();
        if (prevText !== currentText) {
            this.highlightCellSelectionReferences(editorState);
        }

        // ***********************************************
        // 3. Determine whether or not to enter cell reference mode (where clicking on another cell will reference it)

        const containsSpecialCharBeforeSelection = this.editorStateContainsSpecialCharBeforeSelection(editorState);
        const containsCellReferenceBeforeSelection = this.editorStateContainsCellReferenceBeforeSelection(editorState);

        if (containsSpecialCharBeforeSelection) {
            const newEditorState = replaceSelectionWithText('')(editorState);
            this.setCellReferenceMode(newEditorState);
        } else if (containsCellReferenceBeforeSelection) {
            const { cellSelection, cellSelectionName } = containsCellReferenceBeforeSelection;
            const fromCellCoords = getStartSelectionCoords(cellSelection);
            const focusKey = editorState.getSelection().getFocusKey();

            const positionSelection = SelectionState.createEmpty(focusKey).merge({
                focusOffset: currentSelectionStart,
                anchorOffset: currentSelectionStart - cellSelectionName.length,
                hasFocus: true,
            });

            const newEditorState = replaceSelectionWithText('')(forceFocus(editorState, positionSelection));

            this.setCellReferenceMode(newEditorState, fromCellCoords);
        } else {
            this.unsetCellReferenceMode();
        }

        this.prevEditorState = editorState;
    }

    debouncedOnMilanoteCellEditorChange = debounce(this.onMilanoteCellEditorChange, 50);

    onBeforeOnCellMouseDown(
        event: MouseEvent,
        coords: CellCoords,
        TD: HTMLTableCellElement,
        controller: SelectionController,
    ): void {
        // @ts-ignore - Handsontable private property
        if (event.isImmediatePropagationEnabled === false) return;

        if (!this.inCellReferenceMode) return;

        // If in cell referencing mode, when clicking on another cell, we want to prevent the normal behaviour of
        // selecting the clicked cell, and instead reference the cell in the current textarea.

        event.stopImmediatePropagation();
        event.preventDefault();

        // If shift key is pressed, we want to select the cell range from the cell we started referencing from to
        // the cell we clicked on.
        if (hasShiftKey(event) && this.isCurrentlyReferencingFromCell) {
            const { row: startRow, col: startCol } = this.isCurrentlyReferencingFromCell;
            const { row: endRow, col: endCol } = coords.toObject();

            this.referenceCells?.([startRow, startCol, endRow, endCol]);
            return;
        }

        if (hasCommandModifier(event) && !!this.isCurrentlyReferencingFromCell && !!this.currEditorState) {
            const newEditorState = this.insertTextAtEnd(this.currEditorState, ',');
            this.setEditorState(newEditorState);
            this.setCellReferenceMode(newEditorState);
        }

        this.isCurrentlyReferencingFromCell = coords.toObject();

        const { row, col } = coords.toObject();
        this.referenceCells?.([row, col, row, col]);
    }

    onBeforeOnCellMouseOver(
        event: MouseEvent,
        coords: CellCoords,
        TD: HTMLTableCellElement,
        controller: SelectionController,
    ): void {
        if (event.buttons !== 1 || !this.inCellReferenceMode || !this.isCurrentlyReferencingFromCell) return;

        // If in cell referencing mode and currently referencing cells, when hovering over another cell, we want to
        // prevent the normal behaviour of selecting the cell range, and instead reference the cell range in the
        // current textarea.

        event.stopImmediatePropagation();
        event.preventDefault();

        const { row: startRow, col: startCol } = this.isCurrentlyReferencingFromCell;
        const { row: endRow, col: endCol } = coords.toObject();
        this.referenceCells?.([startRow, startCol, endRow, endCol]);
    }

    onBeforeChange(): void {
        this.unsetCellReferenceMode();
        this.removeCellSelectionReferences();
    }

    /******************
     * HELPER FUNCTIONS
     ******************/

    setEditorState(newEditorState: EditorState): void {
        const { cellEditorRef } = this.getConfig();

        if (!cellEditorRef.current) return;

        cellEditorRef.current.onChange(newEditorState);

        this.justReferencedCell = true;
    }

    getCellReferences(inputValue: string): CellReference[] {
        return inputValue
            .replaceAll(' ', '')
            .split(/[=(),?*/^%&\-+<>;]/)
            .reduce<CellReference[]>((acc, str) => {
                const cellSelection = parseCellName(str);

                if (cellSelection) acc.push({ cellSelection, cellSelectionName: str });

                return acc;
            }, []);
    }

    getCharBeforeSelection(value: string, selectionStart: number): string {
        let index = selectionStart - 1;

        while (value.charAt(index) === ' ') {
            index -= 1;
        }

        return value.charAt(index);
    }

    editorStateContainsSpecialCharBeforeSelection = (editorState: EditorState): boolean => {
        const currentText = editorState.getCurrentContent().getPlainText();
        const currentSelectionStart = editorState.getSelection().getStartOffset();

        const charBeforeSelection = this.getCharBeforeSelection(currentText, currentSelectionStart);
        return !!(charBeforeSelection && charBeforeSelection.match(/^[=(,?*/^%&\-+<>;]$/));
    };

    editorStateContainsCellReferenceBeforeSelection = (editorState: EditorState): CellReference | undefined => {
        const currentText = editorState.getCurrentContent().getPlainText();
        const currentSelectionStart = editorState.getSelection().getStartOffset();

        return this.cellReferences.find(
            ({ cellSelectionName }) =>
                currentText.slice(currentSelectionStart - cellSelectionName.length, currentSelectionStart) ===
                cellSelectionName,
        );
    };

    insertTextAtEnd(editorState: EditorState, text: string): EditorState {
        const focusKey = editorState.getSelection().getFocusKey();
        const position = editorState.getSelection().getStartOffset();

        const positionSelection = SelectionState.createEmpty(focusKey).merge({
            anchorOffset: position,
            focusOffset: position,
            hasFocus: true,
        });

        return insertTextAt(text)(positionSelection)(editorState);
    }

    setCellReferenceMode(editorState: EditorState, fromCellCoords: CellCoordsObj | null = null): void {
        this.inCellReferenceMode = true;
        this.isCurrentlyReferencingFromCell = fromCellCoords;

        this.referenceCells = (cellSelection: CellSelection): void => {
            if (
                getStartRow(cellSelection) < 0 ||
                getStartCol(cellSelection) < 0 ||
                getEndRow(cellSelection) < 0 ||
                getEndCol(cellSelection) < 0
            )
                return;

            const { cellEditorRef } = this.getConfig();
            if (cellEditorRef.current === null) return;

            // ***********************************************
            // 1. Update editor value to include the referenced cell range

            const cellSelectionName = getCellRangeName(cellSelection);
            const newEditorState = this.insertTextAtEnd(editorState, cellSelectionName);

            // ***********************************************
            // 2. Highlight referenced cells

            this.highlightCellSelectionReferences(newEditorState);
        };
    }

    unsetCellReferenceMode(): void {
        this.inCellReferenceMode = false;
        this.referenceCells = null;
        this.isCurrentlyReferencingFromCell = null;
    }

    removeCellSelectionReferences(): void {
        this.prevEditorState = null;

        if (this.customBordersAdded) {
            this.hot.updateSettings({ customBorders: [] });
            this.customBordersAdded = false;
        }
    }

    /**
     * With the given cellReferences,
     *   1. Add borders and background color to the cells being referenced
     *   2. Inside the editor, apply text color to the cell references
     */
    highlightCellSelectionReferences(editorState: EditorState): void {
        const currentText = editorState.getCurrentContent().getPlainText();
        this.cellReferences = this.getCellReferences(currentText);

        // ***********************************************
        // 1. For all the cells that are being referenced, add borders around them

        this.hot.updateSettings({
            customBorders: this.cellReferences.map((cellReference, index) => {
                this.customBordersAdded = true;
                const { cellSelection } = cellReference;
                const { borderColorHex } = HIGHLIGHTED_CELL_COLORS[index % HIGHLIGHTED_CELL_COLORS.length];
                return {
                    range: {
                        from: getMinCellSelection(cellSelection),
                        to: getMaxCellSelection(cellSelection),
                    },
                    left: { width: 2, color: `#${borderColorHex}` },
                    right: { width: 2, color: `#${borderColorHex}` },
                    top: { width: 2, color: `#${borderColorHex}` },
                    bottom: { width: 2, color: `#${borderColorHex}` },
                };
            }),
        });

        // ***********************************************
        // 2. For all the cells that are being referenced, add background color on them

        const editorText = editorState.getCurrentContent().getPlainText();
        let lastSearchIndex = 0;

        let newEditorState = removeAllInlineStyle(editorState);

        this.cellReferences.forEach((cellReference, index) => {
            const { cellSelection } = cellReference;
            const { backgroundColor: cellHighlightColor } =
                HIGHLIGHTED_CELL_COLORS[index % HIGHLIGHTED_CELL_COLORS.length];

            const referencedCellCoords = getAllCellsBetween([cellSelection]);
            referencedCellCoords.forEach(({ row, col }) => {
                const cellElement = this.hot.getCell(row, col);
                if (!cellElement) return;

                // Calculate the background color of the cell to look like an overlay
                const currentCellBackground = this.hot?.getCellMeta(row, col).cellData?.background;
                const currentCellBackgroundHex = getBackgroundColorHex(
                    currentCellBackground,
                    // @ts-ignore - valid property
                    this.hot?.milanoteProps.isDarkMode || false,
                );
                cellElement.style.backgroundColor = mixColorsForOverlayEffect(
                    currentCellBackgroundHex,
                    cellHighlightColor,
                ).toString();
            });
        });

        // ***********************************************
        // 3. Inside the editor, apply text color to the cell references

        this.cellReferences.forEach((cellReference, index) => {
            const { cellSelectionName } = cellReference;
            const { borderColorHex } = HIGHLIGHTED_CELL_COLORS[index % HIGHLIGHTED_CELL_COLORS.length];

            const position = editorText.indexOf(cellSelectionName, lastSearchIndex);
            lastSearchIndex = position + cellSelectionName.length;

            const selection = SelectionState.createEmpty(editorState.getSelection().getFocusKey()).merge({
                anchorOffset: position,
                focusOffset: position + cellSelectionName.length,
            });

            newEditorState = applyInlineStyle(newEditorState, selection, `TEXT_HEX-${borderColorHex}`);
        });

        // ***********************************************
        // 4. Set the undo/redo stack to what it was before, or else it each operations to the editorState will be added
        //    to the undo stack

        newEditorState = EditorState.set(newEditorState, { undoStack: editorState.getUndoStack() });
        newEditorState = EditorState.set(newEditorState, { redoStack: editorState.getRedoStack() });

        // ***********************************************
        // 5. Apply the editor state changes to the editor

        this.setEditorState(newEditorState);
    }
}

export default MilanoteFormulaCellReferencePlugin;
