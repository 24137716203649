// Lib
import { cloneDeep, isEmpty } from 'lodash';

// Utils

// Types
import { CellData } from '../TableTypes';

// Constants
import { TABLE_DEFAULT_HEADER_BACKGROUND_COLOR } from '../tableConstants';
import { getColumnCount } from './tableCellDataPropertyUtils';
import { formatCellAsType } from './tableCellTypeUtils';

export const createTableCellEntry = (value: string | null = null, locale = 'en-US'): CellData => {
    if (isEmpty(value) || !value) return { value: null };

    return formatCellAsType({ value }, null, locale) || { value };
};

export const createEmptyRowEntries = (nCols: number): Array<CellData> => {
    return Array.from({ length: nCols }, () => createTableCellEntry());
};

export const createEmptyTableEntries = (nRows: number, nCols: number): Array<Array<CellData>> => {
    return Array.from({ length: nRows }, (_, i) => createEmptyRowEntries(nCols));
};

export const createTableContentDataFromValues = (
    values: Array<Array<string | null>>,
    locale: string,
): Array<Array<CellData>> => {
    return values.map((row) => row.map((value) => createTableCellEntry(value, locale)));
};

export const applyInitialCellValues = (dataArray: Array<Array<CellData>>): Array<Array<CellData>> => {
    const newDataArray = cloneDeep(dataArray);
    const nCols = getColumnCount(newDataArray);

    for (let col = 0; col < nCols; col++) {
        // Set header formatting for first row
        newDataArray[0][col] = {
            textStyle: ['BOLD'],
            background: TABLE_DEFAULT_HEADER_BACKGROUND_COLOR,
            ...newDataArray[0][col],
        };
    }
    return newDataArray;
};
