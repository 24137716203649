// Lib
import { pick, get } from 'lodash/fp';
import { LOCATION_CHANGE } from 'react-router-redux';

// Utils
import { objectIntersection } from '../../milanoteLodash';
import { getLocation } from '../../../app/routingSelector';
import { asObject } from '../../../../common/utils/immutableHelper';
import { createElementWithId, deleteElement } from '../../../../common/elements/elementActions';
import {
    getCurrentlyEditingEditorId,
    getCurrentlyEditingEditorKey,
    getCurrentlyEditingId,
} from '../../../element/selection/currentlyEditingSelector';
import { getCurrentCellSelections } from '../../../element/selection/selectedElementsSelector';
import { getElementLocalData } from '../../../element/local/elementLocalDataSelector';
import { getComment } from '../../../element/comment/store/commentsSelector';

// Actions
import { finishEditingElement } from '../../../element/selection/selectionActions';

// Constants
import * as ELEMENT_ACTION_TYPES from '../../../../common/elements/elementConstants';
import * as SELECTION_ACTION_TYPES from '../../../../common/elements/selectionConstants';
import * as ELEMENT_COUNT_ACTION_TYPES from '../../../user/elementCount/elementCountConstants';
import { CommentActionType } from '../../../../common/comments/commentConstants';
import { END_OPERATION, START_OPERATION } from '../undoRedoConstants';
import { CLIPPER_OPERATION_COMPLETE } from '../../../element/card/clipper/store/clipperConstants';
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';
import {
    ELEMENT_CLIPBOARD_SAVE,
    ELEMENT_CLIPBOARD_UNDO,
} from '../../../workspace/shortcuts/clipboard/clipboardConstants';

// Reverse location & from
const getSingleMoveUndoDetails = (state, { id, location, from }) => ({
    id,
    location: { ...from },
    from: { ...location },
});

const createUndoMoveAction = (state, action) => ({
    type: action.type,
    ...getSingleMoveUndoDetails(state, action),
    sync: action.sync,
});

const createUndoMultiMoveAction = (state, action) => ({
    type: action.type,
    moves: action.moves.map((move) => getSingleMoveUndoDetails(state, move)),
    sync: action.sync,
    timestamp: action.timestamp,
});

const getUndoUpdateType = (state, action) => {
    if (!action.updateType) return;

    if (action.updateType === ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.DUE_DATE) {
        if (!action.undoUpdates || !action.undoUpdates.length) return;

        // If undoing to a state that still has a due date, then we're still setting it
        return action.undoUpdates.some((update) => !!get(['changes', 'dueDate'], update))
            ? ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.DUE_DATE
            : ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.TOGGLE_DUE_DATE;
    }

    if (action.updateType === ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.TOGGLE_DUE_DATE) {
        if (!action.updates || !action.updates.length) return;

        // If no longer showing the due date, then we're setting the due date on undo
        if (action.updates.some((update) => !get(['changes', 'showDueDate'], update))) {
            return ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.DUE_DATE;
        }
    }
};

const getSingleUpdateUndoDetails = (state, { changes, id }) => ({
    id,
    changes: objectIntersection(changes, state.getIn(['elements', id, 'content']).toJS()),
});

const createUndoUpdateAction = (state, action) => {
    const elementUpdateUndoAction = {
        ...action,
        updateType: getUndoUpdateType(state, action),
        // We can explicitly set the undo updates by providing the action with an "undoUpdates" property
        updates: action.undoUpdates
            ? action.undoUpdates
            : action.updates.map((update) => getSingleUpdateUndoDetails(state, update)),
        isUndo: true,
    };

    if (action.batchUndoActions && action.batchUndoActions.length > 0) {
        return {
            type: BATCH_ACTION_TYPE,
            payload: [
                elementUpdateUndoAction,
                ...action.batchUndoActions.map((action) => ({ ...action, isUndo: true })),
            ],
        };
    }

    return elementUpdateUndoAction;
};

const createUndoSetTypeAction = (state, action) => {
    const { id, undoChanges } = action;

    const changes = undoChanges || get('changes', getSingleUpdateUndoDetails(state, action));

    return {
        ...action,
        changes,
        elementType: state.getIn(['elements', id, 'elementType']),
    };
};

const createUndoMoveAndUpdateAction = (state, action) => ({
    ...createUndoMoveAction(state, action),
    ...getSingleUpdateUndoDetails(state, action),
});

const createUndoCreateAction = (state, action) =>
    deleteElement({
        id: action.id,
        elementType: action.elementType,
        // Need to add this to ensure the action is synced with the server
        location: {
            parentId: action.location.parentId,
        },
        sync: action.sync,
    });

const createUndoDeleteAction = (state, action) => {
    const { id } = action;

    const existingElement = asObject(state.getIn(['elements', id]));

    return createElementWithId({
        ...existingElement,
        id,
    });
};

const createUndoRouteChange = (state, action) => ({
    type: LOCATION_CHANGE,
    payload: {
        ...getLocation(state),
        action: 'POP',
    },
});

const createUndoSelectAction = (state, action) => ({
    type: SELECTION_ACTION_TYPES.ELEMENTS_DESELECTED,
    ids: action.ids,
    sync: action.sync,
});

/**
 * This will return the cursor to the previously edited element, or just finish editing
 * if there was no previously edited element.
 */
const createUndoEditStartAction = (state, action) => {
    // Get the currently edited element element and create a return edit action
    const currentlyEditingElementId = getCurrentlyEditingId(state);
    const currentEditorId = getCurrentlyEditingEditorId(state);
    const currentEditorKey = getCurrentlyEditingEditorKey(state);

    if (currentEditorId && currentEditorKey) {
        return {
            ...action,
            id: currentlyEditingElementId,
            editorId: currentEditorId,
            editorKey: currentEditorKey,
        };
    }

    // Otherwise just stop editing
    return finishEditingElement(currentlyEditingElementId);
};

const createUndoSelectionUpdateMeta = (state, action) => {
    const cellSelections = getCurrentCellSelections(state);
    return { ...action, cellSelections };
};

export const createUndoIncreaseElementCount = (state, action) => ({
    type: ELEMENT_COUNT_ACTION_TYPES.ELEMENT_COUNT_DECREASE,
    counts: action.counts,
});

export const createUndoDecreaseElementCount = (state, action) => ({
    type: ELEMENT_COUNT_ACTION_TYPES.ELEMENT_COUNT_INCREASE,
    counts: action.counts,
});

const getStoredCommentText = (state, { _id }) => {
    const comment = getComment(state, { _id });
    return comment?.text;
};

const getStoredCommentContent = (state, { _id }) => {
    const comment = getComment(state, { _id });
    return comment?.content;
};

const getCommentUpdateType = (state, { contentUpdate = {} }) => {
    const { updateType } = contentUpdate;

    switch (updateType) {
        case ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.REACTION:
            return ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.TOGGLE_REACTION;
        case ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.TOGGLE_REACTION:
            return ELEMENT_ACTION_TYPES.ELEMENT_UPDATE_TYPE.REACTION;
        default:
    }
};

const createUndoCommentAdd = (state, action) => ({
    ...pick(['_id', 'threadId', 'userId', 'sync'], action),
    type: CommentActionType.COMMENTS_DELETE,
});

const createUndoCommentUpdate = (state, action) => ({
    ...action,
    text: getStoredCommentText(state, action),
    contentUpdate: action.contentUpdate && {
        ...action.contentUpdate,
        updateType: getCommentUpdateType(state, action),
        changes: getStoredCommentContent(state, action),
    },
});

const createUndoCommentDelete = (state, action) => {
    const comment = getComment(state, { _id: action._id });
    return {
        ...action,
        type: CommentActionType.COMMENTS_ADD,
        threadId: comment?.threadId,
        text: getStoredCommentText(state, action),
        createdAt: comment?.createdAt,
        overrideCreatedAt: true,
    };
};

const createUndoStartOperation = (state, action) => ({
    ...action,
    type: END_OPERATION,
});

const createUndoEndOperation = (state, action) => ({
    ...action,
    type: START_OPERATION,
});

const createUndoClipboardSaveOperation = (state, action) => ({
    ...action,
    type: ELEMENT_CLIPBOARD_UNDO,
});

const createUndoConvertToCloneAction = (state, action) => {
    const originalElementHasClones = state.getIn(['elements', action.originalElementId, 'content', 'hasClones']);

    const clonedElementOriginalType = state.getIn(['elements', action.id, 'elementType']);
    const clonedElementOriginalContent = asObject(state.getIn(['elements', action.id, 'content']));

    return {
        type: ELEMENT_ACTION_TYPES.ELEMENT_UNDO_CONVERT_TO_CLONE,
        originalElementId: action.originalElementId,
        originalElementHasClones,
        clonedElementId: action.id,
        clonedElementOriginalType,
        clonedElementOriginalContent,
        sync: action.sync,
    };
};

/**
 * Cancels an in-progress attachment upload on undo.
 */
const createUndoElementAttachmentUpload = (state, action) => ({
    ...action,
    type: ELEMENT_ACTION_TYPES.ELEMENT_ATTACHMENT_CANCEL_UPLOAD,
});

/**
 * Resets local element data to its previous state.
 */
const createUndoSetElementLocalDataMulti = (state, action) => ({
    ...action,
    updates: action.updates.map(({ id }) => ({ id, data: getElementLocalData(state, { elementId: id }) })),
});

const buildReverseAction = (state, action) => {
    switch (action.type) {
        case BATCH_ACTION_TYPE:
            return {
                ...action,
                payload: action.payload.map((payloadAction) => buildReverseAction(state, payloadAction)),
            };
        case ELEMENT_ACTION_TYPES.ELEMENT_MOVE_AND_UPDATE:
            return createUndoMoveAndUpdateAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_MOVE_MULTI:
            return createUndoMultiMoveAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_UPDATE:
            return createUndoUpdateAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_CREATE:
            return createUndoCreateAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_DELETE:
            return createUndoDeleteAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_CONVERT_TO_CLONE:
            return createUndoConvertToCloneAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_SET_TYPE:
            return createUndoSetTypeAction(state, action);
        case LOCATION_CHANGE:
            return createUndoRouteChange(state, action);
        case SELECTION_ACTION_TYPES.ELEMENTS_SELECTED:
            return createUndoSelectAction(state, action);
        case SELECTION_ACTION_TYPES.ELEMENT_EDIT_START:
            return createUndoEditStartAction(state, action);
        case SELECTION_ACTION_TYPES.TABLE_ELEMENT_CELL_SELECTIONS_UPDATE:
            return createUndoSelectionUpdateMeta(state, action);
        case ELEMENT_COUNT_ACTION_TYPES.ELEMENT_COUNT_INCREASE:
            return createUndoIncreaseElementCount(state, action);
        case ELEMENT_COUNT_ACTION_TYPES.ELEMENT_COUNT_DECREASE:
            return createUndoDecreaseElementCount(state, action);
        case CommentActionType.COMMENTS_ADD:
            return createUndoCommentAdd(state, action);
        case CommentActionType.COMMENTS_UPDATE:
            return createUndoCommentUpdate(state, action);
        case CommentActionType.COMMENTS_DELETE:
            return createUndoCommentDelete(state, action);
        case START_OPERATION:
            return createUndoStartOperation(state, action);
        case END_OPERATION:
            return createUndoEndOperation(state, action);
        case CLIPPER_OPERATION_COMPLETE:
            return action;
        case ELEMENT_CLIPBOARD_SAVE:
            return createUndoClipboardSaveOperation(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_ATTACHMENT_ACCEPT_UNDO:
            return createUndoElementAttachmentUpload(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_SET_LOCAL_DATA_MULTI:
            return createUndoSetElementLocalDataMulti(state, action);
        default:
            throw Error(`UNDO: Unknown action type, ${action.type}`);
    }
};

export const addUndoActionProperties = (action) => ({
    ...action,
    isUndo: true,
});

export default (state, action) =>
    addUndoActionProperties({
        ...buildReverseAction(state, action),
        transactionId: action.transactionId,
    });
