// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getMaxHeight, getWidth } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

export default (DecoratedComponent) => {
    class CommentThreadSaveHeightResizeDecorator extends React.Component {
        onResizeComplete = (size, containerElement, contentElement) => {
            const { elementId, element, gridSize, setElementSize, dispatchUpdateElement } = this.props;

            const changes = {
                width: Math.max(Math.round(size.width), ELEMENT_DEFAULT_WIDTH),
            };

            const contentHeight = contentElement ? contentElement.clientHeight : 0;
            const containerHeight = containerElement ? containerElement.clientHeight : Infinity;

            const shouldSaveMaxHeight =
                contentHeight > containerHeight ||
                // Or already has maxHeight saved
                !!getMaxHeight(element);

            if (shouldSaveMaxHeight) {
                // If the container height isn't as big as the resize height, then use the container height instead
                changes.maxHeight =
                    contentHeight >= size.height * gridSize ? Math.round(containerHeight / gridSize) : 'none';
            }

            dispatchUpdateElement && dispatchUpdateElement({ id: elementId, changes });

            setElementSize && setElementSize(size);
        };

        handleDoubleClick = () => {
            const { elementId, element, dispatchUpdateElement } = this.props;

            const width = getWidth(element);
            const maxHeight = getMaxHeight(element);

            if ((!width || width === ELEMENT_DEFAULT_WIDTH) && !maxHeight) return;

            return dispatchUpdateElement({
                id: elementId,
                changes: {
                    width: null,
                    maxHeight: null,
                },
            });
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    handleDoubleClick={this.handleDoubleClick}
                    setElementSize={this.onResizeComplete}
                />
            );
        }
    }

    CommentThreadSaveHeightResizeDecorator.propTypes = {
        elementId: PropTypes.string,
        element: PropTypes.object,
        commentList: PropTypes.array,
        gridSize: PropTypes.number,
        setElementSize: PropTypes.func,
        dispatchUpdateElement: PropTypes.func,
    };

    return CommentThreadSaveHeightResizeDecorator;
};
