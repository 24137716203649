// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import MilanoteMentionsEditor from '../../../components/editor/plugins/mentions/MilanoteMentionsEditor';
import CommentPostButton from './CommentPostButton';

// Plugins
import { createLinkPlugin, createLinkifyPlugin } from '../../../components/editor/plugins/link';
import createMilanoteShortcutsPlugin from '../../../components/editor/plugins/milanoteShortcuts';
import createEditingHelperPlugin from '../../../components/editor/plugins/editingHelper';
import createSubmitOnEnterPlugin from '../../../components/editor/plugins/submitOnEnter';
import createDeleteOnEmptyPlugin from '../../../components/editor/plugins/deleteOnEmpty';
import createActivityPingPlugin from '../../../components/editor/plugins/activityPing';
import createSearchHighlightPlugin from '../../../components/editor/plugins/searchHighlight';
import createMarkdownPlugin from '../../../components/editor/plugins/markdownSupport';
import createHighlightPlugin from '../../../components/editor/plugins/textColor';
import createStyleShortcutsPlugin from '../../../components/editor/plugins/styleShortcuts';
import createTabReplacementPlugin from '../../../components/editor/plugins/tabReplacementPlugin';

// Styles
import './CommentEditor.scss';

const linkifyPlugin = createLinkifyPlugin({});
const linkPlugin = createLinkPlugin({});
const markdownPlugin = createMarkdownPlugin({ enableBlockStyles: false });
const milanoteShortcutsPlugin = createMilanoteShortcutsPlugin();
const styleShortcutsPlugin = createStyleShortcutsPlugin({ enableBlockStyles: false });
const editingHelperPlugin = createEditingHelperPlugin({ preventNewline: false });
const searchHighlightPlugin = createSearchHighlightPlugin();
const submitOnEnterPlugin = createSubmitOnEnterPlugin();
const deleteOnEmptyPlugin = createDeleteOnEmptyPlugin();
const activityPingPlugin = createActivityPingPlugin();
const highlightPlugin = createHighlightPlugin();
const tabReplacementPlugin = createTabReplacementPlugin();

const plugins = [
    searchHighlightPlugin,
    highlightPlugin,
    linkPlugin,
    linkifyPlugin,
    milanoteShortcutsPlugin,
    submitOnEnterPlugin,
    deleteOnEmptyPlugin,
    markdownPlugin,
    activityPingPlugin,
    styleShortcutsPlugin,
    // Ensure this is last as it uses a handleReturn helper to remove inline styles by default
    editingHelperPlugin,
    tabReplacementPlugin,
];

const CommentEditor = (props) => (
    <MilanoteMentionsEditor
        {...props}
        plugins={plugins}
        className={classNames('CommentEditor', props.className, { editing: props.isEditing })}
    >
        {(editorState) => {
            if (props.editorStateRef) {
                props.editorStateRef.current = editorState;
            }

            if (props.isPreview || props.hidePostButton) return null;

            return (
                <CommentPostButton
                    ref={props.postButtonRef}
                    className="post-button"
                    {...props}
                    editorState={editorState}
                />
            );
        }}
    </MilanoteMentionsEditor>
);

CommentEditor.propTypes = {
    className: PropTypes.string,
    postButtonRef: PropTypes.object,
    isPreview: PropTypes.bool,
    isEditing: PropTypes.bool,
    hidePostButton: PropTypes.bool,
    editorStateRef: PropTypes.object,
};

export default CommentEditor;
