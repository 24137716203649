// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getGivenName } from '../../../common/users/userHelper';
import { getIsCreatorActive } from './store/commentThreadActivitySelector';

// Components
import CommentLine from './CommentLine';

// Styles
import './CommentThreadStartIndicator.scss';

const mapStateToProps = createStructuredSelector({
    creatorUser: (state, ownProps) => state.getIn(['users', ownProps.creatorId]),
    isCreatorActive: getIsCreatorActive,
});

const CommentThreadStartIndicator = ({ creatorId, currentUserId, creatorUser, commentList, isCreatorActive }) => {
    if (creatorId === currentUserId || (commentList && commentList.length) || isCreatorActive) return null;

    return (
        <CommentLine className="CommentThreadStartIndicator" user={creatorUser}>
            <span className="user-name">{getGivenName(creatorUser) || 'Someone'}</span>
            <span> started this thread</span>
        </CommentLine>
    );
};

CommentThreadStartIndicator.propTypes = {
    creatorId: PropTypes.string.isRequired,
    currentUserId: PropTypes.string.isRequired,
    threadId: PropTypes.string.isRequired,
    creatorUser: PropTypes.object,
    show: PropTypes.bool,
    commentList: PropTypes.array,
    isCreatorActive: PropTypes.bool,
};

export default connect(mapStateToProps)(CommentThreadStartIndicator);
