// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getLinkifyMatchesFromText } from '../../../../../common/utils/getLinksFromText';

// Components
import DraftLinkAnchor from '../link/DraftLinkAnchor';
import DraftClickableLinkAnchor from '../link/DraftClickableLinkAnchor';

// Styles
import './HyperlinkAnchor.scss';

/**
 * This is similar to the draft-js-link-plugin however it's been updated to allow specific entity
 * data for Milanote to be passed through because the npm package was not permissive enough.
 */
const createHyperlinkAnchor = ({ clickableLinks }) => {
    const HyperlinkAnchor = (props) => {
        const { contentState, entityKey } = props;

        const entity = contentState.getEntity(entityKey).getData();

        let href = '';
        if (entity.url) {
            const links = getLinkifyMatchesFromText(entity.url);
            href = links && links[0] ? links[0].url : '';
        }

        const className = entity.highlight ? 'highlight' : '';

        const AnchorComponent = clickableLinks ? DraftClickableLinkAnchor : DraftLinkAnchor;

        return <AnchorComponent className={className} {...props} href={href} target="_blank" />;
    };

    HyperlinkAnchor.propTypes = {
        contentState: PropTypes.object.isRequired,
        entityKey: PropTypes.string.isRequired,
    };

    return HyperlinkAnchor;
};

export default createHyperlinkAnchor;
