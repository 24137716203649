// Lib
import { CellValueDetailedType, SimpleCellAddress } from 'hyperformula/es';
import Handsontable from 'handsontable/base';
import { prop } from 'lodash/fp';

// Utils
import hyperFormulaInstance from '../manager/hyperFormulaInstance';
import { isCellOfType } from '../../../../common/table/utils/tableCellDataPropertyUtils';

// Types
import { CellTypeObject } from '../../../../common/table/TableTypes';

// Constants
import {
    CellTypeNames,
    CellTypeObjectNames,
    DateStringFormatOptions,
    FormatOptions,
    TimeStringFormatOptions,
} from '../../../../common/table/CellTypeConstants';
import { getDefaultTypeObject } from '../../../../common/table/utils/tableCellTypeUtils';

const FORMULAS_INFER_FIRST_ARG_CELL_TYPE = [
    'SUM',
    'SUMIF',
    'SUMIFS',
    'SUMSQ',
    'SUMX2MY2',
    'SUMX2PY2',
    'SUMXMY2',
    'SUMPRODUCT',
    'MIN',
    'MINA',
    'MINIFS',
    'MAX',
    'MAXA',
    'MAXIFS',
    'AVERAGE',
    'AVERAGEA',
    'AVERAGEIF',
];

const cellFormulaRegex = /=([A-Za-z0-9]*)\(.*\)/;

export const isFormula = (text: string | null): boolean => !!text && text.toString().startsWith('=');

/**
 * Infer cell type of formula cells
 * - By default, infer based on the hfValueDetailedType of the cell
 * - For some formulas (FORMULAS_INFER_FIRST_ARG_CELL_TYPE), HyperFormula does not automatically infer the cell type,
 *   so manually infer the cell type based on the first precedent cell
 */
export const inferFormulaCellType = (
    cellAddress: SimpleCellAddress,
    hotInstance: Handsontable,
): CellTypeObject | undefined => {
    const cellFormula = hyperFormulaInstance.getCellFormula(cellAddress);
    if (!cellFormula) return;

    const getCellTypeFromCellMeta = (cellType: CellTypeObjectNames) => {
        const { cellData } = hotInstance.getCellMeta(cellAddress.row, cellAddress.col) || {};
        return (isCellOfType(cellType)(cellData) && cellData?.type) || getDefaultTypeObject(cellType);
    };

    const hfValueDetailedType = hyperFormulaInstance.getCellValueDetailedType(cellAddress);
    switch (hfValueDetailedType) {
        case CellValueDetailedType.NUMBER_DATE:
            return {
                ...getCellTypeFromCellMeta(CellTypeNames.DATE_TIME),
                [FormatOptions.HAS_TIME]: false,
            };
        case CellValueDetailedType.NUMBER_DATETIME:
            return {
                ...getCellTypeFromCellMeta(CellTypeNames.DATE_TIME),
                [FormatOptions.HAS_TIME]: true,
                [FormatOptions.TIME_FORMAT]: TimeStringFormatOptions.TWELVE_HOUR,
            };
        case CellValueDetailedType.NUMBER_TIME:
            return {
                ...getCellTypeFromCellMeta(CellTypeNames.DATE_TIME),
                [FormatOptions.HAS_TIME]: true,
                [FormatOptions.TIME_FORMAT]: TimeStringFormatOptions.TWELVE_HOUR_SECONDS,
                [FormatOptions.TIME_IN_BRACKETS]: false,

                [FormatOptions.DATE_FORMAT]: DateStringFormatOptions.NONE,
            };
        case CellValueDetailedType.NUMBER_PERCENT:
            return getCellTypeFromCellMeta(CellTypeNames.PERCENTAGE);
        case CellValueDetailedType.NUMBER_CURRENCY:
            return getCellTypeFromCellMeta(CellTypeNames.CURRENCY);
        default: {
            // If formula in FORMULAS_INFER_FIRST_ARG_CELL_TYPE, infer cell type based on first precedent cell

            const cellFormulaFnName = prop(1, cellFormula?.match(cellFormulaRegex));
            const shouldInferBasedOnFirstPrecedentCell =
                cellFormulaFnName && FORMULAS_INFER_FIRST_ARG_CELL_TYPE.includes(cellFormulaFnName);

            if (shouldInferBasedOnFirstPrecedentCell) {
                let firstPrecedentCellAddress = prop(0, hyperFormulaInstance.getCellPrecedents(cellAddress));

                if (firstPrecedentCellAddress && 'start' in firstPrecedentCellAddress) {
                    firstPrecedentCellAddress = firstPrecedentCellAddress.start;
                }

                return firstPrecedentCellAddress && inferFormulaCellType(firstPrecedentCellAddress, hotInstance);
            }
            return;
        }
    }
};
