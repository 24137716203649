import { length, prop } from '../../../../common/utils/immutableHelper';
import { CellCoordsObj, CellData, CellSelection, CellSelections } from '../../../../common/table/TableTypes';
import CellRange from 'handsontable/3rdparty/walkontable/src/cell/range';
import Handsontable from 'handsontable/base';
import Core from 'handsontable/core';
import { getColumnCount, getRowCount } from '../../../../common/table/utils/tableCellDataPropertyUtils';

/**************************
 * CELL SELECTION GETTERS
 **************************/

export const getStartRow = prop(0);
export const getStartCol = prop(1);
export const getEndRow = prop(2);
export const getEndCol = prop(3);

export const getStartSelectionCoords = (cellSelection: CellSelection): CellCoordsObj => ({
    row: getStartRow(cellSelection),
    col: getStartCol(cellSelection),
});

export const getEndSelectionCoords = (cellSelection: CellSelection): CellCoordsObj => ({
    row: getEndRow(cellSelection),
    col: getEndCol(cellSelection),
});

export const getMinCellSelection = (cellSelection: CellSelection): CellCoordsObj => ({
    row: Math.min(getStartRow(cellSelection), getEndRow(cellSelection)),
    col: Math.min(getStartCol(cellSelection), getEndCol(cellSelection)),
});

export const getMaxCellSelection = (cellSelection: CellSelection): CellCoordsObj => ({
    row: Math.max(getStartRow(cellSelection), getEndRow(cellSelection)),
    col: Math.max(getStartCol(cellSelection), getEndCol(cellSelection)),
});

export const getCellSelectionInLayer = (cellSelections: CellSelections, layer: number): CellSelection | undefined =>
    prop(layer, cellSelections);

export const getAllCellsBetween = (cellSelections: CellSelections = []): Array<CellCoordsObj> => {
    const allCells: CellCoordsObj[] = [];

    cellSelections?.forEach((cellSelection: CellSelection) => {
        const { row: minRow, col: minCol } = getMinCellSelection(cellSelection);
        const { row: maxRow, col: maxCol } = getMaxCellSelection(cellSelection);

        // take max of min and 0 to remove any -1 values
        for (let row = Math.max(minRow, 0); row <= maxRow; row++) {
            for (let col = Math.max(minCol, 0); col <= maxCol; col++) {
                allCells.push({ row, col });
            }
        }
    });

    return allCells;
};

export const getAllSelectedCols = (cellSelections: CellSelections = []): number[] => {
    const allCols: number[] = [];

    cellSelections?.forEach((cellSelection: CellSelection) => {
        const { col: minCol } = getMinCellSelection(cellSelection);
        const { col: maxCol } = getMaxCellSelection(cellSelection);

        // take max of min and 0 to remove any -1 values
        for (let col = Math.max(minCol, 0); col <= maxCol; col++) {
            allCols.push(col);
        }
    });

    return allCols;
};

export const getNRowsInSelection = (cellSelection: CellSelection): number => {
    return Math.abs(getEndRow(cellSelection) - getStartRow(cellSelection)) + 1;
};
export const getNColsInSelection = (cellSelection: CellSelection): number => {
    return Math.abs(getEndCol(cellSelection) - getStartCol(cellSelection)) + 1;
};

export const excludeRefHeadersFromSelection = (cellSelection: CellSelection): CellSelection =>
    cellSelection.map((index: number) => Math.max(index, 0)) as CellSelection;

/**
 * Get the highest row and column indices from one or more selections
 * e.g. [[1, 3, 1, 3], [4, 2, 5, 2]] => { row: 5, col: 3 }
 */
export const getMaxFromSelections = (cellSelections: CellSelections | undefined): CellCoordsObj | undefined => {
    if (!cellSelections || cellSelections.length === 0) return;

    const rows: number[] = [];
    const cols: number[] = [];

    cellSelections.forEach((cellSelection: CellSelection) => {
        const max = getMaxCellSelection(cellSelection);
        rows.push(max.row);
        cols.push(max.col);
    });

    return { row: Math.max(...rows), col: Math.max(...cols) };
};

export const getRowRefHeaderCellSelection = (
    fromRow: number,
    toRow: number,
    options: { nCols: number; nRows: number },
): CellSelection => {
    return [fromRow, -1, toRow, options.nCols];
};

export const getColRefHeaderCellSelection = (
    fromCol: number,
    toCol: number,
    options: { nCols: number; nRows: number },
): CellSelection => {
    return [-1, fromCol, options.nRows, toCol];
};

/**************************
 * CHECK CELL SELECTION
 **************************/

export const isSelectingRefColHeader = (selection: CellSelection): boolean => getStartRow(selection) === -1;
export const isSelectingRefRowHeader = (selection: CellSelection): boolean => getStartCol(selection) === -1;

export const isSelectingFirstRow = (selection: CellSelection): boolean =>
    getStartRow(selection) === 0 || getEndRow(selection) === 0;

export const isSelectingLastRow = (selection: CellSelection, nRows: number): boolean =>
    getStartRow(selection) === nRows - 1 || getEndRow(selection) === nRows - 1;

export const isSelectingSingleCell = (selection: CellSelection): boolean =>
    getStartRow(selection) === getEndRow(selection) && getStartCol(selection) === getEndCol(selection);

export const isSelectionSubsetOf = (selection: CellSelection, largeSelection: CellSelection): boolean =>
    getStartRow(largeSelection) <= getStartRow(selection) &&
    getStartCol(largeSelection) <= getStartCol(selection) &&
    getEndRow(largeSelection) >= getEndRow(selection) &&
    getEndCol(largeSelection) >= getEndCol(selection);

export const isRowInSelection = (row: number, cellSelection: CellSelection): boolean =>
    (row >= getStartRow(cellSelection) && row <= getEndRow(cellSelection)) ||
    (row >= getEndRow(cellSelection) && row <= getStartRow(cellSelection));
export const isColInSelection = (col: number, cellSelection: CellSelection): boolean =>
    (col >= getStartCol(cellSelection) && col <= getEndCol(cellSelection)) ||
    (col >= getEndCol(cellSelection) && col <= getStartCol(cellSelection));

export const isRowInSelections = (row: number, cellSelections: CellSelections): boolean =>
    cellSelections.some((cellSelection: CellSelection) => isRowInSelection(row, cellSelection));

export const isColInSelections = (col: number, cellSelections: CellSelections): boolean =>
    cellSelections.some((cellSelection: CellSelection) => isColInSelection(col, cellSelection));

export const isCellRefColHeader = ({ row }: CellCoordsObj): boolean => row === -1;
export const isCellRefRowHeader = ({ col }: CellCoordsObj): boolean => col === -1;

/**************************
 * ALTER CELL SELECTION
 **************************/

export const asCellSelection = (cellRange: CellRange | null): CellSelection | null => {
    if (!cellRange) return null;

    const startRow = cellRange.from.row;
    const startCol = cellRange.from.col;
    const endRow = cellRange.to.row;
    const endCol = cellRange.to.col;

    return [startRow, startCol, endRow, endCol];
};

export const moveCellsRight = (selection: CellSelection): CellSelection => {
    const numberOfCols = Math.abs(getStartCol(selection) - getEndCol(selection)) + 1;

    // just move to a single new column at the end of the table, retain row selection
    if (isSelectingRefRowHeader(selection))
        return [getStartRow(selection), getEndCol(selection) + 1, getEndRow(selection), getEndCol(selection) + 1];

    return [
        getStartRow(selection),
        getStartCol(selection) + numberOfCols,
        getEndRow(selection),
        getEndCol(selection) + numberOfCols,
    ];
};

export const moveCellsLeft = (selection: CellSelection): CellSelection => {
    const numberOfCols = Math.abs(getStartCol(selection) - getEndCol(selection)) - 1;
    return [
        getStartRow(selection),
        getStartCol(selection) + numberOfCols,
        getEndRow(selection),
        getEndCol(selection) + numberOfCols,
    ];
};

export const moveCellsDown = (selection: CellSelection): CellSelection => {
    const numberOfRows = Math.abs(getStartRow(selection) - getEndRow(selection)) + 1;

    // just move to a single new row at the bottom of the table, retain col selection
    if (isSelectingRefColHeader(selection))
        return [getEndRow(selection) + 1, getStartCol(selection), getEndRow(selection) + 1, getEndCol(selection)];

    return [
        getStartRow(selection) + numberOfRows,
        getStartCol(selection),
        getEndRow(selection) + numberOfRows,
        getEndCol(selection),
    ];
};

export const moveCellsUp = (selection: CellSelection): CellSelection => {
    const numberOfRows = Math.abs(getStartRow(selection) - getEndRow(selection)) - 1;
    return [
        getStartRow(selection) + numberOfRows,
        getStartCol(selection),
        getEndRow(selection) + numberOfRows,
        getEndCol(selection),
    ];
};

/**
 * Reorder cell selection such that the startRow and startCol is always lower than endRow and endCol respectively
 */
export const reorderCellSelection = (selection: CellSelection): CellSelection => [
    Math.min(getStartRow(selection), getEndRow(selection)),
    Math.min(getStartCol(selection), getEndCol(selection)),
    Math.max(getStartRow(selection), getEndRow(selection)),
    Math.max(getStartCol(selection), getEndCol(selection)),
];

/**************************
 * CELL SELECTIONS GETTERS
 **************************/

export const getFirstSelection = (cellSelections: CellSelections | undefined): CellSelection | undefined =>
    prop(0, cellSelections);
export const getFirstSelectionStartCellCoords = (cellSelections: CellSelections): CellCoordsObj => {
    const firstCellSelection = getFirstSelection(cellSelections);

    return {
        row: getStartRow(firstCellSelection),
        col: getStartCol(firstCellSelection),
    };
};

/**************************
 * CHECK CELL SELECTIONS
 **************************/

export const hasSelections = (cellSelections: CellSelections): boolean => cellSelections && length(cellSelections) > 0;
export const isSelectingSingleSelection = (cellSelections: CellSelections): boolean => length(cellSelections) === 1;
export const isSelectingSingleSelectionAndCell = (cellSelections: CellSelections): boolean => {
    if (length(cellSelections) !== 1) return false;

    const firstSelection = getFirstSelection(cellSelections);
    return !!firstSelection && isSelectingSingleCell(firstSelection);
};
export const isSelectingMultipleSelections = (cellSelections: CellSelections | undefined): boolean =>
    !!cellSelections && length(cellSelections) > 1;

/**************************
 * OTHERS
 **************************/

export const unselectedColCount = (data: Array<Array<CellData>>, selection: CellSelection): number => {
    if (isSelectingRefRowHeader(selection)) return 0;

    const numberOfCols = getColumnCount(data);
    const numberOfSelectedCols = Math.abs(getStartCol(selection) - getEndCol(selection)) + 1;
    return numberOfCols - numberOfSelectedCols;
};

export const unselectedRowCount = (data: Array<Array<CellData>>, selection: CellSelection): number => {
    if (isSelectingRefColHeader(selection)) return 0;

    const numberOfRows = getRowCount(data);
    const numberOfSelectedRows = Math.abs(getStartRow(selection) - getEndRow(selection)) + 1;
    return numberOfRows - numberOfSelectedRows;
};

/**
 * If a cellSelection is a range of cells, and we want to deselect a subset of it, we would need to split the
 * cellSelection into multiple cellSelections, in order to exclude the deselected cells.
 *
 * This function returns a list of cell selections that will still be selected if we deselect `deselectCellSelection`
 * from `cellSelection`.
 */
export const deselectCellSelectionSubset = (
    cellSelection: CellSelection,
    deselectCellSelection: CellSelection,
): CellSelections => {
    const newCellSelections: Array<CellSelection> = [];
    if (getStartRow(deselectCellSelection) > getStartRow(cellSelection)) {
        newCellSelections.push([
            getStartRow(cellSelection),
            getStartCol(cellSelection),
            getStartRow(deselectCellSelection) - 1,
            getEndCol(cellSelection),
        ]);
    }

    if (getStartCol(deselectCellSelection) > getStartCol(cellSelection)) {
        newCellSelections.push([
            getStartRow(deselectCellSelection),
            getStartCol(cellSelection),
            getEndRow(deselectCellSelection),
            getStartCol(deselectCellSelection) - 1,
        ]);
    }

    if (getEndCol(deselectCellSelection) < getEndCol(cellSelection)) {
        newCellSelections.push([
            getStartRow(deselectCellSelection),
            getEndCol(deselectCellSelection) + 1,
            getEndRow(deselectCellSelection),
            getEndCol(cellSelection),
        ]);
    }

    if (getEndRow(deselectCellSelection) < getEndRow(cellSelection)) {
        newCellSelections.push([
            getEndRow(deselectCellSelection) + 1,
            getStartCol(cellSelection),
            getEndRow(cellSelection),
            getEndCol(cellSelection),
        ]);
    }

    return newCellSelections;
};

/**
 * Hide/show "current" borders based on whether only single cell is selection.
 * This is to replicate the behaviour of Numbers app, where "current" borders
 * will not be shown when there are a cell range selected.
 */
export const fixSelectionBorderDisplay = (hot: Handsontable): void => {
    // If there is no selection, and there are no cell refs, return
    const cellSelections = hot.getSelected();
    if (!cellSelections) return;

    // If there is more than one cell selected, don't display the current selection border
    // This is because we only want to show one border around each selection, but when one
    // cell is selected, it ONLY uses the current selection border, not the area selection border
    const singleCellSelected = cellSelections && isSelectingSingleSelectionAndCell(cellSelections);
    const currentSelectionBorderElements = hot.rootElement.querySelectorAll('.ht_master .htBorders .wtBorder.current');
    const currentIsHidden = currentSelectionBorderElements[0]?.classList.contains('hidden');

    // Only run if there is a single selection and its currently hidden, or there isn't a single selection and its currently visible
    if ((!singleCellSelected && !currentIsHidden) || (singleCellSelected && currentIsHidden)) {
        currentSelectionBorderElements.forEach((el) => {
            el.classList.toggle('hidden', !singleCellSelected);
        });
    }
};

export const repositionAutofillHandle = (
    hot: Core | null,
    hotTableContainerRef: React.MutableRefObject<HTMLDivElement | null>,
) => {
    // Autofill handle is only shown when there is a single selection
    // Return here if there is no selection or there are multiple selections
    const currentSelections = hot?.getSelected();
    if (!currentSelections || !isSelectingSingleSelection(currentSelections)) return;

    const currentSelection = getFirstSelection(currentSelections);
    if (!currentSelection) return;

    requestAnimationFrame(() => {
        if (!hot || !hotTableContainerRef.current) return;

        const finalRowSelected = isRowInSelection(hot.countRows() - 1, currentSelection);
        const finalColSelected = isColInSelection(hot.countCols() - 1, currentSelection);
        const shouldMoveAutofillHandle = finalRowSelected && finalColSelected;

        if (!shouldMoveAutofillHandle) {
            hotTableContainerRef.current.style.setProperty('--autofill-handle-position-offset-x', '0px');
            hotTableContainerRef.current.style.setProperty('--autofill-handle-position-offset-y', '0px');
            return;
        }

        const singleCellSelected = isSelectingSingleSelectionAndCell(currentSelections);
        const selector = `.ht_master .wtBorder.${singleCellSelected ? 'current' : 'area'}:not(.hidden)`;
        const borders = hot.rootElement.querySelectorAll(selector) as NodeListOf<HTMLElement>;
        const [topBorder, leftBorder] = borders;

        if (!topBorder || !leftBorder) return;

        // Calculate the offset to put the handle exactly in the middle of the borders
        // or if its touching one of the edges it should be further in, so we can see the entire handle
        const handleWidthOffsetX = isColInSelection(0, currentSelection) ? 5 : 3;
        const handleWidthOffsetY = isRowInSelection(0, currentSelection) ? 5 : 3;

        const distanceX = topBorder.offsetWidth - handleWidthOffsetX;
        const distanceY = leftBorder.offsetHeight - handleWidthOffsetY;

        hotTableContainerRef.current.style.setProperty('--autofill-handle-position-offset-x', `-${distanceX}px`);
        hotTableContainerRef.current.style.setProperty('--autofill-handle-position-offset-y', `-${distanceY}px`);
    });
};
