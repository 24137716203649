// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import PageLoaderMain from './PageLoaderMain';

// Selectors
import { getIsLocalCacheHydrated } from '../../offline/cache/localCacheSelector';
import { getLegacyHybridUseCaseSelector, getPlatformDetailsSelector } from '../../platform/platformSelector';

// Utils
import classNames from 'classnames';
import { isPlatformLegacyMobileApp, isPlatformPhoneOrMobileMode } from '../../platform/utils/platformDetailsUtils';

// Types
import { LegacyHybridUseCase } from '../../platform/platformTypes';

// Styles
import './PageLoader.scss';

const isAppHeaderVisible = (legacyHybridUseCase: LegacyHybridUseCase | null) => {
    switch (legacyHybridUseCase) {
        case LegacyHybridUseCase.IOS_BOARD_LIST:
        case LegacyHybridUseCase.IOS_CANVAS:
            return false;
        case LegacyHybridUseCase.IPAD_OS:
            return true;
        default:
            return true;
    }
};

const PageLoader = () => {
    const isLocalCacheHydrated = useSelector(getIsLocalCacheHydrated);
    const legacyHybridUseCase = useSelector(getLegacyHybridUseCaseSelector);
    const platformDetails = useSelector(getPlatformDetailsSelector);

    // We only want to show the mobile toolbar in the new mobile app/web
    const showMobileToolbar =
        isPlatformPhoneOrMobileMode(platformDetails) && !isPlatformLegacyMobileApp(platformDetails);

    return (
        <div className="PageLoader">
            <div className="WorkspaceWrapper">
                {isAppHeaderVisible(legacyHybridUseCase) && (
                    <header className="AppHeader">
                        <div className="WorkspaceToolsHeader header-tools" />
                    </header>
                )}
                <div className="Workspace">
                    <div className={classNames('workspace-positioner', { 'show-mobile-toolbar': showMobileToolbar })}>
                        <div id="toolbar" className="Toolbar" />
                        {!isLocalCacheHydrated && <PageLoaderMain />}
                    </div>
                </div>
                {showMobileToolbar && <div className="modern-mobile-toolbar-placeholder" />}
            </div>
        </div>
    );
};

export default PageLoader;
