// Lib
import React, { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from '../../../../../node_module_clones/react-router';
import dateformat from 'dateformat';

// Components
import AccountPlanSection from './summary/AccountPlanSection';

// Selectors
import { getCurrentUserSubscription } from '../../../currentUserSelector';

// Utils
import { isStripeSubscription } from '../../../../../common/payment/subscriptions/subscriptionUtils';
import {
    getSubscriptionPlanCancelAt,
    getSubscriptionPlanCancelAtPeriodEnd,
    getSubscriptionPlanName,
} from '../../../../../common/payment/subscriptionPropertyUtil';

import './AccountPlanContainer.scss';

const mapStateToProps = createStructuredSelector({
    subscription: getCurrentUserSubscription,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
    children: React.ReactElement;
    showSubscriptionDetails?: boolean;
    route?: { name: string };
    location: { pathname: string };
};

const AccountPlanContainer = (props: Props): ReactElement => {
    const { children, subscription, showSubscriptionDetails = true, route } = props;

    if (
        subscription &&
        !isStripeSubscription(subscription) &&
        location.pathname !== '/account/plan/immediate-downgrade'
    ) {
        const cancelAtPeriodEnd = getSubscriptionPlanCancelAtPeriodEnd(subscription);
        const cancelAt = getSubscriptionPlanCancelAt(subscription);
        const cancelAtFormattedDate = dateformat(cancelAt * 1000, "'the' dS 'of' mmmm, yyyy");

        return (
            <div className="AccountPlanContainer">
                {route?.name && <h1>{route.name}</h1>}

                {showSubscriptionDetails && !cancelAtPeriodEnd && (
                    <AccountPlanSection
                        title={`You're on the ${getSubscriptionPlanName(subscription)} plan`}
                        action={<></>}
                    />
                )}

                {showSubscriptionDetails && cancelAtPeriodEnd && (
                    <AccountPlanSection
                        title={`You recently cancelled your ${getSubscriptionPlanName(subscription)} plan`}
                        details={`Your subscription will end on ${cancelAtFormattedDate}`}
                        action={
                            <Link className="action LinkButton" to="/account/plan/immediate-downgrade">
                                End subscription now
                            </Link>
                        }
                    />
                )}

                <h3>About your subscription</h3>

                <p className="account-subscription-warning">
                    Your Milanote subscription was created through an Apple account. You can manage your subscription
                    under Settings &gt; Apple ID &gt; Subscriptions on your iPhone or iPad. Learn more about it here:{' '}
                    <a
                        href="https://help.milanote.com/en/articles/9147963-how-do-i-update-my-milanote-subscription-on-iphone-or-ipad"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        How do I update my Milanote subscription on iPhone or iPad?
                    </a>
                </p>
            </div>
        );
    }

    return children;
};

export default connector(AccountPlanContainer);
