// Utils
import { findLastIndex, get } from 'lodash/fp';
import platformSingleton from '../../platform/platformSingleton';
import { prop } from '../../../common/utils/immutableHelper';
import { getIsMilanoteDomain, isAlternateWebHost, isSvgImageURL } from '../../../common/utils/urlUtil';

// Constants
import { IMAGE_TYPE_CONFIGS } from '../../../common/media/mediaConstants';
import {
    APP_QUERY_PARAMS,
    MEDIA_CDN_HOSTNAME,
    APP_MEDIA_HOSTNAME,
    APP_MEDIA_URL,
} from '../../../common/utils/urlConstants';
import { manuallyReportError } from '../../analytics/rollbarService';
import { ROLLBAR_LEVELS } from '../../analytics/rollbarConstants';

const getResponsiveImageIndexForDimension = (dimension) => (containerDimensionSizePx, sizesConfig, imageDetails) => {
    if (!containerDimensionSizePx || !imageDetails) return 0;

    const targetSize = platformSingleton.features.isRetina ? containerDimensionSizePx * 2 : containerDimensionSizePx;

    const largestSize = findLastIndex((size) => !!prop(size.name, imageDetails), sizesConfig);

    const oppositeDimension = dimension === 'width' ? 'height' : 'width';
    const maxImageSizeCurrentDimension = prop(dimension, imageDetails);
    const maxImageSizeOppositeDimension = prop(oppositeDimension, imageDetails);

    return sizesConfig.reduceRight((bestIndex, size, currentIndex) => {
        if (!imageDetails || !prop(size.name, imageDetails)) return bestIndex;

        let maxSize = size[dimension];

        // If the config doesn't specify a max size in this dimension, determine it from the opposite dimension
        // by comparing the ratio
        if (!maxSize) {
            const maxSizeOppositeDimension = size[oppositeDimension];

            // Can't do anything here as we don't have a max in either dimension
            if (!maxSizeOppositeDimension) return currentIndex;

            maxSize = maxSizeOppositeDimension * (maxImageSizeCurrentDimension / maxImageSizeOppositeDimension);
        }

        // If size isn't defined then treat it as 'unrestricted'
        if (!maxSize || isNaN(maxSize)) return currentIndex;

        // If the size is larger or equal to the target size, then this is the new best index
        if (maxSize >= targetSize) {
            return currentIndex;
        }

        return bestIndex;
    }, largestSize);
};

const getResponsiveImageIndexForHeight = getResponsiveImageIndexForDimension('height');
const getResponsiveImageIndexForWidth = getResponsiveImageIndexForDimension('width');

export const getResponsiveImageSize = ({ widthPx, heightPx, imageDetails, imageType }) => {
    const sizesConfig = IMAGE_TYPE_CONFIGS[imageType].sizes;
    const responsiveHeightIndex = getResponsiveImageIndexForHeight(heightPx, sizesConfig, imageDetails);
    const responsiveWidthIndex = getResponsiveImageIndexForWidth(widthPx, sizesConfig, imageDetails);
    const bestImageSizeIndex = Math.max(responsiveWidthIndex, responsiveHeightIndex);

    return get([bestImageSizeIndex, 'name'], sizesConfig);
};

/**
 * Return image source in this format:
 *   /api/media/[fileKey]?elementId=[elementId]&userId=[userId]
 */
const getImageSourceThroughProxy = ({ url, elementId }) => {
    const urlPath = url.pathname;

    // To get file key, we would need to decode the urlPath first to prevent double encoding
    const fileKey = decodeURIComponent(urlPath.slice(1));

    url.pathname = `/api/media/${encodeURIComponent(fileKey)}`;

    if (elementId) {
        url.searchParams.set('elementId', elementId);
    }

    return url.pathname + url.search;
};

/**
 * Rewrite origin if not as expected.
 * Assumes it is a Milanote domain
 * Could occur for staging environments to optimise perm checks
 *
 * @param {URL} imageURL
 * @return {URL} updated image URL
 */
export const rewriteImageDomainForCF = (imageURL) => {
    const { hostname } = imageURL;

    // Check if not the media domain or app media domain which can occur when uploaded from a staging env into production
    if (hostname === APP_MEDIA_HOSTNAME || hostname === MEDIA_CDN_HOSTNAME) return imageURL;

    // Update the origin (protocol + host + port) to current environment's App Media hostname
    const appMediaURL = new URL(APP_MEDIA_URL);
    imageURL.host = appMediaURL.host;
    imageURL.protocol = appMediaURL.protocol;
    imageURL.port = appMediaURL.port;

    return imageURL;
};

/**
 * Return image source in this format:
 *   https://app.milanote.com/media/[fileKey]?v=2&elementId=[elementId]
 */
const getImageSourceThroughCF = ({ url, imageSource, elementId }) => {
    // Convert domain
    rewriteImageDomainForCF(url);

    // In Lambda@Edge, the param `v=2` will do a permission check first before getting the image.
    url.searchParams.set(APP_QUERY_PARAMS.VERSION, '2');

    if (elementId) {
        url.searchParams.set(APP_QUERY_PARAMS.ELEMENT_ID, elementId);
    } else {
        // FIXME: Determine why this is empty sometimes and not set
        // This will likely fail permission checks.  Hence, logging it to try and track down the cause.
        manuallyReportError({
            custom: { imageSource },
            error: new Error('No elementId provided for image source through CF'),
            level: ROLLBAR_LEVELS.WARNING,
        });
    }

    return url.toString();
};

export const getImageSource = ({
    imageDetails,
    imageSize,
    elementId,
    useSecureMediaUrl,
    renderSecureImagesThroughProxy,
    renderSecureImagesThroughCF,
    optimizeForWebRender = true,
    isPreview = false,
}) => {
    const imageSource = prop(imageSize, imageDetails);
    const isTransparent = prop('transparent', imageDetails);

    if (!imageSource) return imageSource;

    // If image not a Milanote domain or alternate web host (e.g. in development localhost vs ngrok), return imageSource as is
    if (!(getIsMilanoteDomain(imageSource) || isAlternateWebHost(imageSource))) return imageSource;

    try {
        const url = new URL(imageSource);

        // If image is used to render in web, ensure width is set (default to `original`).
        // This will ensure the image rendered has been edited by Sharp, and is in the
        // sRGB color space, which is optimised for web.
        if (optimizeForWebRender) {
            url.searchParams.set(APP_QUERY_PARAMS.WIDTH, url.searchParams.get(APP_QUERY_PARAMS.WIDTH) || 'display');
        }

        if (isPreview && !isTransparent) {
            url.searchParams.set(APP_QUERY_PARAMS.FORMAT, 'jpg');
        }

        // If image is SVG, do not request for an edited image, so that the SVG is rendered as is.
        if (isSvgImageURL(imageSource)) {
            url.searchParams.delete(APP_QUERY_PARAMS.WIDTH);
            url.searchParams.delete(APP_QUERY_PARAMS.FORMAT);
        }

        if (useSecureMediaUrl && renderSecureImagesThroughProxy) {
            return getImageSourceThroughProxy({ url, elementId });
        }

        if (useSecureMediaUrl && renderSecureImagesThroughCF) {
            return getImageSourceThroughCF({ url, imageSource, elementId });
        }

        return url.toString();
    } catch (e) {
        manuallyReportError({
            errorMessage: 'Failed to retrieve image source',
            error: e,
            custom: { imageSource, elementId },
        });
        return null;
    }
};
