// Lib
import { isEmpty, get } from 'lodash/fp';
import { LOCATION_CHANGE } from 'react-router-redux';

// Utils
import { isElementId } from '../../../../common/uid/idValidator';
import { getFirstSubPathFromPathname } from '../../../../common/utils/urlUtil';

// Selectors
import { getLocation, getLocationPathname } from '../../../app/routingSelector';

// Constants
import * as ELEMENT_ACTION_TYPES from '../../../../common/elements/elementConstants';
import * as SELECTION_ACTION_TYPES from '../../../../common/elements/selectionConstants';
import * as ELEMENT_COUNT_ACTION_TYPES from '../../../user/elementCount/elementCountConstants';
import { CommentActionType } from '../../../../common/comments/commentConstants';
import { END_OPERATION, START_OPERATION } from '../undoRedoConstants';
import { CLIPPER_OPERATION_COMPLETE } from '../../../element/card/clipper/store/clipperConstants';
import { BATCH_ACTION_TYPE } from '../../../store/reduxBulkingMiddleware';
import { CUT, ELEMENT_CLIPBOARD_SAVE } from '../../../workspace/shortcuts/clipboard/clipboardConstants';

const isPathForBoard = (path) => {
    const subPath = getFirstSubPathFromPathname(path);

    if (!subPath) return false;

    return isElementId(subPath);
};

const actionHasUndoProperties = (action) => {
    // don't add other people's actions
    if (action.remote) return false;

    // don't add existing undo actions to the stack
    if (action.isUndo) return false;
    if (action.isRedo) return false;

    // actions with a negative transactionId should not be added to the stack
    if (action.transactionId < 0) return false;

    // add regular location change actions to the stack
    if (action.type === LOCATION_CHANGE) {
        const pathname = get(['payload', 'pathname'], action);
        // If navigating to a board then we can undo
        // NOTE: canUndoRouteChange will prevent undos if we're leaving a non-board URL
        return isPathForBoard(pathname);
    }

    // add things with a transaction id will be added to the stack
    if (action.transactionId) return true;

    // default to not adding actions to stack
    return false;
};

const stateHasElement = (state, elementId) => state.hasIn(['elements', elementId]);
const stateHasSomeElements = (state, elementIds) =>
    elementIds.some((elementId) => state.hasIn(['elements', elementId]));
const canUndoMultiMoveAction = (state, action) =>
    stateHasSomeElements(
        state,
        action.moves.map((move) => move.id),
    );
const canUndoMoveAction = (state, action) => stateHasElement(state, action.id);
const canUndoUpdateAction = (state, action) =>
    stateHasSomeElements(
        state,
        action.updates.map((update) => update.id),
    );

// Usually this is just for setting internal state, so any multi-part action that updates
// local data with an explicit transactionId should be undone, but one-off calls should
// be left alone.
const canUndoElementSetLocalDataMulti = (state, action) => Boolean(action.transactionId);

// We only want to undo a route change if we're navigating from a board URL
// we also want to prevent first location change from creating an empty undo action
const canUndoRouteChange = (state, action) => {
    const pathname = getLocationPathname(state);

    // If we're not navigating from a board URL, then don't undo this navigation
    if (!isPathForBoard(pathname)) return false;

    return (
        !isEmpty(getLocation(state)) &&
        action.payload &&
        action.payload.action !== 'REPLACE' &&
        action.payload.action !== 'POP'
    );
};

const canUndoClipboardSave = (state, action) => action?.operation === CUT;

const canUndoElementEditStart = (state, action) => action.canUndo && !!action.transactionId;

const actionCanUndo = (state, action) => {
    switch (action.type) {
        case BATCH_ACTION_TYPE:
            return action.payload.some((payloadAction) => actionCanUndo(state, payloadAction));
        case ELEMENT_ACTION_TYPES.ELEMENT_MOVE_AND_UPDATE:
            return canUndoMoveAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_MOVE_MULTI:
            return canUndoMultiMoveAction(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_UPDATE:
            return canUndoUpdateAction(state, action);
        case LOCATION_CHANGE:
            return canUndoRouteChange(state, action);
        case ELEMENT_CLIPBOARD_SAVE:
            return canUndoClipboardSave(state, action);
        case SELECTION_ACTION_TYPES.ELEMENT_EDIT_START:
            return canUndoElementEditStart(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_SET_LOCAL_DATA_MULTI:
            return canUndoElementSetLocalDataMulti(state, action);
        case ELEMENT_ACTION_TYPES.ELEMENT_SET_TYPE:
        case SELECTION_ACTION_TYPES.ELEMENTS_SELECTED:
        case SELECTION_ACTION_TYPES.TABLE_ELEMENT_CELL_SELECTIONS_UPDATE:
        case ELEMENT_ACTION_TYPES.ELEMENT_CREATE:
        case ELEMENT_ACTION_TYPES.ELEMENT_DELETE:
        case ELEMENT_ACTION_TYPES.ELEMENT_CONVERT_TO_CLONE:
        case ELEMENT_COUNT_ACTION_TYPES.ELEMENT_COUNT_INCREASE:
        case ELEMENT_COUNT_ACTION_TYPES.ELEMENT_COUNT_DECREASE:
        case CommentActionType.COMMENTS_UPDATE:
        case CommentActionType.COMMENTS_ADD:
        case CommentActionType.COMMENTS_DELETE:
        case START_OPERATION:
        case END_OPERATION:
        case CLIPPER_OPERATION_COMPLETE:
        case ELEMENT_ACTION_TYPES.ELEMENT_ATTACHMENT_ACCEPT_UNDO:
            return true;
        default:
            return false;
    }
};

export default (store, action) => actionHasUndoProperties(action) && actionCanUndo(store.getState(), action);
