// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConnectedQuickLineCreationTool from './ConnectedQuickLineCreationTool';

const QuickLineCreationTool = (props) => {
    const { show } = props;

    if (!show) return null;

    return <ConnectedQuickLineCreationTool {...props} />;
};

QuickLineCreationTool.propTypes = {
    show: PropTypes.bool,
};

export default QuickLineCreationTool;
