// Utils
import { getMaxCellSelection, getMinCellSelection } from './tableCellSelectionUtils';

// Types
import { CellCoordsObj, CellSelection } from '../../../../common/table/TableTypes';

/**
 * Get excel style naming of a column (e.g. A, B, AA, BB)
 */
const getColName = (col: number): string => {
    const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[col % 26];
    if (col <= 26) return res;

    return getColName(Math.floor(col / 26) - 1) + res;
};

/**
 * Get excel style naming of a cell coordinate (e.g. A1, B1, AA2, BB2)
 */
export const getCellCoordsName = (coord1: CellCoordsObj): string => {
    const { row, col } = coord1;

    return `${getColName(col)}${row + 1}`;
};

/**
 * Get excel style naming of a cell range (e.g. A1:B1, AA2:BB5)
 */
export const getCellRangeName = (cellSelection: CellSelection): string => {
    const { row: minRow, col: minCol } = getMinCellSelection(cellSelection);
    const { row: maxRow, col: maxCol } = getMaxCellSelection(cellSelection);

    if (minRow === maxRow && minCol === maxCol)
        return getCellCoordsName({
            row: minRow,
            col: minCol,
        });

    return `${getCellCoordsName({ row: minRow, col: minCol })}:${getCellCoordsName({ row: maxRow, col: maxCol })}`;
};

const parseCellColName = (colName: string): number => {
    const colNameUpper = colName.toUpperCase();
    let colNumber = 0;
    for (let i = 0; i < colNameUpper.length; i++) {
        const char = colNameUpper.charCodeAt(i);
        colNumber += (char - 64) * 26 ** (colNameUpper.length - i - 1);
    }

    return colNumber - 1;
};

/**
 * Converts a cell range name (e.g. A1:B1, AA2:BB5) into a cell selection
 */
export const parseCellName = (str: string): CellSelection | null => {
    const cellSelection = str.match(/^\$?([a-z]+)\$?([0-9]+)(?::\$?([a-z]+)\$?([0-9]+))?$/i);
    if (!cellSelection) return null;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, startColName, startRowName, endColName, endRowName] = cellSelection;

    const startRow = parseInt(startRowName, 10) - 1;
    const startCol = parseCellColName(startColName);
    const endRow = endRowName ? parseInt(endRowName, 10) - 1 : startRow;
    const endCol = endColName ? parseCellColName(endColName) : startCol;

    return [startRow, startCol, endRow, endCol];
};
