// Lib
import { produce } from 'immer';

// Constants
import { CommentActionType } from '../../../../common/comments/commentConstants';
import { MNComment } from '../../../../common/comments/commentTypes';
import { CommentAction } from '../../../../common/comments/commentActionsType';

const initialState: Record<string, MNComment> = {};

export default (state = initialState, action: CommentAction) => {
    switch (action.type) {
        case CommentActionType.COMMENTS_LOAD:
            // Use plain javascript for loading comments as this is a simple use case, where we are not
            // deeply merging the dataset
            return { ...state, ...action.comments };
        case CommentActionType.COMMENTS_UPDATE: {
            // We want to prevent wrapping the produce function in a large dataset, as in can be slower.
            // So in this case, we extract out the comment that we want to update, and use produce on the
            // single comment we want to update.
            const { _id, contentUpdate, text: textUpdate } = action;

            const comment = state[_id];

            const updatedComment = produce(comment, (draft) => {
                if (contentUpdate?.changes) {
                    draft.content = { ...draft.content, ...contentUpdate.changes };
                }

                if (textUpdate) {
                    draft.text = textUpdate;
                }
            });

            return { ...state, [_id]: updatedComment };
        }
        case CommentActionType.COMMENTS_ADD: {
            // Use plain javascript for loading comments as this is a simple use case, where we are not
            // deeply merging the dataset. Plain javascript is also generally more performant on larger dataset.
            const { _id, createdAt, userId, threadId, text } = action;
            return { ...state, [_id]: { _id, createdAt, userId, threadId, text } };
        }
        case CommentActionType.COMMENTS_DELETE:
            return produce(state, (draft) => {
                delete draft[action._id];
            });
        default:
            return state;
    }
};
