import { getCurrentUserToken } from '../auth/authService';

import { INITIAL_STATE_HYDRATION_OMIT, INITIAL_STATE_POJO_KEYS } from './initialStateConstants';
import { produceImmutableReducerState } from '../offline/cache/utils/rebuildPersistedState';

declare global {
    interface Window {
        desktopIntegration: {
            requestStateSync: () => Record<string, any>;
        };
    }
}

const { requestStateSync } = window.desktopIntegration;

const prepareState = (state: Record<string, any>) => {
    const immutableState = produceImmutableReducerState(state, INITIAL_STATE_POJO_KEYS, INITIAL_STATE_HYDRATION_OMIT);

    // correctly hydrate the user's guest status
    const authenticatedState = immutableState.setIn(['app', 'currentUser', 'isGuest'], !getCurrentUserToken());

    return authenticatedState;
};

const getElectronInitialState = () => {
    const state = requestStateSync();
    if (!state) return;

    const preparedState = prepareState(state);
    return preparedState;
};

export default getElectronInitialState;
