import { Capacitor } from '@capacitor/core';
import { CAPACITOR_SAFE_AREA_PLUGIN } from '../../../capacitor_plugins/pluginConstants';
import { SafeArea, SafeAreaData } from '../../../capacitor_plugins/safeArea';

const updateSafeAreaValues = (currentSafeArea: SafeAreaData) => {
    const root = document.documentElement;

    (Object.keys(currentSafeArea) as Array<keyof SafeAreaData>)?.forEach((key) => {
        root.style.setProperty(`--capacitor-safe-area-${key}`, `${currentSafeArea[key] || 0}px`);
    });
};

/**
 * Adds the safe area values to the root element.
 * This is necessary especially for loading screens since the css safe-area-inset-* values
 * are not available immediately and cause a flicker when the safe area is applied.
 */
export const addCapacitorSafeAreaValues = () => {
    if (Capacitor.isPluginAvailable(CAPACITOR_SAFE_AREA_PLUGIN)) {
        (async () => {
            await SafeArea.currentSafeArea().then(updateSafeAreaValues);

            SafeArea.addListener('safeAreaChanged', updateSafeAreaValues);
        })();
    }
};
