// Utils
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';

// Selectors
import { getCurrentUserId } from '../../../user/currentUserSelector';
import { getComment } from './commentsSelector';

// Actions
import { getNewElementId } from '../../actions/elementActions';
import {
    commentsAdd as baseCommentsAdd,
    commentsUpdate as baseCommentsUpdate,
    commentsDelete as baseCommentsDelete,
} from '../../../../common/comments/commentActions';

export { commentsLoad } from '../../../../common/comments/commentActions';

export const commentsAdd =
    ({ text, threadId, sync = true, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) => {
        const state = getState();
        const userId = getCurrentUserId(state);

        // Build a new ID for the comment
        const id = dispatch(getNewElementId());

        return dispatch(baseCommentsAdd({ text, threadId, sync, transactionId, id, userId }));
    };

export const commentsUpdate = ({
    _id,
    contentUpdate,
    text,
    userId,
    sync = true,
    transactionId = getNewTransactionId(),
}) => baseCommentsUpdate({ _id, contentUpdate, text, userId, sync, transactionId });

export const commentsDelete =
    ({ _id, userId, sync = true, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) => {
        // We need to add the thread ID so that the action channel middleware knows which channel to send it to
        const state = getState();
        const comment = getComment(state, { _id });

        return dispatch(
            baseCommentsDelete({
                _id,
                threadId: comment?.threadId,
                userId,
                transactionId,
                sync,
            }),
        );
    };
