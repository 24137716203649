// Selectors
import { getTokensSelector } from '../utils/permissions/permissionsSelector';

// Constants
import {
    ELEMENT_CONVERT_TO_CLONE,
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_MOVE,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_SET_TYPE,
    ELEMENT_UPDATE,
} from '../../common/elements/elementConstants';
import { CommentActionType } from '../../common/comments/commentConstants';
import { BATCH_ACTION_TYPE } from './reduxBulkingMiddleware';

const addTokensToAction = (state, action) => {
    const tokens = getTokensSelector(state).toArray();

    return {
        ...action,
        tokens,
    };
};

const handleSyncedAction = (state, action) => {
    // TODO-PERMISSIONS This could be improved by only retrieving the permissions that apply for each of the actions
    switch (action.type) {
        case BATCH_ACTION_TYPE:
        case ELEMENT_CREATE:
        case ELEMENT_DELETE:
        case ELEMENT_MOVE:
        case ELEMENT_MOVE_MULTI:
        case ELEMENT_UPDATE:
        case ELEMENT_MOVE_AND_UPDATE:
        case ELEMENT_CONVERT_TO_CLONE:
        case ELEMENT_SET_TYPE:
        case CommentActionType.COMMENTS_ADD:
        case CommentActionType.COMMENTS_UPDATE:
        case CommentActionType.COMMENTS_DELETE:
            return addTokensToAction(state, action);
        default:
            return action;
    }
};

export default (store) => (next) => (action) => {
    // Only need to provide tokens on the synced actions
    if (action.remote || !action.sync) return next(action);

    const { getState } = store;
    const state = getState();

    return next(handleSyncedAction(state, action));
};
