import { isColumn, isLine } from '../../../common/elements/utils/elementTypeUtils';
import { MNElement } from '../../../common/elements/elementModelTypes';

/**
 * Check if the element is a column and not being dragged
 */
const isStaticColumnElement = (element: MNElement, isDragging: boolean) => isColumn(element) && !isDragging;

export const shouldShowPlaceholder = (
    measurements: any,
    elementId: string,
    element: MNElement,
    isElementVisible: boolean,
    elementFilterData: any | null,
    isDragging = false,
) => {
    if (!measurements) return false;
    if (isElementVisible) return false;
    if (isLine(element)) return false;
    if (elementFilterData?.[elementId]) return false;

    // Showing placeholders for columns can prevent their children from rendering
    // correctly when they come on screen, and would cause the orchestrated list to start again.
    // So, we only show column placeholders while they're being dragged.
    if (isStaticColumnElement(element, isDragging)) return false;

    return true;
};
