// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import memoizeOne from 'memoize-one';

// State
import dragAndDropStateSingleton from './dragAndDropStateSingleton';

// Measurements
import { useMeasurementsDispatch } from '../../components/measurementsStore/MeasurementsProvider';
import { getMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Actions
import { startAttachMode } from '../../utils/dnd/dndActions';
import { duplicateMultipleElements } from '../duplicate/elementDuplicateActions';
import { moveMultipleElements } from '../actions/elementMoveActions';
import { deselectAllElements } from '../selection/selectionActions';
import { dragStart, dragEnd } from '../../reducers/draggingActions';
import { endOperation } from '../../utils/undoRedo/undoRedoActions';
import { getCurrentBoardConnectingElementIdsThunk } from '../connections/elementConnectionsActions';
import { getCanvasZoomStateThunk } from '../../canvas/store/canvasActions';

// Selectors
import { getElements } from '../selectors/elementSelector';
import { getDraggedElementsThunk } from './draggableElementsSelector';
import { getElementChildrenIdsThunk } from '../selectors/elementTraversalSelector';

// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';
import { getChildren, getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getHighestScore, isLocationAttached } from '../../../common/elements/utils/elementLocationUtils';
import {
    getElementId,
    getElementLocation,
    getLocationParentId,
} from '../../../common/elements/utils/elementPropertyUtils';

import { analyticsEvent } from '../../analytics';
import { sendAmplitudeEvent } from '../../analytics/amplitudeService';
import { asObject, getMany, prop, propIn } from '../../../common/utils/immutableHelper';
import buildMovedElementOffsetsMap from './utils/buildMovedElementOffsetsMap';
import buildElementMovesArray from './utils/buildElementMovesArray';
import { getScaledGrabOffset } from '../../utils/dnd/dragAndDropUtils';
import {
    getMeasurementsCache,
    setMeasurementsCache,
} from '../../components/measurementsStore/elementMeasurements/measurementsCacheSingleton';
import * as pointsLib from '../../../common/maths/geometry/point';
import { isWorkspaceSectionCanvas } from '../../workspace/workspacePropertyUtils';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformIpad, isPlatformPhoneOrMobileMode } from '../../platform/utils/platformDetailsUtils';
import { isTask, isTaskList } from '../../../common/elements/utils/elementTypeUtils';
import areDraggedItemsTaskListsWithoutHeadings from './task/areDraggedItemsTaskListsWithoutHeadings';
import buildTaskListOntoTasksMovesArray from './utils/buildTaskListOntoTasksMovesArray';
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';
import { isInColumnList } from '../../../common/inList/inListUtils';

// Constants
import { BoardSections } from '../../../common/boards/boardConstants';
import { MODIFIER_KEYS } from '../../utils/dnd/modifierKeys/dragModifierKeysConstants';
import { EVENT_TYPE_NAMES } from '../../../common/analytics/amplitudeEventTypesUtil';
import { AttachModeType } from '../../utils/dnd/dndConstants';
import { ELEMENT_DND_TYPE, ELEMENT_MOVE_OPERATIONS } from '../../../common/elements/elementConstants';

/**
 * Common functionality shared between the ElementContainer and the Task to determine whether the element is
 * actually draggable.
 */
export const shouldAttachDragSource = ({
    isEditable,
    isEditing,
    isEditingChild,
    documentMode,
    isFocusedForegroundElement,
}) => isEditable && !isEditing && !isEditingChild && !documentMode && !isFocusedForegroundElement;

const isDraggingMemo = memoizeOne((element, monitor) => {
    const thisElementId = getElementId(element);
    const item = monitor.getItem();
    const { draggedElementIds, connectingElementIds } = item;
    return draggedElementIds.includes(thisElementId) || !!connectingElementIds?.includes(thisElementId);
});

const trackDraggingAnalyticsEvent = (copyDrag, dropResult, { element, inList }) => {
    if (copyDrag) return analyticsEvent('duplicated-element-opt-drag');
    if (dropResult.location.section === BoardSections.TRASH) return analyticsEvent('moved-element-to-trash-drag');
    if (
        dropResult.location.section === BoardSections.INBOX &&
        dropResult.location.parentId === getLocationParentId(element) &&
        !isInColumnList(inList)
    ) {
        return analyticsEvent('moved-element-to-unsorted-notes');
    }
    if (isTask(element)) sendAmplitudeEvent({ eventType: EVENT_TYPE_NAMES.DRAGGED_TO_DO_ITEM });
};

const DraggableElement = (Component) => {
    const DraggableComponent = (props) => {
        const {
            inTrash,
            elementId,
            element,
            dragModifierKeys,
            currentBoardId,
            isEditing,
            isEditingChild,
            isEditable,
            isLocked,
            attachment,
            element: grabbedElement,
            isSelected,
            gridSize,
            permissions,
            getContextZoomScale = () => 1,
            disableDragPreview = false,
        } = props;

        const dispatch = useDispatch();

        const startDragging = (isSelected, ids) => {
            if (!isSelected) {
                dispatch(deselectAllElements());
            }
            dispatch(dragStart({ ids }));
        };
        const endDragging = (dropState) => dispatch(dragEnd(dropState));
        const dispatchEndMoveOperation = () => dispatch(endOperation('move'));
        const onDropMove = ({ moves, initialMeasurements, transactionId, sync = true }) =>
            dispatch(
                moveMultipleElements({
                    moves,
                    initialMeasurements,
                    transactionId,
                    sync,
                    moveOperation: ELEMENT_MOVE_OPERATIONS.DROP,
                }),
            );
        const onDropCopy = ({ moves, transactionId }) => dispatch(duplicateMultipleElements({ moves, transactionId }));
        const dispatchGetDraggedElements = ({ grabbedElement, permissions }) =>
            dispatch(getDraggedElementsThunk({ grabbedElement, permissions }));
        const dispatchGetDraggedChildIds = (elementIds) => dispatch(getElementChildrenIdsThunk(elementIds));
        const dispatchGetCurrentElements = () => dispatch((_, getState) => getElements(getState()));
        const dispatchGetConnectedElementIds = (elementIds) =>
            dispatch(getCurrentBoardConnectingElementIdsThunk({ elementIds }));
        const dispatchStartAttachMode = (hoveredElementId) =>
            dispatch(startAttachMode(hoveredElementId, AttachModeType.HOT_SPOT));
        const dispatchGetCanvasZoomState = () => dispatch(getCanvasZoomStateThunk());

        const measurementsDispatch = useMeasurementsDispatch();
        const dispatchGetMeasurementsMap = () => measurementsDispatch((_, getState) => getMeasurementsMap(getState()));

        const [dragProps, connectDragSource, connectDragPreview] = useDrag({
            item: { id: elementId, type: ELEMENT_DND_TYPE },
            canDrag: () => !isLocked && isEditable && !(isEditing || isEditingChild),
            isDragging: (monitor) => isDraggingMemo(element, monitor),
            begin: (monitor) => {
                // Returns the data describing the dragged tool
                const zoomState = dispatchGetCanvasZoomState();
                const zoomScale = prop('scale', zoomState);
                const zoomTranslationPx = prop('translation', zoomState);
                const zoomScaleOnDragSource = getContextZoomScale();

                const sourceClientOffset = monitor.getSourceClientOffset();
                const clientOffset = monitor.getClientOffset();
                const scaledGrabOffset = getScaledGrabOffset({ clientOffset, sourceClientOffset });
                const unscaledGrabOffset = pointsLib.reverseScale(zoomScaleOnDragSource, scaledGrabOffset);

                dragAndDropStateSingleton.scaledGrabOffset = scaledGrabOffset;

                // Find out the offsets (from the top left of the grabbed element) for all dragged elements
                const draggedElements = dispatchGetDraggedElements({ grabbedElement, permissions });
                const draggedElementIds = draggedElements.map(getElementId);

                const originalMeasurementsMap = dispatchGetMeasurementsMap();

                // In order to get an accurate measurements before dragging, recalculate all elements
                const measurementsMap = [grabbedElement, ...draggedElements].reduce((acc, element) => {
                    const elementId = getElementId(element);

                    const elementMeasurement = asObject(prop(elementId, originalMeasurementsMap));

                    // If element is not on canvas, the measurements are usually not accurate after a scroll, so remeasure it.
                    acc[elementId] = isWorkspaceSectionCanvas(elementMeasurement)
                        ? elementMeasurement
                        : measurementsRegistry.getElementMeasurementFromCanvasDocument(elementId);

                    return acc;
                }, {});

                // Currently can only start in attach mode if dragging a single element
                // NOTE: Assumes the attach mode is a hot spot right now
                if (draggedElements?.length === 1 && draggedElements.every(isLocationAttached)) {
                    const parentId = getLocationParentId(draggedElements[0]);
                    dispatchStartAttachMode(parentId);
                }

                const draggedChildIds = dispatchGetDraggedChildIds(draggedElementIds);

                const draggedDescendantIds = [...draggedElementIds, ...draggedChildIds];

                const connectingElementIds = dispatchGetConnectedElementIds(draggedDescendantIds);
                const location = asObject(getElementLocation(grabbedElement));

                startDragging(isSelected, draggedElementIds);

                // Save the measurements map so on drop, if the measurements don't initially exist (as the board
                // has changed), the lines can use the cached measurements instead.
                setMeasurementsCache(measurementsMap);

                // Find out the offsets (from the top left of the grabbed element) for all dragged elements
                const unscaledElementOffsetsMap = buildMovedElementOffsetsMap({
                    grabbedElement,
                    draggedElements,
                    measurementsMap,
                    gridSize,
                    unscaledGrabOffset,
                    zoomScale,
                    zoomScaleOnDragSource,
                    zoomTranslationPx,
                });

                const shouldShiftDragUp =
                    isPlatformPhoneOrMobileMode(platformSingleton) || isPlatformIpad(platformSingleton);
                const unscaledCustomDragOffset = shouldShiftDragUp
                    ? pointsLib.scale(gridSize, { x: 0, y: -1 })
                    : { x: 0, y: 0 };
                const scaledCustomDragOffset = pointsLib.scale(zoomScaleOnDragSource, unscaledCustomDragOffset);

                return {
                    dragStartTimestamp: getTimestamp(),
                    attachment,
                    element: grabbedElement,
                    connectingElementIds,
                    draggedElementIds,
                    draggedDescendantIds,
                    draggedElements,
                    scaledCustomDragOffset,
                    unscaledElementOffsetsMap,
                    zoomScaleOnDragSource,
                    originalLocation: location,
                    fromList: !!props.inList,
                    hoveredTypesRegistry: {},
                    disableDragPreview,
                };
            },
            end: (item, monitor) => {
                const operationTransactionId = dispatchEndMoveOperation();

                // Doing nothing if it was not dropped onto a compatible target
                if (!monitor.didDrop()) return endDragging();

                const dropResult = monitor.getDropResult();
                const { sync, dropState } = dropResult;

                const droppedElementIds = item.draggedElementIds;

                const copyDrag = !inTrash && dragModifierKeys?.get(MODIFIER_KEYS.altKey);

                // A successful drag should store some dropState
                dropState && !copyDrag
                    ? endDragging({ ...dragAndDropStateSingleton, ...dropState, droppedElementIds })
                    : endDragging();

                // Only drop if a location is returned by the drop target
                if (!dropResult.location) return;

                trackDraggingAnalyticsEvent(copyDrag, dropResult, props);

                // Use the drop transaction ID if one exists, otherwise use the move transaction ID
                const transactionId = dropResult.transactionId || operationTransactionId;

                const measurementsMap = dispatchGetMeasurementsMap();
                const elements = dispatchGetCurrentElements();
                const currentBoardElements = getChildren(elements, currentBoardId).valueSeq().toJS();
                const filteredElements = currentBoardElements.filter(
                    (el) => item.draggedElementIds.indexOf(el.id) === -1,
                );
                const highestScore = getHighestScore(filteredElements);

                const dropLocationParentId = propIn(['location', 'parentId'], dropResult);
                const dropLocationParentElement = getElement(elements, dropLocationParentId);

                const isTaskListDropOnTasks =
                    (isTaskList(dropLocationParentElement) || isTask(dropLocationParentElement)) &&
                    areDraggedItemsTaskListsWithoutHeadings(props, monitor);

                const moves = isTaskListDropOnTasks
                    ? buildTaskListOntoTasksMovesArray({ elements, dropResult, measurementsMap })
                    : buildElementMovesArray({
                          element,
                          dropResult,
                          item,
                          gridSize,
                          measurementsMap,
                          highestScore,
                      });

                if (copyDrag) return onDropCopy({ moves, transactionId });

                const measurementsCache = getMeasurementsCache();
                const initialMeasurements = asObject(getMany(droppedElementIds, measurementsCache));

                onDropMove({ moves, initialMeasurements, transactionId, sync });
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            // The internals of `useDragSource` in React-dnd use these options to default the drag source on
            //  creation of the drag source, rather than the options provided to the connectDragSource function
            //  within ElementContainer, etc.
            // To avoid accidental moves immediately after board load, we need to provide appropriate defaults here
            options: {
                delayTouchStart: isSelected ? 0 : 300,
            },
        });

        return (
            <Component
                {...props}
                {...dragProps}
                connectDragSource={connectDragSource}
                connectDragPreview={connectDragPreview}
            />
        );
    };

    DraggableComponent.propTypes = {
        elementId: PropTypes.string,
        inTrash: PropTypes.bool,
        element: PropTypes.object,
        dragModifierKeys: PropTypes.object,
        currentBoardId: PropTypes.string,
        isEditing: PropTypes.bool,
        isEditingChild: PropTypes.bool,
        isEditable: PropTypes.bool,
        isLocked: PropTypes.bool,
        attachment: PropTypes.object,
        isSelected: PropTypes.bool,
        gridSize: PropTypes.number,
        permissions: PropTypes.number,
        getContextZoomScale: PropTypes.func,
        inList: PropTypes.string,
        disableDragPreview: PropTypes.bool,
    };

    return DraggableComponent;
};

export default DraggableElement;
